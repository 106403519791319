import {
  videoProgressIndex,
  videoProgressStatus,
  acceleratorContent,
  acceleratorContentLinks,
  acceleratorContentTitles,
  requiresAction,
  succeeded,
} from '@es/common/src/config/progressDashboardConfig/acceleratorProgressDashboardConfig'
import {
  contentLinks,
  foundationContentTitles,
} from '@es/common/src/config/progressDashboardConfig/foundationProgressDashboardConfig'
import React from 'react'
import { AcceleratorProgressAccordion } from './components'

export const AcceleratorProgressDashboard = ({
  acceleratorProgressTrackingData,
  instalmentPlan,
}) => {
  const {
    mentorshipPercentageProgress,
    foundationTrainingPercentageProgress,
    coreSessionVideoPercentageValue,
    coreSessionWrittenContentPercentageMetrics,
    coreSessionSuccessCheckListPercentageMetrics,
    bonusSessionVideoPercentageValues,
    writtenContentAccessed,
    successChecklistAccessed,
    adviceForEntrepreneursPercentageProgress,
    eBookLibraryPercentageProgress,
    conversationVideoPercentageValues,
    mentoringPortalAccessed,
    userHasAccessedMentoringPortal,
    firstQuestionToMartin,
    threeQuestionsToMartin,
    userHasAskedAtLeastOneQuestion,
    userHasAskedAtLeastThreeQuestions,
    writtenContent,
    successChecklist,
    bookOfSecrets,
    raisingFunds,
    expertsAdvice,
    bookOfSecretsHasBeenAccessed,
    raisingMoneyHasBeenAccessed,
    expertAdviceHasBeenAccessed,
    developAGreatProduct,
    exitYourCompany,
    bonusSessionVideoPercentageProgress,
  } = acceleratorProgressTrackingData

  const {
    mentoringPortalPage,
    startingAGoodBusinessPage,
    gettingIntoTheInvestorsMindPage,
    whatIHaveLearntAsAnEntrepreneurPage,
    secretsToGoodHiringPage,
    behindTheScenesOfAGreatProductPage,
    bookOfSecretsPage,
    raisingFundsPage,
    expertsAdvicePage,
    developAGreatProductPage,
    exitYourCompanyPage,
  } = acceleratorContentLinks

  const {
    MENTORSHIP_WITH_MARTIN,
    FOUNDATION_TRAINING_MODULE,
    ADVICE_FOR_ENTREPRENEURS,
    EBOOK_LIBRARY,
    BONUS,
  } = acceleratorContent

  const {
    identifyingEntrepreneurshipOpportunity,
    establishingTheStartup,
    optimizeYourBrand,
    buildAGreatProduct,
    businessPlanning,
    deliverSmartTeamPlanning,
    keyMetricTracking,
    strategicThinkingToSuccess,
    sellingYourBusiness,
  } = foundationContentTitles

  const {
    identifyingEntrepreneurshipOpportunityPage,
    establishingTheStartupPage,
    optimizeYourBrandPage,
    buildAGreatProductPage,
    businessPlanningPage,
    deliverSmartTeamPlanningPage,
    keyMetricTrackingPage,
    strategicThinkingToSuccessPage,
    sellingYourBusinessPage,
  } = contentLinks

  const {
    startingAGoodBusiness,
    gettingIntoTheInvestorsMind,
    whatIHaveLearntAsAnEntrepreneur,
    secretsToGoodHiring,
    behindTheScenesOfAGreatProduct,
  } = acceleratorContentTitles

  const [
    identifyingEntrepreneurshipOpportunityValue,
    establishingTheStartupValue,
    optimizeYourBrandValue,
    buildAGreatProductValue,
    businessPlanningValue,
    deliverSmartTeamPlanningValue,
    keyMetricTrackingValue,
    sellingYourBusinessValue,
    strategicThinkingToSuccessValue,
  ] = coreSessionVideoPercentageValue

  const [
    writtenContentForIdentifyingEntrepreneurshipOpportunityValue,
    writtenContentForEstablishingTheStartupValue,
    writtenContentForOptimizeYourBrandValue,
    writtenContentForBuildAGreatProductValue,
    writtenContentForBusinessPlanningValue,
    writtenContentForDeliverSmartTeamPlanningValue,
    writtenContentForKeyMetricTrackingValue,
    writtenContentForStrategicThinkingToSuccessValue,
    writtenContentForSellingYourBusinessValue,
  ] = coreSessionWrittenContentPercentageMetrics

  const [
    successCheckListForIdentifyingEntrepreneurshipOpportunityValue,
    successCheckListForEstablishingTheStartupValue,
    successCheckListForOptimizeYourBrandValue,
    successCheckListForBuildAGreatProductValue,
    successCheckListForBusinessPlanningValue,
    successCheckListForDeliverSmartTeamPlanningValue,
    successCheckListForKeyMetricTrackingValue,
    successCheckListForStrategicThinkingToSuccessValue,
    successCheckListForSellingYourBusinessValue,
  ] = coreSessionSuccessCheckListPercentageMetrics

  const [
    startingAGoodBusinessValue,
    gettingIntoTheInvestorsMindValue,
    whatIHaveLearntAsAnEntrepreneurValue,
    secretsToGoodHiringValue,
    behindTheScenesOfAGreatProductValue,
  ] = conversationVideoPercentageValues

  const [developAGreatProductValue, exitYourCompanyValue] = bonusSessionVideoPercentageValues

  const { ACCESSED_VALUE, PARTIALLY_COMPLETED_VALUE, COMPLETED_VALUE } = videoProgressIndex

  const { ACCESSED, PARTIALLY_COMPLETED, COMPLETED } = videoProgressStatus

  const detailedProgressDashboardProps = [
    {
      heading: MENTORSHIP_WITH_MARTIN.title,
      subheading: MENTORSHIP_WITH_MARTIN.subtitle,
      contents: [
        {
          title: mentoringPortalAccessed,
          href: mentoringPortalPage,
          complete: userHasAccessedMentoringPortal,
          portalAccessDate: userHasAccessedMentoringPortal,
        },
        {
          title: firstQuestionToMartin,
          href: mentoringPortalPage,
          complete: userHasAskedAtLeastOneQuestion,
        },
        {
          title: threeQuestionsToMartin,
          href: mentoringPortalPage,
          complete: userHasAskedAtLeastThreeQuestions,
        },
      ],
      percentageProgress: mentorshipPercentageProgress,
    },
    {
      heading: FOUNDATION_TRAINING_MODULE.title,
      subheading: FOUNDATION_TRAINING_MODULE.subtitle,
      contents: [
        {
          title: identifyingEntrepreneurshipOpportunity,
          href: identifyingEntrepreneurshipOpportunityPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForIdentifyingEntrepreneurshipOpportunityValue,
              writtenContentAccessDate: writtenContentAccessed[0],
            },
            {
              title: successChecklist,
              complete: successCheckListForIdentifyingEntrepreneurshipOpportunityValue,
              successChecklistAccessDate: successChecklistAccessed[0],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: identifyingEntrepreneurshipOpportunityValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: identifyingEntrepreneurshipOpportunityValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: identifyingEntrepreneurshipOpportunityValue >= COMPLETED_VALUE,
            },
          ],
          complete: identifyingEntrepreneurshipOpportunityValue >= COMPLETED_VALUE,
        },
        {
          title: establishingTheStartup,
          href: establishingTheStartupPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForEstablishingTheStartupValue,
              writtenContentAccessDate: writtenContentAccessed[1],
            },
            {
              title: successChecklist,
              complete: successCheckListForEstablishingTheStartupValue,
              successChecklistAccessDate: successChecklistAccessed[1],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: establishingTheStartupValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: establishingTheStartupValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: establishingTheStartupValue >= COMPLETED_VALUE,
            },
          ],
          complete: establishingTheStartupValue >= COMPLETED_VALUE,
        },
        {
          title: optimizeYourBrand,
          href: optimizeYourBrandPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForOptimizeYourBrandValue,
              writtenContentAccessDate: writtenContentAccessed[2],
            },
            {
              title: successChecklist,
              complete: successCheckListForOptimizeYourBrandValue,
              successChecklistAccessDate: successChecklistAccessed[2],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: optimizeYourBrandValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: optimizeYourBrandValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: optimizeYourBrandValue >= COMPLETED_VALUE,
            },
          ],
          complete: optimizeYourBrandValue >= COMPLETED_VALUE,
        },
        {
          title: buildAGreatProduct,
          href: buildAGreatProductPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForBuildAGreatProductValue,
              writtenContentAccessDate: writtenContentAccessed[3],
            },
            {
              title: successChecklist,
              complete: successCheckListForBuildAGreatProductValue,
              successChecklistAccessDate: successChecklistAccessed[3],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: buildAGreatProductValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: buildAGreatProductValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: buildAGreatProductValue >= COMPLETED_VALUE,
            },
          ],
          isLocked: instalmentPlan?.exists && instalmentPlan?.payments[1]?.status !== succeeded,
          failedPayment:
            instalmentPlan?.exists && instalmentPlan?.payments[1]?.status === requiresAction,
          accessDate: instalmentPlan?.exists && instalmentPlan?.payments[1]?.scheduledAt,
          complete: buildAGreatProductValue >= COMPLETED_VALUE,
        },
        {
          title: businessPlanning,
          href: businessPlanningPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForBusinessPlanningValue,
              writtenContentAccessDate: writtenContentAccessed[4],
            },
            {
              title: successChecklist,
              complete: successCheckListForBusinessPlanningValue,
              successChecklistAccessDate: successChecklistAccessed[4],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: businessPlanningValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: businessPlanningValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: businessPlanningValue >= COMPLETED_VALUE,
            },
          ],
          isLocked: instalmentPlan?.exists && instalmentPlan?.payments[1]?.status !== succeeded,
          failedPayment:
            instalmentPlan?.exists && instalmentPlan?.payments[1]?.status === requiresAction,
          accessDate: instalmentPlan?.exists && instalmentPlan?.payments[1]?.scheduledAt,
          complete: businessPlanningValue >= COMPLETED_VALUE,
        },
        {
          title: deliverSmartTeamPlanning,
          href: deliverSmartTeamPlanningPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForDeliverSmartTeamPlanningValue,
              writtenContentAccessDate: writtenContentAccessed[5],
            },
            {
              title: successChecklist,
              complete: successCheckListForDeliverSmartTeamPlanningValue,
              successChecklistAccessDate: successChecklistAccessed[5],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: deliverSmartTeamPlanningValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: deliverSmartTeamPlanningValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: deliverSmartTeamPlanningValue >= COMPLETED_VALUE,
            },
          ],
          isLocked: instalmentPlan?.exists && instalmentPlan?.payments[1]?.status !== succeeded,
          failedPayment:
            instalmentPlan?.exists && instalmentPlan?.payments[1]?.status === requiresAction,
          accessDate: instalmentPlan?.exists && instalmentPlan?.payments[1]?.scheduledAt,
          complete: deliverSmartTeamPlanningValue >= COMPLETED_VALUE,
        },
        {
          title: keyMetricTracking,
          href: keyMetricTrackingPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForKeyMetricTrackingValue,
              writtenContentAccessDate: writtenContentAccessed[6],
            },
            {
              title: successChecklist,
              complete: successCheckListForKeyMetricTrackingValue,
              successChecklistAccessDate: successChecklistAccessed[6],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: keyMetricTrackingValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: keyMetricTrackingValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: keyMetricTrackingValue >= COMPLETED_VALUE,
            },
          ],
          isLocked: instalmentPlan?.exists && instalmentPlan?.payments[2]?.status !== succeeded,
          failedPayment:
            instalmentPlan?.exists && instalmentPlan?.payments[2]?.status === requiresAction,
          accessDate: instalmentPlan?.exists && instalmentPlan?.payments[2]?.scheduledAt,
          complete: keyMetricTrackingValue >= COMPLETED_VALUE,
        },
        {
          title: strategicThinkingToSuccess,
          href: strategicThinkingToSuccessPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForStrategicThinkingToSuccessValue,
              writtenContentAccessDate: writtenContentAccessed[7],
            },
            {
              title: successChecklist,
              complete: successCheckListForStrategicThinkingToSuccessValue,
              successChecklistAccessDate: successChecklistAccessed[7],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: strategicThinkingToSuccessValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: strategicThinkingToSuccessValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: strategicThinkingToSuccessValue >= COMPLETED_VALUE,
            },
          ],
          isLocked: instalmentPlan?.exists && instalmentPlan?.payments[2]?.status !== succeeded,
          failedPayment:
            instalmentPlan?.exists && instalmentPlan?.payments[2]?.status === requiresAction,
          accessDate: instalmentPlan?.exists && instalmentPlan?.payments[2]?.scheduledAt,
          complete: strategicThinkingToSuccessValue >= COMPLETED_VALUE,
        },
        {
          title: sellingYourBusiness,
          href: sellingYourBusinessPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForSellingYourBusinessValue,
              writtenContentAccessDate: writtenContentAccessed[8],
            },
            {
              title: successChecklist,
              complete: successCheckListForSellingYourBusinessValue,
              successChecklistAccessDate: successChecklistAccessed[8],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: sellingYourBusinessValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: sellingYourBusinessValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: sellingYourBusinessValue >= COMPLETED_VALUE,
            },
          ],
          isLocked: instalmentPlan?.exists && instalmentPlan?.payments[2]?.status !== succeeded,
          failedPayment:
            instalmentPlan?.exists && instalmentPlan?.payments[2]?.status === requiresAction,
          accessDate: instalmentPlan?.exists && instalmentPlan?.payments[2]?.scheduledAt,
          complete: sellingYourBusinessValue >= COMPLETED_VALUE,
        },
      ],
      percentageProgress: foundationTrainingPercentageProgress,
    },
    {
      heading: ADVICE_FOR_ENTREPRENEURS.title,
      subheading: ADVICE_FOR_ENTREPRENEURS.subtitle,
      contents: [
        {
          title: startingAGoodBusiness,
          href: startingAGoodBusinessPage,
          progress: [
            {
              status: ACCESSED,
              complete: startingAGoodBusinessValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: startingAGoodBusinessValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: startingAGoodBusinessValue >= COMPLETED_VALUE,
            },
          ],
          complete: startingAGoodBusinessValue >= COMPLETED_VALUE,
        },
        {
          title: gettingIntoTheInvestorsMind,
          href: gettingIntoTheInvestorsMindPage,
          progress: [
            {
              status: ACCESSED,
              complete: gettingIntoTheInvestorsMindValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: gettingIntoTheInvestorsMindValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: gettingIntoTheInvestorsMindValue >= COMPLETED_VALUE,
            },
          ],
          complete: gettingIntoTheInvestorsMindValue >= COMPLETED_VALUE,
        },
        {
          title: whatIHaveLearntAsAnEntrepreneur,
          href: whatIHaveLearntAsAnEntrepreneurPage,
          progress: [
            {
              status: ACCESSED,
              complete: whatIHaveLearntAsAnEntrepreneurValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: whatIHaveLearntAsAnEntrepreneurValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: whatIHaveLearntAsAnEntrepreneurValue >= COMPLETED_VALUE,
            },
          ],
          complete: whatIHaveLearntAsAnEntrepreneurValue >= COMPLETED_VALUE,
        },
        {
          title: secretsToGoodHiring,
          href: secretsToGoodHiringPage,
          progress: [
            {
              status: ACCESSED,
              complete: secretsToGoodHiringValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: secretsToGoodHiringValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: secretsToGoodHiringValue >= COMPLETED_VALUE,
            },
          ],
          complete: secretsToGoodHiringValue >= COMPLETED_VALUE,
        },
        {
          title: behindTheScenesOfAGreatProduct,
          href: behindTheScenesOfAGreatProductPage,
          progress: [
            {
              status: ACCESSED,
              complete: behindTheScenesOfAGreatProductValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: behindTheScenesOfAGreatProductValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: behindTheScenesOfAGreatProductValue >= COMPLETED_VALUE,
            },
          ],
          complete: behindTheScenesOfAGreatProductValue >= COMPLETED_VALUE,
        },
      ],
      percentageProgress: adviceForEntrepreneursPercentageProgress,
    },
    {
      heading: EBOOK_LIBRARY.title,
      subheading: EBOOK_LIBRARY.subtitle,
      contents: [
        {
          title: bookOfSecrets,
          href: bookOfSecretsPage,
          complete: bookOfSecretsHasBeenAccessed,
          resourceAccessDate: bookOfSecretsHasBeenAccessed,
        },
        {
          title: raisingFunds,
          href: raisingFundsPage,
          complete: raisingMoneyHasBeenAccessed,
          resourceAccessDate: raisingMoneyHasBeenAccessed,
        },
        {
          title: expertsAdvice,
          href: expertsAdvicePage,
          complete: expertAdviceHasBeenAccessed,
          resourceAccessDate: expertAdviceHasBeenAccessed,
        },
      ],
      percentageProgress: eBookLibraryPercentageProgress,
    },
    {
      heading: BONUS.title,
      subheading: BONUS.subtitle,
      contents: [
        {
          title: developAGreatProduct,
          href: developAGreatProductPage,
          progress: [
            {
              status: ACCESSED,
              complete: developAGreatProductValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: developAGreatProductValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: developAGreatProductValue >= COMPLETED_VALUE,
            },
          ],
          complete: developAGreatProductValue >= COMPLETED_VALUE,
        },
        {
          title: exitYourCompany,
          href: exitYourCompanyPage,
          progress: [
            {
              status: ACCESSED,
              complete: exitYourCompanyValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: exitYourCompanyValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: exitYourCompanyValue >= COMPLETED_VALUE,
            },
          ],
          complete: exitYourCompanyValue >= COMPLETED_VALUE,
        },
      ],
      percentageProgress: bonusSessionVideoPercentageProgress,
    },
  ]

  return <AcceleratorProgressAccordion items={detailedProgressDashboardProps} />
}
