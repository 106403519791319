import parsePhoneNumber from 'libphonenumber-js'

export const PhoneNumberView = ({ phoneNumber }) => {
  const phone = parsePhoneNumber(String(phoneNumber))
  if (phone) {
    console.log({ formatted: phone.formatInternational() })
    return phone.formatInternational()
  } else {
    return phoneNumber
  }
}
