import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, fonts, themes } from '../../../theme'

/**
 * A Paragraph component used to render general long text throughout the site.
 * Color styles are controlled by the theme, and either the `secondary` font can
 * be selected using the `font` prop.
 *
 * `em` tags in the children are highlighted with the primary color.
 */
const P = styled.p`
  margin: 0 0 0.8em;
  color: ${props => (themes[props.colorScheme] || themes.light).color};
  font-family: ${props => fonts[props.font]};
  line-height: 1.3;
  em {
    font-style: normal;
    color: ${colors.primary};
  }
`

P.propTypes = {
  colorScheme: PropTypes.oneOf(['light', 'gray', 'dark', 'primary']),
  font: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
}

P.defaultProps = {
  colorScheme: 'light',
  font: 'primary',
}

/** @component */
export default P
