import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'

import styled from 'styled-components'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { P, H3 } from '../../../components/typography'
import { useTestimonials } from '../../../containers/AdminApi/TestimonialsApi'

const Page = styled(AdminPage)``

const TestimonialItem = styled.div`
  padding: 15px;
  border-bottom: 2px solid ${props => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.sm`
    flex-direction: row;
  `}
`

const Details = styled.div`
  flex: 1;
  padding-right: 30px;
`

const Image = styled.img`
  height: ${props => props.size}px;
`

const Detail = styled(P)`
  text-align: left;
`
const Name = styled(Detail)`
  color: ${props => props.theme.colors.primary};
  text-decoration-line: underline;
`

const TestimonialsPage = () => {
  const { testimonials, getAllTestimonials, loading, error } = useTestimonials()

  useEffect(() => {
    getAllTestimonials()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page heading="Testimonials">
      {loading ? (
        <LoadingSection />
      ) : (
        <ErrorSection error={error}>
          {testimonials.length === 0 && <H3>No testimonials yet</H3>}
          {testimonials.map(t => {
            return (
              <TestimonialItem>
                <Details>
                  <Link
                    to={{
                      pathname: `/tracking/${t.fullName}/_/${t.userId}`,
                    }}
                  >
                    <Name>
                      <b>{t.fullName}</b>
                    </Name>
                  </Link>
                  <Detail>
                    {t.city}, {t.country}
                  </Detail>
                  <Detail>{t.text}</Detail>
                </Details>

                <Image size={200} src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${t.imageSrc}`} />
              </TestimonialItem>
            )
          })}
        </ErrorSection>
      )}
    </Page>
  )
}

export default TestimonialsPage
