import { useState, useEffect, useCallback, useMemo } from 'react'
import { flattenDeep } from 'lodash'
import { orderBy } from 'lodash/fp'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

const orderByQuestionDateDesc = orderBy(['questionDate'], ['desc'])

export const useMentoring = () => {
  const [mentoring, setMentoring] = useState({
    questions: [],
    usersQuestions: null,
    modAnswers: [],
    loading: true,
  })

  useEffect(() => {
    getAllQuestions()
    getModeratorAnswers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getQuestionById = useCallback(
    (id) =>
      esAdminAPI('get', `/mentoring/question/${id}`)
        .then((currentQuestion) =>
          setMentoring((state) => ({ ...state, currentQuestion, loading: false })),
        )
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const getQuestionsByUser = useCallback(
    (userId) =>
      esAdminAPI('get', `/mentoring/questions/user/${userId}`)
        .then((usersQuestions) =>
          setMentoring((state) => ({
            ...state,
            usersQuestions: orderByQuestionDateDesc(usersQuestions),
            loading: false,
          })),
        )
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const getAllQuestions = useCallback(() => {
    esAdminAPI('get', '/mentoring/questions/all')
      .then((questions) => setMentoring((state) => ({ ...state, questions, loading: false })))
      .catch((error) => setMentoring((state) => ({ ...state, error, loading: false })))
  }, [])

  const setStateWithUpdatedQuestion = useCallback(
    (id) => (question) =>
      setMentoring((state) => ({
        ...state,
        loading: false,
        questions: state.questions.map((qAndA) => (qAndA.id === id ? question : qAndA)),
      })),
    [],
  )

  const postAnswer = useCallback(
    (id, { answerFormatted, answerPlainText }) =>
      esAdminAPI('patch', `/mentoring/questions/${id}/answer`, {
        answerFormatted,
        answerPlainText,
      })
        .then(setStateWithUpdatedQuestion(id))
        .catch((err) => {
          setMentoring((state) => ({ ...state, loading: false }))
          throw err
        }),
    [setStateWithUpdatedQuestion],
  )

  const publishAnswer = useCallback(
    (id, answerAuthor) =>
      esAdminAPI('post', `/mentoring/questions/${id}/publish`, { answerAuthor })
        .then(setStateWithUpdatedQuestion(id))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [setStateWithUpdatedQuestion],
  )

  const unpublishAnswer = useCallback(
    (id) =>
      esAdminAPI('patch', `/mentoring/questions/${id}/unpublish`)
        .then(setStateWithUpdatedQuestion(id))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [setStateWithUpdatedQuestion],
  )

  const setTags = useCallback(
    (id, tags = []) =>
      esAdminAPI('patch', `/mentoring/questions/${id}/tags`, { tags })
        .then(setStateWithUpdatedQuestion(id))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [setStateWithUpdatedQuestion],
  )

  const deleteTagGlobally = useCallback(
    (tagName) =>
      esAdminAPI('post', '/mentoring/tags/delete', { tagName })
        .then((questions) => setMentoring({ questions, loading: false }))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const renameTagGlobally = useCallback(
    (oldName, newName) =>
      esAdminAPI('post', '/mentoring/tags/rename', { oldName, newName })
        .then((questions) => setMentoring({ questions, loading: false }))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const getModeratorAnswers = useCallback(
    () =>
      esAdminAPI('get', `/mentoring/mod-answers/all`)
        .then((modAnswers) => setMentoring((state) => ({ ...state, modAnswers })))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const addModAnswer = useCallback(
    ({ title, body }) =>
      esAdminAPI('post', `/mentoring/mod-answers/add`, { title, body })
        .then((modAnswers) => setMentoring((state) => ({ ...state, modAnswers })))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )
  const updateModAnswer = useCallback(
    (questionId, { title, body }) =>
      esAdminAPI('patch', `/mentoring/mod-answers/update`, { questionId, title, body })
        .then((modAnswers) => setMentoring((state) => ({ ...state, modAnswers })))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const deleteModAnswer = useCallback(
    (id) =>
      esAdminAPI('post', `/mentoring/mod-answers/delete`, { id })
        .then((modAnswers) => setMentoring((state) => ({ ...state, modAnswers })))
        .catch((error) => setMentoring((state) => ({ ...state, error, loading: false }))),
    [],
  )

  const { questions } = mentoring

  const allTags = useMemo(
    () => flattenDeep(questions.map((qAndA) => qAndA.tags.map((t) => t.name))),
    [questions],
  )

  const tagCounts = {}

  allTags.forEach((tag) => {
    if (tagCounts[tag]) tagCounts[tag].count++
    else tagCounts[tag] = { tag, count: 1 }
  })

  return useMemo(
    () => ({
      addModAnswer,
      deleteModAnswer,
      deleteTagGlobally,
      getModeratorAnswers,
      getQuestionById,
      mentoring,
      postAnswer,
      publishAnswer,
      renameTagGlobally,
      setTags,
      tagCounts,
      unpublishAnswer,
      updateModAnswer,
      getQuestionsByUser,
    }),
    [
      addModAnswer,
      deleteModAnswer,
      deleteTagGlobally,
      getModeratorAnswers,
      getQuestionById,
      mentoring,
      updateModAnswer,
      postAnswer,
      publishAnswer,
      renameTagGlobally,
      setTags,
      tagCounts,
      unpublishAnswer,
      getQuestionsByUser,
    ],
  )
}
