import { useState } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

const ROUTE = '/testimonials'

export const useTestimonials = () => {
  const [state, setState] = useState({ testimonials: [], loading: true, error: null })

  const getAllTestimonials = () =>
    esAdminAPI('get', ROUTE)
      .then(testimonials => setState(state => ({ ...state, testimonials, loading: false })))
      .catch(error => setState(state => ({ ...state, error, loading: false })))

  return {
    ...state,
    getAllTestimonials,
  }
}
