import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { contentWidth, themes, mediaMinWidth } from '../../../theme'

/**
 * A full width page section providing a background colour and padded
 * space for rendering children.
 */
const FullWidth = styled.section`
  ${props => themes[props.colorScheme]};
  padding: 0 15px;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: ${props => props.maxWidth || contentWidth};
  text-align: ${props => (props.center ? 'center' : 'left')};
  padding: ${props =>
    props.padding === 'none' ? '0' : props.padding === 'lg' ? '2.5em 0' : '1.3em 0'};

  ${mediaMinWidth.md`
    padding: ${props =>
      props.padding === 'none' ? '0' : props.padding === 'sm' ? '1em 0' : '2em 0'};
  `};
`

const Section = ({ id, colorScheme, center, padding, maxWidth, children, ...props }) => (
  <FullWidth {...props} colorScheme={colorScheme} id={id}>
    <Container center={center} padding={padding} maxWidth={maxWidth}>
      {children}
    </Container>
  </FullWidth>
)

Section.Container = Container

Section.propTypes = {
  colorScheme: PropTypes.oneOf(['light', 'dark', 'gray', 'primary']),
  /** Reduce the padding by half or remove it entirely */
  padding: PropTypes.oneOf(['lg', 'sm', 'none']),
  /** Applies `text-align: center` to the section */
  center: PropTypes.bool,
  /** A maximum width for the content in the section to make it smaller or larger than the overall content width */
  maxWidth: PropTypes.string,
  /** Provide an `id` for use in scrolling with a URL hash */
  id: PropTypes.string,
}

Section.defaultProps = {
  colorScheme: 'light',
  padding: null,
  center: false,
}

export default Section
