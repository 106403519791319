/**
 * Common functions for mapping/filtering/sorting over arrays
 */

// filter
export const onlyUnique = (value, index, self) => self.indexOf(value) === index

// sort
export const byKey = (key, { asc = true } = {}) => (a, b) => {
  const multiplier = asc ? -1 : 1

  if (a[key] > b[key]) return -1 * multiplier
  if (a[key] < b[key]) return 1 * multiplier
  return 0
}
