import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { useLockBodyScroll } from '../../../hooks/useLockBodyScroll'

const Container = styled.div`
  background: ${props => props.theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 99; /* Covers header */
  overflow: scroll;
  padding: 30px 20px;
`

export const FullScreenOverlay = ({ closeOverlay = () => {}, children }) => (
  <Container
    onClick={e => {
      e.stopPropagation()
      closeOverlay()
    }}
  >
    {children}
  </Container>
)

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
`

const ClickableText = styled.div`
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 10px;
`

const FillAndBlockScroll = styled.div`
  width: 100%;
  padding-bottom: 80px;
`

export const FullScreenWithClose = ({ close, children }) => {
  useLockBodyScroll()
  return (
    <FullScreenOverlay>
      <FillAndBlockScroll
        onScroll={stopPropagation}
        onTouchEnd={stopPropagation}
        onTouchMove={stopPropagation}
        onTouchStart={stopPropagation}
      >
        <ContentContainer>
          <ClickableText onClick={close}>Close</ClickableText>
          {children}
        </ContentContainer>
      </FillAndBlockScroll>
    </FullScreenOverlay>
  )
}

FullScreenOverlay.propTypes = {
  closeOverlay: PropTypes.func,
}

const stopPropagation = e => e.stopPropagation()
