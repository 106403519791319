import React from 'react'
import PropTypes from 'prop-types'

import { SectionHeading } from './components/SectionHeading'
import { MessageBlock } from './MessageBlock'

export const MessageList = ({
  messages,
  title,
  publishButtonText,
  disableNoProgram,
  disableMentoring,
  greetingText,
  signatureText,
  ...handlers
}) =>
  messages &&
  messages.length > 0 && (
    <>
      <SectionHeading>{title}</SectionHeading>
      {messages.map((message, i) => (
        <MessageBlock
          {...message}
          {...handlers}
          greetingText={greetingText}
          signatureText={signatureText}
          disableNoProgram={disableNoProgram}
          disableMentoring={disableMentoring}
          key={message.subject + i}
          publishButtonText={publishButtonText}
        />
      ))}
    </>
  )

MessageList.propTypes = {
  title: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveMessage: PropTypes.func.isRequired,
  publishMessage: PropTypes.func.isRequired,
  unpublishMessage: PropTypes.func,
}
