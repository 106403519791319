import React from 'react'

import { useAdminUsers } from '../../../containers/AdminApi/AdminUsersApi'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import { Table, TH, TD, Action } from '../../../components/lists/Table'
import AdminUsersForm from './AdminUsersForm/AdminUsersForm'

const AdminUsersPage = () => {
  const {
    admin: { users, loading, error },
    addAdmin,
    removeAdmin,
  } = useAdminUsers()
  return (
    <AdminPage heading="Administrators" loading={loading} error={error}>
      <AdminUsersForm onSubmit={addAdmin} />
      <Table
        headings={
          <tr>
            <TH>Name</TH>
            <TH>Email</TH>
            <TH />
          </tr>
        }
        items={users}
      >
        {({ id, firstName, lastName, email }) => (
          <tr key={id}>
            <TD>
              {firstName} {lastName}
            </TD>
            <TD>{email}</TD>
            <TD right>
              <Action onClick={() => removeAdmin(id)}>Remove</Action>
            </TD>
          </tr>
        )}
      </Table>
    </AdminPage>
  )
}

export default AdminUsersPage
