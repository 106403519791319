import styled from 'styled-components'
import Button from '../Button/Button'

const FormInlineButton = styled(Button)`
  padding: 8px 0.6em;
  white-space: nowrap;
`

/** @component */
export default FormInlineButton
