import React from 'react'
import TD from '../../../../../components/lists/Table/TD'

const AdviceTrackingRow = ({ article: [articleTitle, articleData] }) => (
  <tr>
    <TD>{articleTitle}</TD>
    <TD>{articleData.noOfUsersWatched}</TD>
    <TD>{Math.round(articleData.averageNoOfViews)}</TD>
    <TD>{Math.round(articleData.allUsersAverage)}%</TD>
    <TD></TD>
  </tr>
)

export default AdviceTrackingRow
