import { useState, useEffect } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'
import { formatFormServerError } from '../../utils/forms/errors'

export const useAdviceArticles = () => {
  const [advice, setAdvice] = useState({ articles: [], laoding: true, saving: false })

  useEffect(() => {
    esAdminAPI('get', '/advice/articles')
      .then(articles => setAdvice({ articles, loading: false }))
      .catch(error => setAdvice(prev => ({ ...prev, error, loading: false })))
  }, [])

  const createArticle = async values => {
    setAdvice(prev => ({ ...prev, saving: true }))
    const formData = new FormData()

    Object.entries(values).forEach(([property, value]) => {
      formData.append(property, value)
    })

    await esAdminAPI('post', '/advice/article', null, {
      body: formData,
      headers: {},
    }).then(article => {
      setAdvice(prev => ({
        articles: [article, ...prev.articles],
        saving: false,
      }))
    })
  }

  const updateArticle = (id, values) => {
    setAdvice(prev => ({ ...prev, saving: true }))
    const formData = new FormData()

    Object.entries(values).forEach(([property, value]) => {
      formData.append(property, value)
    })

    return esAdminAPI('put', `/advice/article/${id}`, null, {
      body: formData,
      headers: {},
    }).then(updatedArticle =>
      setAdvice(prev => ({
        articles: prev.articles.map(articles =>
          articles.id !== updatedArticle.id ? articles : updatedArticle,
        ),
        saving: false,
      })),
    )
  }

  const setPublished = (id, published) =>
    esAdminAPI('put', `/advice/article/${id}/publish`, { published })
      .then(updatedArticle => {
        setAdvice(prev => ({
          articles: prev.articles.map(articles =>
            articles.id !== updatedArticle.id ? articles : updatedArticle,
          ),
        }))
      })
      .catch(formatFormServerError)

  const archiveArticle = id =>
    esAdminAPI('delete', `/advice/article/${id}`)
      .then(updatedArticle =>
        setAdvice(prev => ({
          articles: prev.articles.map(articles =>
            articles.id !== updatedArticle.id ? articles : updatedArticle,
          ),
        })),
      )
      .catch(formatFormServerError)

  return { advice, createArticle, updateArticle, setPublished, archiveArticle }
}
