import { authTokenStore } from './authTokenStore'

const empty = {}

export const esAPI =
  (uri) =>
  (method, path, data, { body, headers = empty } = empty) => {
    const options = {
      mode: 'cors',
      credentials: 'include',
      method: method.toUpperCase(),
      headers,
      body,
    }

    if (data) {
      headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(data)
    }

    headers['Authorization'] = authTokenStore.get()

    return fetch(`${uri}${path}`, options).then((res) => {
      if (res.ok && res.headers.has('Authorization')) {
        authTokenStore.set(res.headers.get('Authorization'))
      }
      return res.ok ? res.json() : res.json().then((result) => Promise.reject(result))
    })
  }

export default esAPI
