import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/input/Button/Button'
import { FormattedInput } from '../../../components/input/FormattedInput'
import { ModalWithAction } from '../../../components/input/ModalWithAction/ModalWithAction'
import { P } from '../../../components/typography'
import { formatDateTime } from '../../../utils/date'
import { toTitleCase } from '../../../utils/strings'
import { MessageForm } from './MessageForm'

const MessageContainer = styled.div`
  background: ${(props) => props.grayLight};
  width: 100%;
  box-shadow: 2px 2px 10px gray;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 30px;
`

const Subject = styled(P)`
  text-align: left;
  font-size: 25px;
  font-weight: bold;
`

const EditButton = styled(Button)`
  background: ${(props) =>
    props.answerExists ? props.theme.colors.gray : props.theme.colors.info};
  color: ${(props) => props.theme.colors.white};
  margin-right: 10px;
  margin-bottom: 10px;
`

const PublishButton = styled(Button)`
  background: ${(props) => (props.published ? props.theme.colors.error : props.theme.colors.green)};
  color: ${(props) => props.theme.colors.white};
  margin-right: 10px;
  margin-bottom: 10px;
`

const Buttons = styled.div`
  margin-top: 10px;
  display: flex;
`

const TopContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Targets = styled(P)`
  color: ${(props) => (!props.hasTargets ? 'red' : 'black')};
  text-align: left;
`

export const MessageBlock = ({
  id,
  subject,
  body,
  published,
  publishedDate,
  targetsNoProgram,
  targetsStarter,
  targetsFoundation,
  targetsAccelerator,
  saveMessage,
  publishMessage,
  unpublishMessage,
  mentoringStatusFilter,
  publishButtonText = 'Publish',
  disableNoProgram,
  disableMentoring,
  greetingText,
  signatureText,
}) => {
  const [editing, setEditing] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)

  const targetsArr = [
    targetsNoProgram && 'noProgram',
    targetsStarter && 'starter',
    targetsFoundation && 'foundation',
    targetsAccelerator && 'accelerator',
  ].filter(Boolean)

  let targetsStr = ''

  const pushToTargetStr = (val) => {
    if (targetsStr.length > 0) targetsStr += ', '
    targetsStr += val
  }

  if (targetsNoProgram) pushToTargetStr('No Program')
  if (targetsStarter) pushToTargetStr('Starter')
  if (targetsFoundation) pushToTargetStr('Foundation')
  if (targetsAccelerator) pushToTargetStr('Accelerator')

  return (
    <MessageContainer>
      <TopContent>
        <Subject>{subject}</Subject>
        {published && <P>{formatDateTime(publishedDate)}</P>}
        <Targets hasTargets={!!targetsStr}>
          <b>Programs: </b>
          {!targetsStr ? 'No recipients specified' : targetsStr}
        </Targets>
        {mentoringStatusFilter && !disableMentoring && (
          <Targets hasTargets>
            <b>Mentoring Status: </b>
            {toTitleCase(mentoringStatusFilter)}
          </Targets>
        )}
        {editing ? (
          <MessageForm
            messageId={id}
            messageBody={body}
            disableMentoring={disableMentoring}
            disableNoProgram={disableNoProgram}
            published={published}
            saveMessage={saveMessage}
            closeForm={() => setEditing(false)}
            onComplete={() => setEditing(false)}
            greetingText={greetingText}
            signatureText={signatureText}
            initialValues={{
              id,
              subject,
              body,
              targets: targetsArr,
              mentoringStatusFilter: !mentoringStatusFilter
                ? undefined
                : [
                    ['active', 'all'].includes(mentoringStatusFilter) && 'active',
                    ['inactive', 'all'].includes(mentoringStatusFilter) && 'inactive',
                  ].filter(Boolean),
            }}
          />
        ) : (
          <FormattedInput editing={false} stringifiedState={body} />
        )}
      </TopContent>
      {!editing &&
        // only show published state if able to unpublish (e.g. if we want to hide this for emails)
        Boolean(!published || unpublishMessage) && (
          <Buttons>
            <EditButton onClick={() => setEditing(true)}>Edit</EditButton>
            <PublishButton onClick={() => setConfirmModalVisible(true)} published={published}>
              {published ? 'Unpublish' : publishButtonText}
            </PublishButton>
          </Buttons>
        )}
      <ModalWithAction
        visible={confirmModalVisible}
        closeModal={() => setConfirmModalVisible(false)}
        confirmButtonColor={published ? 'primary' : 'green'}
        confirmButtonText={published ? 'Unpublish' : publishButtonText}
        onPressConfirm={() => (!published ? publishMessage(id) : unpublishMessage(id))}
      />
    </MessageContainer>
  )
}
