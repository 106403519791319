import styled from 'styled-components'
import { colors, fonts, fontWeights } from '../../../theme'

/**
 * A section heading which will render the `h4` HTML tag in the secondary (Lato) font.
 */
const H3Secondary = styled.h3`
  font-weight: ${fontWeights.bold};
  font-family: ${fonts.secondary};
  color: ${colors.black};
  font-size: 18px;
  line-height: 1.2;
  margin: 0;
`

/** @component */
export default H3Secondary
