import { useUserList } from './UsersListApi'
import {
  useAcceleratorContentTrackingQuery,
  useFoundationContentTrackingQuery,
  useStarterContentTrackingQuery,
} from './useContentTrackingQuery'

export const useStarterTrackingApi = ({ userId }) => {
  const { user } = useUserList({ userId })

  const { data, isLoading } = useStarterContentTrackingQuery(user?.user?.id, {
    refetchOnMount: 'always',
  })

  return { data, isLoading }
}

export const useFoundationTrackingApi = ({ userId }) => {
  const { user } = useUserList({ userId })

  const { data, isLoading } = useFoundationContentTrackingQuery(user?.user?.id, {
    refetchOnMount: 'always',
  })

  return { data, isLoading }
}

export const useAcceleratorTrackingApi = ({ userId }) => {
  const { user } = useUserList({ userId })

  const { data, isLoading } = useAcceleratorContentTrackingQuery(user?.user?.id, {
    refetchOnMount: 'always',
  })

  return { data, isLoading }
}
