import {
  videoProgressIndex,
  videoProgressStatus,
  contentLinks,
  foundationContent,
  foundationContentTitles,
} from '@es/common/src/config/progressDashboardConfig/foundationProgressDashboardConfig'
import React from 'react'
import { FoundationProgressAccordion } from './components'

export const FoundationProgressDashboard = ({ foundationProgressTrackingData }) => {
  const {
    foundationTrainingPercentageProgress,
    coreSessionVideoPercentageValue,
    coreSessionWrittenContentPercentageMetrics,
    coreSessionSuccessCheckListPercentageMetrics,
    eBookLibraryPercentageProgress,
    writtenContent,
    successChecklist,
    writtenContentAccessed,
    successChecklistAccessed,
    bookOfSecrets,
    raisingFunds,
    expertsAdvice,
    bookOfSecretsHasBeenAccessed,
    raisingMoneyHasBeenAccessed,
    expertAdviceHasBeenAccessed,
  } = foundationProgressTrackingData

  const {
    identifyingEntrepreneurshipOpportunityPage,
    establishingTheStartupPage,
    optimizeYourBrandPage,
    buildAGreatProductPage,
    businessPlanningPage,
    deliverSmartTeamPlanningPage,
    keyMetricTrackingPage,
    strategicThinkingToSuccessPage,
    sellingYourBusinessPage,
    bookOfSecretsPage,
    raisingFundsPage,
    expertsAdvicePage,
  } = contentLinks

  const { FOUNDATION_TRAINING_MODULE, EBOOK_LIBRARY } = foundationContent

  const {
    identifyingEntrepreneurshipOpportunity,
    establishingTheStartup,
    optimizeYourBrand,
    buildAGreatProduct,
    businessPlanning,
    deliverSmartTeamPlanning,
    keyMetricTracking,
    strategicThinkingToSuccess,
    sellingYourBusiness,
  } = foundationContentTitles

  const { ACCESSED_VALUE, PARTIALLY_COMPLETED_VALUE, COMPLETED_VALUE } = videoProgressIndex

  const { ACCESSED, PARTIALLY_COMPLETED, COMPLETED } = videoProgressStatus

  const [
    identifyingEntrepreneurshipOpportunityValue,
    establishingTheStartupValue,
    optimizeYourBrandValue,
    buildAGreatProductValue,
    businessPlanningValue,
    deliverSmartTeamPlanningValue,
    keyMetricTrackingValue,
    sellingYourBusinessValue,
    strategicThinkingToSuccessValue,
  ] = coreSessionVideoPercentageValue

  const [
    writtenContentForIdentifyingEntrepreneurshipOpportunityValue,
    writtenContentForEstablishingTheStartupValue,
    writtenContentForOptimizeYourBrandValue,
    writtenContentForBuildAGreatProductValue,
    writtenContentForBusinessPlanningValue,
    writtenContentForDeliverSmartTeamPlanningValue,
    writtenContentForKeyMetricTrackingValue,
    writtenContentForStrategicThinkingToSuccessValue,
    writtenContentForSellingYourBusinessValue,
  ] = coreSessionWrittenContentPercentageMetrics

  const [
    successCheckListForIdentifyingEntrepreneurshipOpportunityValue,
    successCheckListForEstablishingTheStartupValue,
    successCheckListForOptimizeYourBrandValue,
    successCheckListForBuildAGreatProductValue,
    successCheckListForBusinessPlanningValue,
    successCheckListForDeliverSmartTeamPlanningValue,
    successCheckListForKeyMetricTrackingValue,
    successCheckListForStrategicThinkingToSuccessValue,
    successCheckListForSellingYourBusinessValue,
  ] = coreSessionSuccessCheckListPercentageMetrics

  const detailedProgressDashboardProps = [
    {
      heading: FOUNDATION_TRAINING_MODULE.title,
      subheading: FOUNDATION_TRAINING_MODULE.subtitle,
      contents: [
        {
          title: identifyingEntrepreneurshipOpportunity,
          href: identifyingEntrepreneurshipOpportunityPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForIdentifyingEntrepreneurshipOpportunityValue,
              writtenContentAccessDate: writtenContentAccessed[0],
            },
            {
              title: successChecklist,
              complete: successCheckListForIdentifyingEntrepreneurshipOpportunityValue,
              successChecklistAccessDate: successChecklistAccessed[0],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: identifyingEntrepreneurshipOpportunityValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: identifyingEntrepreneurshipOpportunityValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: identifyingEntrepreneurshipOpportunityValue >= COMPLETED_VALUE,
            },
          ],
          complete: identifyingEntrepreneurshipOpportunityValue >= COMPLETED_VALUE,
        },
        {
          title: establishingTheStartup,
          href: establishingTheStartupPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForEstablishingTheStartupValue,
              writtenContentAccessDate: writtenContentAccessed[1],
            },
            {
              title: successChecklist,
              complete: successCheckListForEstablishingTheStartupValue,
              successChecklistAccessDate: successChecklistAccessed[1],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: establishingTheStartupValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: establishingTheStartupValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: establishingTheStartupValue >= COMPLETED_VALUE,
            },
          ],
          complete: establishingTheStartupValue >= COMPLETED_VALUE,
        },
        {
          title: optimizeYourBrand,
          href: optimizeYourBrandPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForOptimizeYourBrandValue,
              writtenContentAccessDate: writtenContentAccessed[2],
            },
            {
              title: successChecklist,
              complete: successCheckListForOptimizeYourBrandValue,
              successChecklistAccessDate: successChecklistAccessed[2],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: optimizeYourBrandValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: optimizeYourBrandValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: optimizeYourBrandValue >= COMPLETED_VALUE,
            },
          ],
          complete: optimizeYourBrandValue >= COMPLETED_VALUE,
        },
        {
          title: buildAGreatProduct,
          href: buildAGreatProductPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForBuildAGreatProductValue,
              writtenContentAccessDate: writtenContentAccessed[3],
            },
            {
              title: successChecklist,
              complete: successCheckListForBuildAGreatProductValue,
              successChecklistAccessDate: successChecklistAccessed[3],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: buildAGreatProductValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: buildAGreatProductValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: buildAGreatProductValue >= COMPLETED_VALUE,
            },
          ],
          complete: buildAGreatProductValue >= COMPLETED_VALUE,
        },
        {
          title: businessPlanning,
          href: businessPlanningPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForBusinessPlanningValue,
              writtenContentAccessDate: writtenContentAccessed[4],
            },
            {
              title: successChecklist,
              complete: successCheckListForBusinessPlanningValue,
              successChecklistAccessDate: successChecklistAccessed[4],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: businessPlanningValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: businessPlanningValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: businessPlanningValue >= COMPLETED_VALUE,
            },
          ],
          complete: businessPlanningValue >= COMPLETED_VALUE,
        },
        {
          title: deliverSmartTeamPlanning,
          href: deliverSmartTeamPlanningPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForDeliverSmartTeamPlanningValue,
              writtenContentAccessDate: writtenContentAccessed[5],
            },
            {
              title: successChecklist,
              complete: successCheckListForDeliverSmartTeamPlanningValue,
              successChecklistAccessDate: successChecklistAccessed[5],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: deliverSmartTeamPlanningValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: deliverSmartTeamPlanningValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: deliverSmartTeamPlanningValue >= COMPLETED_VALUE,
            },
          ],
          complete: deliverSmartTeamPlanningValue >= COMPLETED_VALUE,
        },
        {
          title: keyMetricTracking,
          href: keyMetricTrackingPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForKeyMetricTrackingValue,
              writtenContentAccessDate: writtenContentAccessed[6],
            },
            {
              title: successChecklist,
              complete: successCheckListForKeyMetricTrackingValue,
              successChecklistAccessDate: successChecklistAccessed[6],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: keyMetricTrackingValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: keyMetricTrackingValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: keyMetricTrackingValue >= COMPLETED_VALUE,
            },
          ],
          complete: keyMetricTrackingValue >= COMPLETED_VALUE,
        },
        {
          title: strategicThinkingToSuccess,
          href: strategicThinkingToSuccessPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForStrategicThinkingToSuccessValue,
              writtenContentAccessDate: writtenContentAccessed[7],
            },
            {
              title: successChecklist,
              complete: successCheckListForStrategicThinkingToSuccessValue,
              successChecklistAccessDate: successChecklistAccessed[7],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: strategicThinkingToSuccessValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: strategicThinkingToSuccessValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: strategicThinkingToSuccessValue >= COMPLETED_VALUE,
            },
          ],
          complete: strategicThinkingToSuccessValue >= COMPLETED_VALUE,
        },
        {
          title: sellingYourBusiness,
          href: sellingYourBusinessPage,
          subContents: [
            {
              title: writtenContent,
              complete: writtenContentForSellingYourBusinessValue,
              writtenContentAccessDate: writtenContentAccessed[8],
            },
            {
              title: successChecklist,
              complete: successCheckListForSellingYourBusinessValue,
              successChecklistAccessDate: successChecklistAccessed[8],
            },
          ],
          progress: [
            {
              status: ACCESSED,
              complete: sellingYourBusinessValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: sellingYourBusinessValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: sellingYourBusinessValue >= COMPLETED_VALUE,
            },
          ],
          complete: sellingYourBusinessValue >= COMPLETED_VALUE,
        },
      ],
      percentageProgress: foundationTrainingPercentageProgress,
    },
    {
      heading: EBOOK_LIBRARY.title,
      subheading: EBOOK_LIBRARY.subtitle,
      contents: [
        {
          title: bookOfSecrets,
          href: bookOfSecretsPage,
          complete: bookOfSecretsHasBeenAccessed,
          resourceAccessDate: bookOfSecretsHasBeenAccessed,
        },
        {
          title: raisingFunds,
          href: raisingFundsPage,
          complete: raisingMoneyHasBeenAccessed,
          resourceAccessDate: raisingMoneyHasBeenAccessed,
        },
        {
          title: expertsAdvice,
          href: expertsAdvicePage,
          complete: expertAdviceHasBeenAccessed,
          resourceAccessDate: expertAdviceHasBeenAccessed,
        },
      ],
      percentageProgress: eBookLibraryPercentageProgress,
    },
  ]

  return <FoundationProgressAccordion items={detailedProgressDashboardProps} />
}
