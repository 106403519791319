import React from 'react'
import styled from 'styled-components'
import { colors } from '../../../theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.first ? '40px 10px 15px' : '15px 10px')};
`

const HR = styled.hr`
  border: 0.5px solid ${colors.grayLight};
  width: 100%;
  ${props => props.noUnderLine && 'display: none'};
`

const ArticleSection = ({ children, ...props }) => (
  <Container {...props}>
    {children}
    <HR {...props} />
  </Container>
)

export default ArticleSection
