import { useState, useEffect } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'
import { formatFormServerError } from '../../utils/forms/errors'

export const useLiveSession = id => {
  const [liveSession, setLiveSession] = useState({ loading: true })

  useEffect(() => {
    if (typeof id === 'number') {
      esAdminAPI('get', `/sessions/live/${id}`)
        .then(session => setLiveSession({ data: session, loading: false }))
        .catch(error => setLiveSession(prev => ({ ...prev, error, loading: false })))
    }
  }, [])

  return liveSession
}

export const useLiveSessionDates = () => {
  const [liveSession, setLiveSession] = useState({ sessions: [], loading: true })

  useEffect(() => {
    esAdminAPI('get', '/sessions/live')
      .then(sessions => setLiveSession({ sessions, loading: false }))
      .catch(error => setLiveSession(prev => ({ ...prev, error, loading: false })))
  }, [])

  const addSessionDates = numberOfDates =>
    esAdminAPI('post', `/sessions/${numberOfDates}`).then(sessions =>
      setLiveSession(prev => ({ sessions: [...sessions, ...prev.sessions] })),
    )

  const updateSession = (id, values) =>
    esAdminAPI('put', `/sessions/live/${id}`, values)
      .then(updatedSession =>
        setLiveSession(prev => ({
          sessions: prev.sessions.map(session =>
            session.id !== updatedSession.id ? session : updatedSession,
          ),
        })),
      )
      .catch(formatFormServerError)

  const removeSession = id =>
    esAdminAPI('delete', `/sessions/live/${id}`)
      .then(() =>
        setLiveSession(prev => ({
          sessions: prev.sessions.filter(session => session.id !== id),
        })),
      )
      .catch(formatFormServerError)

  const sendNextSessionUpdateEmail = () =>
    esAdminAPI('post', `/sessions/live/notify-next-session`).catch(err => {
      alert(`Error sending email: ${err.message}`)
    })

  return { liveSession, addSessionDates, updateSession, sendNextSessionUpdateEmail, removeSession }
}
