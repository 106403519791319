import {
  videoProgressIndex,
  videoProgressStatus,
} from '@es/common/src/config/progressDashboardConfig/progressDashboardConfig'
import React from 'react'
import { ProgressAccordion } from './components'

export const StarterProgressDashboard = ({ starterProgressTrackingData }) => {
  const {
    mentorshipPercentageProgress,
    foundationTrainingPercentageProgress,
    adviceForEntrepreneursPercentageProgress,
    eBookLibraryPercentageProgress,
    trainingOn50MillionPercentageProgress,
    MENTORSHIP_WITH_MARTIN,
    FOUNDATION_TRAINING_MODULE,
    ADVICE_FOR_ENTREPRENEURS,
    EBOOK_LIBRARY,
    TRAINING_ON_50MM,
    mentoringPortalAccessed,
    userHasAccessedMentoringPortal,
    firstQuestionToMartin,
    threeQuestionsToMartin,
    userHasAskedAtLeastOneQuestion,
    userHasAskedAtLeastThreeQuestions,
    identifyingEntrepreneurshipOpportunity,
    writtenContent,
    successChecklist,
    writtenContentAccessed,
    successChecklistAccessed,
    userHasViewedFirstCoreSessionVideo,
    startingAGoodBusiness,
    gettingIntoTheInvestorsMind,
    firstConversationVideoPercentageValue,
    secondConversationVideoPercentageValue,
    bookOfSecrets,
    raisingFunds,
    expertsAdvice,
    bookOfSecretsHasBeenAccessed,
    raisingMoneyHasBeenAccessed,
    expertAdviceHasBeenAccessed,
    trainingOn50Million,
    bonusVideoOn50MillionPercentageValue,
  } = starterProgressTrackingData

  const { ACCESSED_VALUE, PARTIALLY_COMPLETED_VALUE, COMPLETED_VALUE } = videoProgressIndex

  const { ACCESSED, PARTIALLY_COMPLETED, COMPLETED } = videoProgressStatus

  const detailedProgressDashboardProps = [
    {
      heading: MENTORSHIP_WITH_MARTIN.title,
      subheading: MENTORSHIP_WITH_MARTIN.subtitle,
      contents: [
        {
          title: mentoringPortalAccessed,
          complete: userHasAccessedMentoringPortal,
          accessDate: userHasAccessedMentoringPortal,
        },
        {
          title: firstQuestionToMartin,
          complete: userHasAskedAtLeastOneQuestion,
        },
        {
          title: threeQuestionsToMartin,
          complete: userHasAskedAtLeastThreeQuestions,
        },
      ],
      percentageProgress: mentorshipPercentageProgress,
    },
    {
      heading: FOUNDATION_TRAINING_MODULE.title,
      subheading: FOUNDATION_TRAINING_MODULE.subtitle,
      contents: [
        {
          title: identifyingEntrepreneurshipOpportunity,
          progress: [
            {
              status: ACCESSED,
              complete: userHasViewedFirstCoreSessionVideo > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: userHasViewedFirstCoreSessionVideo >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: userHasViewedFirstCoreSessionVideo >= COMPLETED_VALUE,
            },
          ],
          complete: userHasViewedFirstCoreSessionVideo >= COMPLETED_VALUE,
        },
        {
          title: writtenContent,
          complete: writtenContentAccessed,
          accessDate: writtenContentAccessed,
        },
        {
          title: successChecklist,
          complete: successChecklistAccessed,
          accessDate: successChecklistAccessed,
        },
      ],
      percentageProgress: foundationTrainingPercentageProgress,
    },
    {
      heading: ADVICE_FOR_ENTREPRENEURS.title,
      subheading: ADVICE_FOR_ENTREPRENEURS.subtitle,
      contents: [
        {
          title: startingAGoodBusiness,
          progress: [
            {
              status: ACCESSED,
              complete: firstConversationVideoPercentageValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: firstConversationVideoPercentageValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: firstConversationVideoPercentageValue >= COMPLETED_VALUE,
            },
          ],
          complete: firstConversationVideoPercentageValue >= COMPLETED_VALUE,
        },
        {
          title: gettingIntoTheInvestorsMind,
          progress: [
            {
              status: ACCESSED,
              complete: secondConversationVideoPercentageValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: secondConversationVideoPercentageValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: secondConversationVideoPercentageValue >= COMPLETED_VALUE,
            },
          ],
          complete: secondConversationVideoPercentageValue >= COMPLETED_VALUE,
        },
      ],
      percentageProgress: adviceForEntrepreneursPercentageProgress,
    },
    {
      heading: EBOOK_LIBRARY.title,
      subheading: EBOOK_LIBRARY.subtitle,
      contents: [
        {
          title: bookOfSecrets,
          complete: bookOfSecretsHasBeenAccessed,
          accessDate: bookOfSecretsHasBeenAccessed,
        },
        {
          title: raisingFunds,
          complete: raisingMoneyHasBeenAccessed,
          accessDate: raisingMoneyHasBeenAccessed,
        },
        {
          title: expertsAdvice,
          complete: expertAdviceHasBeenAccessed,
          accessDate: expertAdviceHasBeenAccessed,
        },
      ],
      percentageProgress: eBookLibraryPercentageProgress,
    },
    {
      heading: TRAINING_ON_50MM.title,
      subheading: TRAINING_ON_50MM.subtitle,
      contents: [
        {
          title: trainingOn50Million,
          progress: [
            {
              status: ACCESSED,
              complete: bonusVideoOn50MillionPercentageValue > ACCESSED_VALUE,
            },
            {
              status: PARTIALLY_COMPLETED,
              complete: bonusVideoOn50MillionPercentageValue >= PARTIALLY_COMPLETED_VALUE,
            },
            {
              status: COMPLETED,
              complete: bonusVideoOn50MillionPercentageValue >= COMPLETED_VALUE,
            },
          ],
          complete: bonusVideoOn50MillionPercentageValue >= COMPLETED_VALUE,
        },
      ],
      percentageProgress: trainingOn50MillionPercentageProgress,
    },
  ]

  return <ProgressAccordion items={detailedProgressDashboardProps} />
}
