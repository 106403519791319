import React from 'react'
import PropTypes from 'prop-types'
import toDate from 'date-fns/toDate'
import styled from 'styled-components'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { colors, fonts, mediaMinWidth } from '../../../theme'

import './react-widgets-styles/import.scss'
import { FormattedInput } from '../FormattedInput'

const FormGroup = styled.div`
  margin-bottom: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: ${props => (props.row ? 'row' : 'column')};

  ${mediaMinWidth.sm`
    width: ${props => props.width};
  `};
`

const Label = styled.label`
  display: inline-block;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.2em;
  font-family: ${fonts.primary};
  font-size: 20px;
`

const TextInput = styled.input`
  border: 2px solid ${props => (props.error ? colors.error : colors.grayLight)};
  padding: 0.6em;
  font-family: ${fonts.secondary};
  font-size: 18px;
  width: 100%;

  ::placeholder {
    font-family: ${fonts.primary};
    color: ${colors.black};
    font-size: 20px;
  }
`

const StaticText = styled.div`
  padding: 0.6em;
  font-family: ${fonts.secondary};
  font-size: 18px;
  width: 100%;
`

const TextAreaInput = styled.textarea`
  border: 2px solid ${props => (props.error ? colors.error : colors.grayLight)};
  padding: 0.6em;
  width: 100%;

  font-family: ${fonts.secondary};
  font-size: 16px;
  line-height: 1.3;

  ::placeholder {
    font-family: ${fonts.primary};
    color: ${colors.black};
    font-size: 20px;
  }
`

// Select inputs don't have placeholder text, instead the default option
// is styled like the placeholders of the other inputs.
const SelectInput = styled.select`
  background-color: ${colors.white};
  border: 2px solid ${props => (props.error ? colors.error : colors.grayLight)};
  font-family: ${props => (props.value || !props.placeholder ? fonts.secondary : fonts.primary)};
  font-size: ${props => (props.value || !props.placeholder ? '17px' : '20px')};
  padding: ${props => (props.value || !props.placeholder ? '10px 10px 11px' : '7px')};
  width: 100%;
  height: 45px;

  > option:first-child {
    ${props =>
      !props.placeholder
        ? ''
        : `
      font-family: ${fonts.primary};
      color: ${colors.black};
      font-size: 20px;
    `};
  }

  > option {
    font-family: ${fonts.secondary};
    color: ${colors.black};
    font-size: 17px;
  }
`

const DatePickerInput = styled(DateTimePicker)`
  &.rw-widget {
    padding: 0;

    .rw-widget-container {
      border: 2px solid ${props => (props.error ? colors.error : colors.grayLight)};
      border-radius: 0;
      padding: 0;
      font-family: ${fonts.secondary};
      font-size: 18px;
      width: 100%;
    }

    .rw-input {
      padding: 8px 6px;
    }
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const Help = styled.p`
  margin-top: 0.3em;
  font-family: ${fonts.secondary};
  font-size: 16px;
  line-height: 1.3;
  color: ${colors.grayDark};
`

const Error = styled(Help)`
  color: ${colors.error};
`

/**
 * An input component for use with `react-final-form`.
 */
const Input = React.forwardRef(
  (
    {
      input,
      meta,
      label,
      help,
      hideError,
      children,
      componentClass,
      width,
      style,
      className,
      errorOnlyIfDirty,
      ...props
    },
    ref,
  ) => {
    const { dirty, touched, submitFailed, error, submitError } = meta || {}
    const showError =
      (!errorOnlyIfDirty || dirty || submitFailed) &&
      !hideError &&
      touched &&
      (submitError || error)
    const InputComp = componentClass || TextInput

    return (
      <FormGroup width={width} className={className} style={style}>
        {label && <Label htmlFor={input && input.name}>{label}</Label>}
        <InputComp type="text" {...props} {...input} error={showError} ref={ref}>
          {children}
        </InputComp>
        {showError && <Error>{showError}</Error>}
        {help && <Help>{help}</Help>}
      </FormGroup>
    )
  },
)

Input.propTypes = {
  /** Provide an input component to override the default `TextInput` */
  componentClass: PropTypes.any,
  /** Set the width of the input group */
  width: PropTypes.string,
  /**
   * When auto-focusing the first component it will show a required validation error on blur; set
   * this to true and it will only show the error if the input is dirty or after a failed submit
   */
  errorOnlyIfDirty: PropTypes.bool,
}

Input.defaultProps = {
  width: '100%',
}

Input.Help = Help
Input.Label = Label
Input.Text = TextInput
Input.TextArea = TextAreaInput

Input.Select = ({ placeholder, children, ...props }) => (
  <SelectInput {...props} placeholder={placeholder}>
    {placeholder && (
      <option value="" hidden>
        {placeholder}
      </option>
    )}
    {children}
  </SelectInput>
)

Input.FormattedInput = ({ value, ...props }) => (
  <FormattedInput
    {...props}
    plainText={value.plainText}
    stringifiedState={value.stringifiedState}
  />
)

Input.DateTimePicker = ({ dateFormat, value, onBlur, children, ...props }) => (
  <DatePickerInput
    {...props}
    value={toDate(value)}
    onBlur={() => onBlur(toDate(value))}
    format={dateFormat}
  />
)

Input.StaticText = ({ style, textStyle, ...props }) => (
  <FormGroup style={style}>
    <StaticText {...props} style={textStyle} />
  </FormGroup>
)

Input.CheckboxGroup = ({ fields, options, initial, style, textStyle = {}, ...props }) => {
  const toggle = (event, option) => {
    if (event.target.checked) {
      fields.push(option)
    } else if (!event.target.checked) {
      const index = fields.value.indexOf(option)
      index > -1 && fields.remove(index)
    }
  }
  return (
    <FormGroup style={style} {...props}>
      {Object.entries(options).map(([value, category]) => (
        <CheckboxContainer key={value}>
          <StaticText style={{ textAlign: 'left', width: 130, ...textStyle }}>
            {category}
          </StaticText>
          <input
            defaultChecked={initial && initial.includes(value)}
            type="checkbox"
            onClick={event => toggle(event, value)}
          />
        </CheckboxContainer>
      ))}
    </FormGroup>
  )
}

export default Input
