import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import UserForm from '../../../../../components/auth/UserForm/UserForm'
import EditIcon from '../../../../../components/icons/EditIcon'
import { TD } from '../../../../../components/lists/Table'
import { PhoneNumberView } from './PhoneNumberView'

const RemoveButton = styled.span`
  cursor: pointer;
`

const CloseButton = styled.span`
  cursor: pointer;
  user-select: none;
  margin-left: -10px;
`

const MaxWidthCell = styled(TD)`
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  text-align: left;
`

const phoneVerificationState = (verifiedPhone) => (verifiedPhone ? 'Verified' : 'Unverified')

class LeadRow extends React.Component {
  state = { edit: false }

  toggleEdit = (ev) => {
    ev && ev.stopPropagation()
    this.setState((state) => ({ edit: !state.edit }))
  }

  handleUpdate = (values) => {
    const { user } = this.props
    return this.props
      .onSave(user.id, {
        ...user,
        ...values,
      })
      .then((formError) => formError || this.toggleEdit())
  }

  render() {
    const { adviceTracking, coreSessionTracking, onSave, remove, user, isUnverifiedPhonesData } = this.props
    const {
      city,
      country,
      date,
      updatedDate,
      email,
      firstName,
      id,
      lastName,
      phone,
      source,
      masterclassDate,
      verifiedPhone,
      telesalesSource,
      timeZone,
      utmCampaign,
      utmContent,
      utmMedium,
      utmSource,
      utmTerm,
    } = user

    if (onSave && this.state.edit) {
      return (
        <tr>
          <td colSpan={30}>
            <CloseButton onClick={() => this.setState({ edit: false })}>&times;</CloseButton>
            <UserForm submitText="Save" onSubmit={this.handleUpdate} initialValues={user} />
          </td>
        </tr>
      )
    }
    return (
      <tr>
        <TD>
          <Link
            to={{
              pathname: `/tracking/${firstName}/${lastName}/${id}`,
              state: {
                coreSessionTracking,
                adviceTracking,
              },
            }}
          >
            {firstName} {lastName ?? ''}
          </Link>
        </TD>
        <TD>{email}</TD>
        <TD>
          <PhoneNumberView phoneNumber={phone} />
        </TD>
        {!isUnverifiedPhonesData && <TD>{phoneVerificationState(verifiedPhone)}</TD>}
        {!isUnverifiedPhonesData && <TD>{masterclassDate ? format(masterclassDate, 'PPP') : '-'}</TD>}
        <MaxWidthCell>{source}</MaxWidthCell>
        <TD>{utmSource}</TD>
        <TD>{utmMedium}</TD>
        <TD>{utmCampaign}</TD>
        <TD>{utmTerm}</TD>
        <TD>{utmContent}</TD>
        {!isUnverifiedPhonesData && <TD>{telesalesSource}</TD>}
        <TD>{country}</TD>
        <TD>{city}</TD>
        <TD>{timeZone ?? '-'}</TD>
        <TD>{format(date, 'PPP')}</TD>
        {!isUnverifiedPhonesData && (
          <>
            <TD>{format(date, 'p')}</TD>
            <TD>{format(updatedDate, 'PPP')}</TD>
            <TD>{format(updatedDate, 'p')}</TD>
            <TD>{onSave && <EditIcon title="Edit User" onClick={this.toggleEdit} />}</TD>
            <TD>
              <RemoveButton
                onClick={() =>
                  window.confirm(`Are you sure you want to remove this user?\n ${firstName} ${lastName}\n ${email}`) &&
                  remove(id)
                }
              >
                Remove
              </RemoveButton>
            </TD>
          </>
        )}
      </tr>
    )
  }
}

LeadRow.propTypes = {
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func,
}

export default LeadRow
