import React from 'react'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import { useUserList } from '../../../containers/AdminApi/UsersListApi'
import PaidUsersTable from './PaidUsersTable/PaidUsersTable'

const PaidUsersPage = () => {
  const {
    userList: { users, loading, error },
    updateUser,
    archiveUser,
    archiveMultipleUsers,
    selectedUsers,
    setSelectedUsers,
  } = useUserList({ userType: 'paid' })
  return (
    <AdminPage fullWidth heading="Paid Users" loading={loading} error={error}>
      <PaidUsersTable
        users={users.filter((user) => !user.archived)}
        updateUser={updateUser}
        emptyMsg="There are no paid users yet"
        archiveUser={archiveUser}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        archiveMultipleUsers={archiveMultipleUsers}
      />
    </AdminPage>
  )
}

export default PaidUsersPage
