const MENTORSHIP = '365/24 Direct Mentorship with Martin'
const FOUNDATION_TRAINING = 'Foundation Training Module'
const ADVICE = 'Advice for Entrepreneurs (Conversations series)'
const EBOOK = 'E-book Library'
const BONUS_TRAINING = 'Bonus Sessions'
const OTHER_BENEFITS = 'Other Benefits'

export const acceleratorContentLinks = {
  mentoringPortalPage: `/course/mentoring`,
  identifyingEntrepreneurshipOpportunityPage: `/course/identifying-entrepreneurship-opportunity`,
  startingAGoodBusinessPage: `/course/teaching/30`,
  gettingIntoTheInvestorsMindPage: `/course/teaching/32`,
  whatIHaveLearntAsAnEntrepreneurPage: `/course/teaching/33`,
  secretsToGoodHiringPage: `/course/teaching/34`,
  behindTheScenesOfAGreatProductPage: `/course/teaching/35`,
  bookOfSecretsPage: `/pdf/book-of-secrets`,
  raisingFundsPage: `/pdf/raising-money`,
  expertsAdvicePage: `/pdf/expert-advice`,
  trainingOn50MillionPage: `/course/webinar`,
  developAGreatProductPage: `/course/bonus-content/21`,
  exitYourCompanyPage: `/course/bonus-content/22`,
}

export const investorTitle = "Getting into the Investor's Mind"
export const customerSupportTitle = 'Get in touch with customer support'
export const writtenContentText = 'Read Written Content'
export const successChecklistText = 'Read Success Checklist'

export const succeeded = 'succeeded'
export const requiresAction = 'requires_action'
export const scheduled = 'scheduled'

export const pdfNames = {
  materials: 'materials',
  checklist: 'checklist',
}

export const acceleratorContent = {
  MENTORSHIP_WITH_MARTIN: {
    title: MENTORSHIP,
    subtitle: 'Access Personal Mentorship Directly From A World-Leading Entrepreneur',
    subContent: {
      mentoringPortalAccessed: 'Mentoring Portal Accessed',
      firstQuestionToMartin: 'First Question to Martin',
      threeQuestionsToMartin: 'Three Questions to Martin',
    },
  },
  FOUNDATION_TRAINING_MODULE: {
    title: FOUNDATION_TRAINING,
    subtitle: 'Take advantage of our first foundation training module ',
    subContent: {
      identifyingEntrepreneurshipOpportunity:
        'How to Identify Opportunities in Entrepreneurship - Video',
      writtenContent: writtenContentText,
      successChecklist: successChecklistText,
    },
  },
  ADVICE_FOR_ENTREPRENEURS: {
    title: ADVICE,
    subtitle: 'Access Martin’s popular conversations series',
    subContent: {
      startingAGoodBusiness: 'How Do You Start a Good Business?',
      gettingIntoTheInvestorsMind: investorTitle,
      whatIHaveLearntAsAnEntrepreneur: "What I've Learnt As An Entrepreneur",
      secretsToGoodHiring: 'Secrets To Good Hiring',
      behindTheScenesOfAGreatProduct: 'Behind The Scenes of A Great Product',
    },
  },
  EBOOK_LIBRARY: {
    title: EBOOK,
    subtitle: 'Access Years Of Business Knowledge And Success',
    subContent: {
      bookOfSecrets: 'Book of Secrets',
      raisingFunds: 'Raising Funds for Your Business',
      expertsAdvice: "Expert's Advice",
    },
  },
  BONUS: {
    title: BONUS_TRAINING,
    subtitle: 'Secrets to developing a great product & exiting your business',
    subContent: {
      developAGreatProduct: 'A-Z of how to develop a great product',
      exitYourCompany: 'A-Z of how to exit your company',
    },
  },
  OTHER_BENEFITS_SECTION: {
    title: OTHER_BENEFITS,
    subtitle: 'Loyalty program, Cash reward, help & more',
    subContent: {
      martinsBlog: 'Don’t miss Martin’s popular blog, where he publishes key insights twice a week',
      referrals:
        'Find out about our referral program that provides you with an excellent cashback reward',
      testimonials: 'Find out how you can write testimonials in the portal',
      customerSupport: customerSupportTitle,
    },
  },
}

const {
  MENTORSHIP_WITH_MARTIN,
  FOUNDATION_TRAINING_MODULE,
  ADVICE_FOR_ENTREPRENEURS,
  EBOOK_LIBRARY,
  BONUS,
} = acceleratorContent

const {
  subContent: { mentoringPortalAccessed, firstQuestionToMartin, threeQuestionsToMartin },
} = MENTORSHIP_WITH_MARTIN
const {
  subContent: { identifyingEntrepreneurshipOpportunity, writtenContent, successChecklist },
} = FOUNDATION_TRAINING_MODULE
const {
  subContent: {
    startingAGoodBusiness,
    gettingIntoTheInvestorsMind,
    whatIHaveLearntAsAnEntrepreneur,
    secretsToGoodHiring,
    behindTheScenesOfAGreatProduct,
  },
} = ADVICE_FOR_ENTREPRENEURS
const {
  subContent: { bookOfSecrets, raisingFunds, expertsAdvice },
} = EBOOK_LIBRARY
const {
  subContent: { developAGreatProduct, exitYourCompany },
} = BONUS

export const acceleratorContentTitles = {
  startingAGoodBusiness,
  gettingIntoTheInvestorsMind,
  whatIHaveLearntAsAnEntrepreneur,
  secretsToGoodHiring,
  behindTheScenesOfAGreatProduct,
}
/*
 * The number values in the accessed, partiallyComplete and complete properties in the object below
 * corresponds to actual percentage values that are assigned to the section (parent property).
 * However, for the other object properties with number values (i.e. the ones that are not named 'accessed', 'partiallyComplete' or 'complete'),
 * the actual percentage assigned to the section (parent property e.g [MENTORSHIP])
 * will be an addition of their values (i.e. mentoringPortalAccessed + firstQuestionToMartin + threeQuestionsToMartin).
 */

export const progressCalculationMetric = {
  [MENTORSHIP]: {
    [mentoringPortalAccessed]: 20,
    [firstQuestionToMartin]: 30,
    [threeQuestionsToMartin]: 50,
  },
  [FOUNDATION_TRAINING]: {
    [identifyingEntrepreneurshipOpportunity]: {
      accessed: 5,
      partiallyComplete: 20,
      complete: 60,
    },
    [writtenContent]: 20,
    [successChecklist]: 20,
  },
  [ADVICE]: {
    [startingAGoodBusiness]: {
      accessed: 5,
      partiallyComplete: 15,
      complete: 20,
    },
    [gettingIntoTheInvestorsMind]: {
      accessed: 5,
      partiallyComplete: 15,
      complete: 20,
    },
    [whatIHaveLearntAsAnEntrepreneur]: {
      accessed: 5,
      partiallyComplete: 15,
      complete: 20,
    },
    [secretsToGoodHiring]: {
      accessed: 5,
      partiallyComplete: 15,
      complete: 20,
    },
    [behindTheScenesOfAGreatProduct]: {
      accessed: 5,
      partiallyComplete: 15,
      complete: 20,
    },
  },
  [EBOOK]: {
    [bookOfSecrets]: 35,
    [raisingFunds]: 35,
    [expertsAdvice]: 30,
  },
  [BONUS_TRAINING]: {
    [developAGreatProduct]: {
      accessed: 5,
      partiallyComplete: 20,
      complete: 50,
    },
    [exitYourCompany]: {
      accessed: 5,
      partiallyComplete: 20,
      complete: 50,
    },
  },
}

export const videoProgressStatus = {
  ACCESSED: 'Accessed',
  PARTIALLY_COMPLETED: 'Partially Completed',
  COMPLETED: 'Completed',
}

// Represents the percentage of video content watched.
export const videoProgressIndex = {
  ACCESSED_VALUE: 0,
  PARTIALLY_COMPLETED_VALUE: 25,
  COMPLETED_VALUE: 75,
}

// Database id of the session video (This is the actual ID of the content in the database)
export const videoContentID = {
  idOfFirstConversationVideo: 30,
  idOfSecondConversationVideo: 32,
  idOfThirdConversationVideo: 33,
  idOfFourthConversationVideo: 34,
  idOfFifthConversationVideo: 35,
  idOf50MMTrainingVideo: 25,
}

export const conversationVideoIDs = [30, 32, 33, 34, 35]

export const bonusSessionVideoIDs = [21, 22]
