import format from 'date-fns-tz/format'

export const timeZones = [
  'America/Los_Angeles',
  'America/Chicago',
  'America/New_York',
  'Europe/London',
]

export const defaultTimeZone = 'America/New_York'

export const formatTZName = (date, timeZone) => format(date, 'zzzz', { timeZone })
