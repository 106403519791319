import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { P } from '../../../components/typography'
import { mySqlToLongDateTime } from '../../../utils/date'

const Background = styled.div`
  border-radius: 10px;
  background: ${props => props.theme.colors.grayLight};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Detail = styled(P)`
  margin-bottom: 10px;
`

export const UserDetails = ({ user }) => {
  const {
    date,
    availableContent = {},
    firstName,
    lastName,
    email,
    city,
    country,
    phone,
    promoCode,
    source,
    referrer,
  } = user
  const {
    acceleratorProgram,
    foundationProgram,
    starterProgram,
    mentoringExpiryDate,
  } = availableContent

  const program = acceleratorProgram
    ? 'Accelerator'
    : foundationProgram
    ? 'Foundation'
    : starterProgram
    ? 'Starter'
    : 'None'

  return (
    <Background>
      <Detail>
        <b>Name:</b> {firstName} {lastName}
      </Detail>
      <Detail>
        <b>Email:</b> {email}
      </Detail>
      <Detail>
        <b>Created:</b> {mySqlToLongDateTime(date)}
      </Detail>
      <Detail>
        <b>Program:</b> {program}
      </Detail>
      <Detail>
        <b>Mentoring Expiry Date:</b>{' '}
        {!mentoringExpiryDate
          ? 'Does not exist or is not set explicitly'
          : mySqlToLongDateTime(mentoringExpiryDate)}
      </Detail>
      <Detail>
        <b>Referred by:</b>{' '}
        {referrer && (
          <Link
            to={{
              pathname: `/tracking/${referrer.firstName}/${referrer.lastName}/${referrer.id}`,
            }}
          >
            {referrer.firstName} ({referrer.email})
          </Link>
        )}
      </Detail>
      <Detail>
        <b>Country:</b> {country}
      </Detail>
      <Detail>
        <b>City:</b> {city}
      </Detail>
      <Detail>
        <b>Phone:</b> {phone}
      </Detail>
      <Detail>
        <b>Promo Code:</b> {promoCode}
      </Detail>
      <Detail>
        <b>Source:</b> {source}
      </Detail>
    </Background>
  )
}
