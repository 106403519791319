import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { Icon } from '../../../../../components/icons/Icon'
import { View } from '../../../../../components/layout'
import { colors } from '../../../../../theme'
import { TrackedItem } from './'

const fairProgress = 'fair'
const goodProgress = 'good'
const excellentProgress = 'excellent'

const Container = styled(View)`
  align-items: center;
  justify-content: center;
  ${media.lg`
  align-items: flex-start;
    flex-direction: row;
  `}

  p {
    user-select: none;
  }
  h3 {
    user-select: none;
  }
`

const List = styled(View)`
  flex: 1;
  justify-content: center;
  padding: 16px;
  padding-top: 32px;
`

const ItemWrapper = styled(View)`
  width: 100%;
`

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
const PercentageProgress = styled.div`
  font-weight: bold;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme, progressCategory }) =>
    progressCategory === fairProgress
      ? colors.errorRed
      : progressCategory === goodProgress
      ? colors.orangeYellow
      : colors.brightGreen};

  ${media.lg`
    padding: 0 16px;
  `}
`

const ItemHeading = styled.div`
  margin-bottom: ${(props) => (!props.isOpen ? 0 : 16)}px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`
const Item = styled(View)`
  padding: 32px;
  cursor: pointer;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  box-shadow: 2px 2px 10px gray;
  border-radius: 10px;

  ${media.md`
    align-items: center;
  `}
`

const ButtonWrapper = styled(View)`
  padding-left: 32px;
  flex-shrink: 0;
  align-self: flex-start;
`

const ItemTitle = styled.div`
  font-weight: bold;
  text-align: start;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const ItemSubTitle = styled.div`
  font-size: 16px;
  text-align: start;
  font-weight: normal;
`

const SectionTitles = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: -15px;
  text-decoration: underline;
  font-weight: bold;
`

const Description = styled.div`
  margin-left: 20px;
`
const Completed = styled.div`
  margin-left: 80px;
`
const Expand = styled.div`
  margin-left: 35px;
  padding-right: 10px;
`

const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 35%;
  align-items: flex-end;
`

export const ProgressAccordion = ({ items = [] }) => {
  const [openItemIndex, setOpenItemIndex] = useState(-1)
  const desktopView = useMediaQuery({ minWidth: 1000 })

  return (
    <>
      <SectionTitles>
        <Description>Module Description</Description>
        <FlexRight>
          {desktopView && <Completed>Completed</Completed>}
          <Expand>Expand</Expand>
        </FlexRight>
      </SectionTitles>
      <Container>
        <List>
          {items.map(({ heading, subheading, contents, percentageProgress }, index, array) => {
            const isOpen = index === openItemIndex
            const numbering = index + 1
            const progressCategory =
              percentageProgress <= 50
                ? fairProgress
                : percentageProgress < 100
                ? goodProgress
                : excellentProgress
            return (
              <Item key={heading} onClick={() => setOpenItemIndex(isOpen ? -1 : index)}>
                <ItemWrapper>
                  <ItemHeader>
                    <ItemHeading isOpen={isOpen}>
                      <Title>
                        <ItemTitle>{`${numbering}. ${heading}`}</ItemTitle>
                        <ItemSubTitle>{subheading}</ItemSubTitle>
                      </Title>
                      <PercentageProgress progressCategory={progressCategory}>
                        {percentageProgress}% {!desktopView && `Completed`}
                      </PercentageProgress>
                    </ItemHeading>
                    <ButtonWrapper>
                      <Icon name={isOpen ? 'minus' : 'plus'} color={colors.primary} />
                    </ButtonWrapper>
                  </ItemHeader>
                  {isOpen && (
                    <>
                      {contents.map((content, index) => {
                        return <TrackedItem key={content.title} index={index} {...content} />
                      })}
                    </>
                  )}
                </ItemWrapper>
              </Item>
            )
          })}
        </List>
      </Container>
    </>
  )
}
