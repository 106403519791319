import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, fonts } from '../../../theme'

const Error = styled.div`
  font-family: ${fonts.secondary};
  color: ${colors.error};
  font-size: 18px;
  text-align: center;
  margin-bottom: 1em;
  width: 100%;
`

const FormError = ({ submitting, error }) => (error && !submitting ? <Error>{error}</Error> : null)

FormError.propTypes = {
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default FormError
