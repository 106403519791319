import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm, Field } from 'react-final-form'
import styled from 'styled-components'
import Button from '../../../components/input/Button/Button'
import FormError from '../../../components/input/FormError/FormError'
import Input from '../../../components/input/Input/Input'

const UpdateButton = styled(Button)`
  background: ${props => props.theme.colors.green};
  padding: 10px;
  margin: 0;
  font-size: 17px;
  height: fit-content;
  margin-bottom: 20px;
  max-width: 200px;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const UpdateQuestionForm = props => {
  return (
    <FinalForm
      {...props}
      render={({ handleSubmit, submitting, submitError }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field
            autoFocus
            name="questionBody"
            component={Input}
            componentClass={Input.TextArea}
            defaultValue={props.initialValue}
          />
          <FormError submitting={submitting} error={submitError} />
          <UpdateButton solid type="submit" disabled={submitting}>
            {submitting ? 'Saving...' : 'Update Question'}
          </UpdateButton>
        </StyledForm>
      )}
    />
  )
}

UpdateQuestionForm.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
