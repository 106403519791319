import React from 'react'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import AdviceTrackingTable from './AdviceTrackingTable/AdviceTrackingTable'
import { useAdviceTracking } from '../../../containers/AdminApi/TrackingDataApi'

const AdviceTrackingPage = () => {
  const { adviceViewingData, loading, error } = useAdviceTracking()
  return (
    <AdminPage heading="Advice Page Tracking">
      {loading ? (
        <LoadingSection />
      ) : (
        <ErrorSection error={error}>
          <AdviceTrackingTable viewingData={adviceViewingData} />
        </ErrorSection>
      )}
    </AdminPage>
  )
}

export default AdviceTrackingPage
