import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { addMonths, format, getYear, isBefore } from 'date-fns'
import { orderBy } from 'lodash/fp'
import PropTypes from 'prop-types'
import Button from '../../../../components/input/Button/Button'
import { ModalWithAction } from '../../../../components/input/ModalWithAction'
import { Table, TH } from '../../../../components/lists/Table'
import { DateFilterCell } from '../../../../components/lists/Table/DateFilterCell'
import { DropDownFilterCell } from '../../../../components/lists/Table/DropdownFilterCell'
import { TextFilterCell } from '../../../../components/lists/Table/TextFilterCell'
import { useAdviceTracking, useSessionTracking } from '../../../../containers/AdminApi/TrackingDataApi'
import { useFilterUsers } from '../../../../hooks/useFilterUsers'
import { onlyUnique } from '../../../../utils/arrays'
import { createCsvData, CSV_HEADINGS } from '../../../../utils/csv'
import PaidUserRow from './PaidUserRow/PaidUserRow'

const orderByPaymentDate = orderBy(['paymentDate'], ['desc'])

const HEADINGS = [
  { text: 'First Name', key: 'firstName' },
  { text: 'Email', key: 'email' },
  { text: 'Phone', key: 'phone' },
  { text: 'URL', key: 'source' },
  { text: 'UTM Source', key: 'utmSource' },
  { text: 'UTM Medium', key: 'utmMedium' },
  { text: 'UTM Name', key: 'utmCampaign' },
  { text: 'UTM Term', key: 'utmTerm' },
  { text: 'UTM Content', key: 'utmContent' },
  { text: 'Program', key: 'program' },
  { text: 'Program Status', key: 'programStatus' },
  { text: 'Program Expiry', key: 'programExpiryDate' },
  { text: 'Mentoring Expiry', key: 'mentoringExpiryDate' },
  { text: 'Has Password', key: 'hasPassword' },
  { text: 'Live Session', key: 'liveSession' },
  { text: 'Purchase Date', key: 'paymentDate' },
  { text: 'Accessed Sessions', key: 'accessedSessions' },
  { text: 'Accessed PDFs', key: 'accessedWrittenContent' },
  { text: 'Telesales Assignee', key: 'telesalesSource' },
  { text: 'Promo Code', key: 'promoCode' },
  { text: 'Country', key: 'country' },
  { text: 'City', key: 'city' },
  { text: 'Time Zone', key: 'timeZone' },
  { text: 'Referrer Email', key: 'referrerEmail' },
]

const CSVButton = styled(CSVLink)`
  margin-left: 10px;
  color: black;
  text-decoration: underline;
`

const PaidUsersTable = ({
  users,
  updateUser,
  emptyMsg,
  archiveUser,
  archiveMultipleUsers,
  selectedUsers,
  setSelectedUsers,
}) => {
  const adviceTracking = useAdviceTracking()
  const { coreSessionTracking, sessionTrackingHandlers } = useSessionTracking()

  useEffect(() => {
    sessionTrackingHandlers.allCoreAndBonus()
  }, [])

  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [isRemoveMultipleUsersModalVisible, setIsRemoveMultipleUsersModalVisible] = useState(false)
  const [userToRemove, setUserToRemove] = useState(null)
  const usersWithExtraFields = users.map(addExtraFields)

  // TODO: this mapping/sorting should be done as part of the DB query. Might involve a new column for
  // last purchase date, or just modifying the query.
  const { filters, clearFilters, setSingleFilter, filtersApplied, filteredUsers } = useFilterUsers(usersWithExtraFields)

  const sortedUsers = useMemo(() => orderByPaymentDate(filteredUsers), [filteredUsers])

  const toggleCheckBox = (e, userId) => {
    if (e.target.checked) {
      setSelectedUsers((prev) => [...prev, userId])
    } else {
      setSelectedUsers((prev) => prev.filter((_id) => _id !== userId))
    }
  }

  const getSelectedUsers = useCallback(
    (selectedUserIds = []) => {
      const currentSelectedUsers = []

      selectedUserIds.forEach((userId) => {
        currentSelectedUsers.push(users.find((u) => u.id === userId))
      })

      return currentSelectedUsers
    },
    [selectedUsers],
  )

  const getUsersForCSV = () => {
    if (selectedUsers.length > 0) {
      return getSelectedUsers(selectedUsers)
        .map(addExtraFields)
        .map((user) => ({
          ...user,
          date: format(user.date, 'PPP p'),
          programStatus: isBefore(new Date(), user.availableContent?.mentoringExpiryDate) ? 'Active' : 'InActive',
          programExpiryDay: format(user?.programExpiryDate, 'd') || '',
          programExpiryMonth: format(user?.programExpiryDate, 'MMM') || '',
          programExpiryYear: getYear(user?.programExpiryDate) || '',
          mentoringExpiryDate: user.availableContent?.mentoringExpiryDate || '',
          mentoringExpiryDay: format(user.availableContent?.mentoringExpiryDate, 'd'),
          mentoringExpiryMonth: format(user.availableContent?.mentoringExpiryDate, 'MMM'),
          mentoringExpiryYear: getYear(user.availableContent?.mentoringExpiryDate),
        }))
    }

    return sortedUsers.map((user) => ({
      ...user,
      date: format(user.date, 'PPP p'),
      programStatus: isBefore(new Date(), user.availableContent?.mentoringExpiryDate) ? 'Active' : 'InActive',
      programExpiryDay: format(user?.programExpiryDate, 'd') || '',
      programExpiryMonth: format(user?.programExpiryDate, 'MMM') || '',
      programExpiryYear: getYear(user?.programExpiryDate) || '',
      mentoringExpiryDate: user.availableContent?.mentoringExpiryDate || '',
      mentoringExpiryDay: format(user.availableContent?.mentoringExpiryDate, 'd'),
      mentoringExpiryMonth: format(user.availableContent?.mentoringExpiryDate, 'MMM'),
      mentoringExpiryYear: getYear(user.availableContent?.mentoringExpiryDate),
    }))
  }

  return (
    <>
      <Button style={{ marginBottom: 20, background: filtersApplied ? 'red' : 'gray' }} onClick={clearFilters}>
        Reset Filters
      </Button>

      <CSVButton
        filename={`paid-users${filters.country ? '-' + filters.country : ''}.csv`}
        data={createCsvData(
          CSV_HEADINGS.filter((h) => h.key),
          getUsersForCSV(),
        )}
      >
        Download CSV
      </CSVButton>

      {selectedUsers.length > 0 && (
        <>
          <Button
            style={{ marginBottom: 20, marginLeft: 20, background: 'red' }}
            onClick={() => setIsRemoveMultipleUsersModalVisible(true)}
          >
            Remove
          </Button>
          <Button
            style={{ marginBottom: 20, marginLeft: 20, background: 'green' }}
            onClick={() => setSelectedUsers([])}
          >
            De-select All
          </Button>
        </>
      )}

      <Table
        headings={
          <tr>
            {HEADINGS.map((h) => (
              <TH key={h.text}>{h.text}</TH>
            ))}
            <TH />
            <TH />
          </tr>
        }
        filters={
          <tr style={{ alignItems: 'center' }}>
            <TextFilterCell value={filters.name} setFilter={(val) => setSingleFilter('name', val)} />
            <TextFilterCell value={filters.email} setFilter={(val) => setSingleFilter('email', val)} />
            <td />
            <DropDownFilterCell
              value={filters.source}
              options={usersWithExtraFields.map((u) => u.source).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('source', val)}
            />
            <DropDownFilterCell
              value={filters.utmSource}
              options={usersWithExtraFields.map((u) => u.utmSource).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmSource', val)}
            />
            <DropDownFilterCell
              value={filters.utmMedium}
              options={usersWithExtraFields.map((u) => u.utmMedium).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmMedium', val)}
            />
            <DropDownFilterCell
              value={filters.utmCampaign}
              options={usersWithExtraFields.map((u) => u.utmCampaign).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmCampaign', val)}
            />
            <DropDownFilterCell
              value={filters.utmTerm}
              options={usersWithExtraFields.map((u) => u.utmTerm).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmTerm', val)}
            />
            <DropDownFilterCell
              value={filters.utmContent}
              options={usersWithExtraFields.map((u) => u.utmContent).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmContent', val)}
            />
            <DropDownFilterCell
              value={filters.program}
              options={['Raising Funds For Business', 'Subscription', 'Accelerator', 'Foundation', 'Starter']}
              setFilter={(val) => setSingleFilter('program', val)}
            />
            <DropDownFilterCell
              value={filters.programStatus}
              options={['Active', 'Inactive']}
              setFilter={(val) => setSingleFilter('programStatus', val)}
            />
            <DateFilterCell
              from={filters.fromProgramExpiryDate}
              to={filters.toProgramExpiryDate}
              setFromDate={(date) => setSingleFilter('fromProgramExpiryDate', date)}
              setToDate={(date) => setSingleFilter('toProgramExpiryDate', date)}
            />
            <DateFilterCell
              from={filters.fromMentoringExpiryDate}
              to={filters.toMentoringExpiryDate}
              setFromDate={(date) => setSingleFilter('fromMentoringExpiryDate', date)}
              setToDate={(date) => setSingleFilter('toMentoringExpiryDate', date)}
            />
            <DropDownFilterCell
              value={filters.hasPassword}
              options={['Yes', 'No']}
              setFilter={(val) => setSingleFilter('hasPassword', val)}
            />
            <DateFilterCell
              from={filters.fromLiveSessionDate}
              to={filters.toLiveSessionDate}
              setFromDate={(date) => setSingleFilter('fromLiveSessionDate', date)}
              setToDate={(date) => setSingleFilter('toLiveSessionDate', date)}
            />
            <DateFilterCell
              from={filters.fromPaymentDate}
              to={filters.toPaymentDate}
              setFromDate={(date) => setSingleFilter('fromPaymentDate', date)}
              setToDate={(date) => setSingleFilter('toPaymentDate', date)}
            />
            <DropDownFilterCell
              value={filters.accessedSessions}
              options={['Yes', 'No']}
              setFilter={(val) => setSingleFilter('accessedSessions', val)}
            />
            <DropDownFilterCell
              value={filters.accessedWrittenContent}
              options={['Yes', 'No']}
              setFilter={(val) => setSingleFilter('accessedWrittenContent', val)}
            />
            <DropDownFilterCell
              value={filters.telesalesSource}
              options={usersWithExtraFields.map((u) => u.telesalesSource).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('telesalesSource', val)}
            />
            <DropDownFilterCell
              value={filters.promoCode}
              options={usersWithExtraFields.map((u) => u.promoCode).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('promoCode', val)}
            />
            <DropDownFilterCell
              value={filters.country}
              options={usersWithExtraFields.map((u) => u.country).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('country', val)}
            />
            <td />
            <td />
            <td />
            <td />
            <td />
          </tr>
        }
        items={sortedUsers}
        emptyMsg={emptyMsg}
      >
        {(user) => {
          return (
            <PaidUserRow
              onClickRemove={() => {
                setUserToRemove(user.id)
                setIsRemoveModalVisible(true)
              }}
              key={user.id}
              user={user}
              programStatus={isBefore(new Date(), user.availableContent?.mentoringExpiryDate)}
              onSave={updateUser}
              adviceTracking={adviceTracking}
              coreSessionTracking={coreSessionTracking}
              selectedUsers={selectedUsers}
              toggleCheckBox={toggleCheckBox}
            />
          )
        }}
      </Table>
      <ModalWithAction
        visible={isRemoveModalVisible}
        closeModal={() => setIsRemoveModalVisible(false)}
        onPressConfirm={() => userToRemove && archiveUser(userToRemove)}
        confirmButtonText={`Archive ${(users.find((u) => u.id === userToRemove) || {}).firstName}`}
      />
      <ModalWithAction
        visible={isRemoveMultipleUsersModalVisible}
        closeModal={() => setIsRemoveMultipleUsersModalVisible(false)}
        onPressConfirm={() => selectedUsers.length > 0 && archiveMultipleUsers(selectedUsers)}
        confirmButtonText={
          selectedUsers.length === 1
            ? `Archive ${(users.find((u) => u.id === selectedUsers[0]) || {}).firstName}`
            : `Archive Selected Users`
        }
      />
    </>
  )
}

const addExtraFields = (user) => {
  return {
    ...user,
    ...getProgram(user.availableContent),
  }
}

const empty = {}

const getProgram = ({
  acceleratorPayment,
  foundationPayment,
  starterPayment,
  subscription,
  raisingFundsForBusinessPayment,
} = empty) => {
  const result = { paymentDate: '', program: null, subscription, programExpiryDate: '' }

  let program

  if (acceleratorPayment) {
    program = 'Accelerator'
    result.paymentDate = acceleratorPayment.date
  } else if (foundationPayment) {
    program = 'Foundation'
    result.paymentDate = foundationPayment.date
  } else if (starterPayment) {
    program = 'Starter'
    result.paymentDate = starterPayment.date
  } else if (raisingFundsForBusinessPayment) {
    program = 'Raising Funds For Business'
    result.paymentDate = raisingFundsForBusinessPayment.date
  }

  if (subscription) {
    program = !program ? 'Subscription' : program + ' + Subscription'
    result.paymentDate = subscription.createDate
  }
  result.program = program

  const { paymentDate } = result

  result.programExpiryDate = !paymentDate ? '' : addMonths(paymentDate, 12)

  return result
}

PaidUsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  emptyMsg: PropTypes.string.isRequired,
}

export default PaidUsersTable
