import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, fonts, fontWeights } from '../../../theme'

/**
 * Buttons have a gradient background derived from the primary color by default.
 * This can be overridden with the `solid` or `link` props. When specifying the
 * `link` type the color styles are controlled by the theme.
 */
export const Button = styled.button`
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 0.3em 0.5em;
  font-family: ${fonts.primary};
  font-size: 25px;
  font-weight: ${fontWeights.normal};
  margin: 0;
  cursor: pointer;
  width: ${props => (props.width ? props.width : props.block ? '100%' : 'auto')};
  background: ${props =>
    props.background ||
    (props.link
      ? 'transparent'
      : props.solid
      ? colors.primary
      : `linear-gradient(to left, ${colors.primary}, ${colors.secondary})`)};
  color: ${props => (props.link && props.colorScheme === 'light' ? colors.black : colors.white)};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`

Button.propTypes = {
  colorScheme: PropTypes.oneOf(['light', 'dark']),
  /** A solid background rather than a gradient */
  solid: PropTypes.bool,
  /** Display the button as a link without a background */
  link: PropTypes.bool,
  /** Make the button span the full parent width */
  block: PropTypes.bool,
}

Button.defaultProps = {
  colorScheme: 'light',
  link: false,
}

/** @component */
export default Button
