import React, { useEffect } from 'react'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { useReferrals } from '../../../containers/AdminApi/ReferralsApi'
import { useAdviceTracking } from '../../../containers/AdminApi/TrackingDataApi'
import { useUserList } from '../../../containers/AdminApi/UsersListApi'
import { useContentAvailableQuery } from '../../../containers/AdminApi/useContentAvailableQuery'
import {
  useAcceleratorTrackingApi,
  useFoundationTrackingApi,
  useStarterTrackingApi,
} from '../../../containers/AdminApi/useTrackingApi'
import AdviceTrackingTable from './AdviceTrackingTable/AdviceTrackingTable'
import {
  StarterProgressDashboard,
  FoundationProgressDashboard,
  AcceleratorProgressDashboard,
} from './ProgressDashboard'
import { ReferralsTrackingTable } from './ReferralsTrackingTable/ReferralsTrackingTable'
import { UserDetails } from './UserDetails'

const TrackingPage = ({ match: { params } }) => {
  const { userId, firstName, lastName } = params

  const {
    data: starterProgressTrackingData,
    isLoading: starterProgressTrackingDataLoading,
  } = useStarterTrackingApi({
    userId,
  })
  const {
    data: foundationProgressTrackingData,
    isLoading: foundationProgressTrackingDataLoading,
  } = useFoundationTrackingApi({
    userId,
  })
  const {
    data: acceleratorProgressTrackingData,
    isLoading: acceleratorProgressTrackingDataLoading,
  } = useAcceleratorTrackingApi({
    userId,
  })

  const {
    data: { instalmentPlan = {} },
  } = useContentAvailableQuery(userId, { refetchOnMount: 'always' })

  const {
    referrals: { referrals, error: referralsError, loading: referralsLoading },
    getReferralsByUser,
  } = useReferrals()

  useEffect(() => {
    getReferralsByUser(userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  const { adviceViewingData, loading: adviceLoading, error: adviceErr } = useAdviceTracking()

  const {
    user: { user, loading: userDetailsLoading, error: userDetailsError },
  } = useUserList({ userId })

  const isStarterUser = !userDetailsLoading && !!user?.availableContent?.starterProgram
  const isFoundationUser = !userDetailsLoading && !!user?.availableContent?.foundationProgram
  const isAcceleratorUser = !userDetailsLoading && !!user?.availableContent?.acceleratorProgram

  return (
    <AdminPage heading={`Tracking Data: ${firstName} ${lastName}`}>
      {userDetailsLoading ? (
        <LoadingSection loadingMessage="User details loading..." />
      ) : (
        <ErrorSection error={userDetailsError}>
          <UserDetails user={user} />
        </ErrorSection>
      )}

      {referralsLoading ? (
        <LoadingSection loadingMessage="Referrals tracking loading..." />
      ) : (
        <ErrorSection error={referralsError}>
          <ReferralsTrackingTable
            referrals={referrals}
            heading={`Referrals Tracking (${
              referrals.filter((r) => r.converted).length
            } conversions)`}
          />
        </ErrorSection>
      )}

      {starterProgressTrackingDataLoading ||
      foundationProgressTrackingDataLoading ||
      acceleratorProgressTrackingDataLoading ? (
        <LoadingSection loadingMessage="Progress Dashboard loading..." />
      ) : (
        <>
          {isAcceleratorUser ? (
            <ErrorSection>
              <h1>{`${user.firstName}'s Progress Dashboard`}</h1>
              <AcceleratorProgressDashboard
                userId={userId}
                acceleratorProgressTrackingData={acceleratorProgressTrackingData}
                instalmentPlan={instalmentPlan}
              />
            </ErrorSection>
          ) : isFoundationUser ? (
            <ErrorSection>
              <h1>{`${user.firstName}'s Progress Dashboard`}</h1>
              <FoundationProgressDashboard
                userId={userId}
                foundationProgressTrackingData={foundationProgressTrackingData}
              />
            </ErrorSection>
          ) : isStarterUser ? (
            <ErrorSection>
              <h1>{`${user.firstName}'s Progress Dashboard`}</h1>
              <StarterProgressDashboard
                userId={userId}
                starterProgressTrackingData={starterProgressTrackingData}
              />
            </ErrorSection>
          ) : null}
        </>
      )}

      {adviceLoading ? (
        <LoadingSection loadingMessage="Advice tracking loading..." />
      ) : (
        <ErrorSection error={adviceErr}>
          <AdviceTrackingTable
            userId={userId}
            adviceViewingData={adviceViewingData}
            emptyMsg="No tracking data to show"
          />
        </ErrorSection>
      )}
    </AdminPage>
  )
}

export default TrackingPage
