import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useCurrentUser } from '../../../containers/UserContext/UserContext'

import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import ModalContent from '../../../components/layout/ModalContent/ModalContent'
import FormBorder from '../../../components/input/FormBorder/FormBorder'
import { PasswordResetForm } from '../../../components/auth/PasswordResetForm/PasswordResetForm'
import P from '../../../components/typography/P/P'

const PasswordResetPage = () => {
  const { requestPwdReset } = useCurrentUser()
  const [submitted, setSubmitted] = useState(false)

  return (
    <AdminPage>
      <ModalContent.Heading>Password Reset</ModalContent.Heading>

      {!submitted ? (
        <FormBorder maxWidth="440px">
          <PasswordResetForm
            onSubmit={async vals => {
              await requestPwdReset(vals)
              setSubmitted(true)
            }}
          />
        </FormBorder>
      ) : (
        <P style={{ textAlign: 'center' }}>Email sent</P>
      )}
      <P style={{ textAlign: 'center' }}>
        Back to <Link to="/">Sign In</Link>
      </P>
    </AdminPage>
  )
}

export default PasswordResetPage
