import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import FormBorder from '../../../../components/input/FormBorder/FormBorder'
import { withSuccessHandler } from '../../../../utils/forms/withHandlers'
import Input from '../../../../components/input/Input/Input'
import FormError from '../../../../components/input/FormError/FormError'
import Button from '../../../../components/input/Button/Button'
import { required } from '../../../../utils/forms/validation'
import H3Secondary from '../../../../components/typography/H4Secondary/H3Secondary'
import ArticleSection from '../ArticleSection'
import Dropzone from '../../../../components/input/Dropzone/Dropzone'

const categories = {
  hiring: 'Hiring',
  finance: 'Finance',
  mentoring: 'Mentoring',
  career: 'Career',
}

const CustomWidthField = styled(Field)`
  width: ${props => (props.width ? props.width : '600px')};
`

const Warning = styled.span`
  color: ${props => props.theme.colors.primary};
`

const SubmitButton = styled(Button)`
  margin: 15px 10px;
`

const AdviceArticleForm = ({ submitText, initialValues, saving, ...props }) => (
  <FormBorder maxWidth="700px">
    <Form
      {...props}
      initialValues={{ ...initialValues }}
      mutators={{ ...arrayMutators }}
      render={({ form, handleSubmit, submitting, submitError }) => {
        return (
          <form
            style={{ marginBottom: '0.5em' }}
            onSubmit={withSuccessHandler(handleSubmit, form.reset)}
          >
            <ArticleSection first noUnderLine>
              <H3Secondary>Title</H3Secondary>
              <CustomWidthField
                name="title"
                placeholder="Title"
                component={Input}
                validate={required}
                disabled={saving}
              />
            </ArticleSection>

            <ArticleSection noUnderLine>
              <H3Secondary>Description</H3Secondary>
              <CustomWidthField
                name="description"
                placeholder="Description..."
                component={Input}
                componentClass={Input.TextArea}
                validate={required}
                disabled={saving}
              />
            </ArticleSection>

            {initialValues && (
              <ArticleSection noUnderLine>
                <H3Secondary>Url Slug</H3Secondary>
                <CustomWidthField
                  name="slug"
                  placeholder="Url slug"
                  component={Input}
                  validate={required}
                  disabled={saving}
                />
              </ArticleSection>
            )}

            <ArticleSection noUnderLine>
              <H3Secondary>Video Embed Url</H3Secondary>
              <CustomWidthField
                name="videoUrl"
                placeholder="Embed Url"
                component={Input}
                validate={required}
                disabled={saving}
              />
            </ArticleSection>

            <ArticleSection noUnderLine>
              <H3Secondary>Video Duration (seconds)</H3Secondary>
              <CustomWidthField
                name="durationInSeconds"
                placeholder="Duration in seconds"
                component={Input}
                validate={required}
                disabled={saving}
              />
            </ArticleSection>

            <ArticleSection noUnderLine>
              <H3Secondary>Categories</H3Secondary>
              <FieldArray
                name="categories"
                component={Input}
                componentClass={Input.CheckboxGroup}
                initial={initialValues && initialValues.categories}
                options={categories}
              />
            </ArticleSection>
            <ArticleSection noUnderLine>
              <H3Secondary>
                Thumbnail <Warning>(Resolution 828 x 640, Maximum file size 300 KB)</Warning>
              </H3Secondary>
              <Field name="thumbnail">
                {props => (
                  <Dropzone
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    initialValue={initialValues && initialValues.thumbnail}
                    disabled={saving}
                  />
                )}
              </Field>
            </ArticleSection>

            <ArticleSection noUnderLine>
              <H3Secondary>
                Social Sharing Image <Warning>(Maximum file size 300 KB)</Warning>
              </H3Secondary>
              <Field name="ogImage">
                {props => (
                  <Dropzone
                    {...props.input}
                    initialValue={initialValues && initialValues.ogImage}
                    disabled={saving}
                  />
                )}
              </Field>
            </ArticleSection>

            <SubmitButton type="submit" disabled={saving}>
              {saving ? 'Saving...' : submitText}
            </SubmitButton>

            <FormError submitting={submitting} error={submitError} />
          </form>
        )
      }}
    />
  </FormBorder>
)

AdviceArticleForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
}

AdviceArticleForm.defaultProps = {
  submitText: 'Create Article',
}

export default AdviceArticleForm
