import React from 'react'
import styled from 'styled-components'
import Section from '../../layout/Section/Section'
import { colors, fonts } from '../../../theme'

const PaddedSection = styled(Section)`
  color: ${props => (props.error ? `${colors.error}` : `${colors.black}`)};
  text-align: center;
  padding: 2em;
  font-family: ${fonts.secondary};
  font-size: 18px;
`

const ErrorSection = ({ error, children }) => (
  <PaddedSection error={error} center>
    {error ? error.message : children}
  </PaddedSection>
)

export default ErrorSection
