import get from 'lodash/get'

const isEmpty = value => value === undefined || value === null || value === ''

export const composeValidators = (...validators) => (...args) =>
  validators.reduce((error, validator) => error || validator(...args), null)

export function required(value) {
  if (isEmpty(value)) {
    return 'Required field'
  }
}

export function minOneRequired(valueArr = []) {
  if (!valueArr.length) {
    return 'Please pick at least one value'
  }
}

export function email(value) {
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address'
  }
}

export function minLength(min) {
  return value => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`
    }
  }
}

export function maxLength(max) {
  return value => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`
    }
  }
}

export function greaterThan(min) {
  return value => {
    if (!isEmpty(value) && value <= min) {
      return `Must be greater than ${min}`
    }
  }
}

export function requiredIfSet(field, fieldName) {
  return (value, values) => {
    if (!value && get(values, field)) {
      return `Required if ${fieldName || field} is set`
    }
  }
}

export function equalTo(field, fieldName) {
  return (value, values) => {
    if (!isEmpty(value) && get(values, field) !== value) {
      return `Does not match ${fieldName || field}`
    }
  }
}

export const requiredGreaterThanZero = composeValidators(required, greaterThan(0))
export const minLength2Required = composeValidators(required, minLength(2))
export const maxLength100Required = composeValidators(required, maxLength(100))
export const requiredEmail = composeValidators(required, email)
export const password = composeValidators(required, minLength(6))
