const FOUNDATION_TRAINING = 'Foundation Training Module'
const EBOOK = 'E-book Library'
const OTHER_BENEFITS = 'Other Benefits'

export const contentLinks = {
  identifyingEntrepreneurshipOpportunityPage: `/course/identifying-entrepreneurship-opportunity`,
  establishingTheStartupPage: `/course/establish-your-start-up`,
  optimizeYourBrandPage: `/course/effective-customer-acquisition`,
  buildAGreatProductPage: `/course/building-a-great-product`,
  businessPlanningPage: `/course/raising-smart-finance`,
  deliverSmartTeamPlanningPage: `/course/effective-hiring`,
  keyMetricTrackingPage: `/course/key-metric-tracking`,
  strategicThinkingToSuccessPage: `/course/strategic-thinking-to-success`,
  sellingYourBusinessPage: `/course/selling-your-business`,
  bookOfSecretsPage: `/pdf/book-of-secrets`,
  raisingFundsPage: `/pdf/raising-money`,
  expertsAdvicePage: `/pdf/expert-advice`,
}

export const investorTitle = "Getting into the Investor's Mind"
export const customerSupportTitle = 'Get in touch with customer support'
export const writtenContentText = 'Read Written Content'
export const successChecklistText = 'Read Success Checklist'

export const pdfNames = {
  materials: 'materials',
  checklist: 'checklist',
}

export const foundationContent = {
  FOUNDATION_TRAINING_MODULE: {
    title: FOUNDATION_TRAINING,
    subtitle: 'Take advantage of our first foundation training module ',
    contentOne: {
      identifyingEntrepreneurshipOpportunity:
        'How to Identify Opportunities in Entrepreneurship - Video',
      writtenContent: writtenContentText,
      successChecklist: successChecklistText,
    },
    contentTwo: {
      establishingTheStartup: 'Establishing the Start-up - Video',
      writtenContentForEstablishingTheStartup: writtenContentText,
      successChecklistForEstablishingTheStartup: successChecklistText,
    },
    contentThree: {
      optimizeYourBrand:
        'Optimize your Brand & Marketing for Effective Customer Acquisition - Video',
      writtenContentForOptimizeYourBrand: writtenContentText,
      successChecklistForOptimizeYourBrand: successChecklistText,
    },
    contentFour: {
      buildAGreatProduct: 'Building a Great Product/Service - The Essentials for Success - Video',
      writtenContentForBuildAGreatProduct: writtenContentText,
      successChecklistForBuildAGreatProduct: successChecklistText,
    },
    contentFive: {
      businessPlanning: 'Business Planning, Funding Options & Raising Smart Finance - Video',
      writtenContentForBusinessPlanning: writtenContentText,
      successChecklistForBusinessPlanning: successChecklistText,
    },
    contentSix: {
      deliverSmartTeamPlanning:
        "Deliver Smart Team Planning Become an Effective Hiring Guru, and Evaluate 'when to hire' - Video",
      writtenContentForDeliverSmartTeamPlanning: writtenContentText,
      successChecklistForDeliverSmartTeamPlanning: successChecklistText,
    },
    contentSeven: {
      keyMetricTracking:
        'Implementing and Measuring Effective Financial & Operational Metrics for Decision Making - Video',
      writtenContentForKeyMetricTracking: writtenContentText,
      successChecklistForKeyMetricTracking: successChecklistText,
    },
    contentEight: {
      strategicThinkingToSuccess:
        'Thinking Strategically, Delivering Pragmatically - Hidden Wisdom Critical to Success - Video',
      writtenContentForStrategicThinkingToSuccess: writtenContentText,
      successChecklistForStrategicThinkingToSuccess: successChecklistText,
    },
    contentNine: {
      sellingYourBusiness:
        'The Exit - Selling Your Business and Other Outcome Considerations - Video',
      writtenContentForSellingYourBusiness: writtenContentText,
      successChecklistForSellingYourBusiness: successChecklistText,
    },
  },
  EBOOK_LIBRARY: {
    title: EBOOK,
    subtitle: 'Access Years Of Business Knowledge And Success',
    subContent: {
      bookOfSecrets: 'Book of Secrets',
      raisingFunds: 'Raising Funds for Your Business',
      expertsAdvice: "Expert's Advice",
    },
  },
  OTHER_BENEFITS_SECTION: {
    title: OTHER_BENEFITS,
    subtitle: 'Loyalty program, Cash reward, help & more',
    subContent: {
      martinsBlog: 'Don’t miss Martin’s popular blog, where he publishes key insights twice a week',
      referrals:
        'Find out about our referral program that provides you with an excellent cashback reward',
      testimonials: 'Find out how you can write testimonials in the portal',
      customerSupport: customerSupportTitle,
    },
  },
}

const { FOUNDATION_TRAINING_MODULE, EBOOK_LIBRARY } = foundationContent

const {
  contentOne: { identifyingEntrepreneurshipOpportunity, writtenContent, successChecklist },
  contentTwo: { establishingTheStartup },
  contentThree: { optimizeYourBrand },
  contentFour: { buildAGreatProduct },
  contentFive: { businessPlanning },
  contentSix: { deliverSmartTeamPlanning },
  contentSeven: { keyMetricTracking },
  contentEight: { strategicThinkingToSuccess },
  contentNine: { sellingYourBusiness },
} = FOUNDATION_TRAINING_MODULE

export const foundationContentTitles = {
  identifyingEntrepreneurshipOpportunity,
  establishingTheStartup,
  optimizeYourBrand,
  buildAGreatProduct,
  businessPlanning,
  deliverSmartTeamPlanning,
  keyMetricTracking,
  strategicThinkingToSuccess,
  sellingYourBusiness,
}
const {
  subContent: { bookOfSecrets, raisingFunds, expertsAdvice },
} = EBOOK_LIBRARY

/*
 * The number values in the accessed, partiallyComplete and complete properties in the object below
 * corresponds to actual percentage values that are assigned to the section (parent property).
 * However, for the other object properties with number values (i.e. the ones that are not named 'accessed', 'partiallyComplete' or 'complete'),
 * the actual percentage assigned to the section (parent property e.g [MENTORSHIP])
 * will be an addition of their values (i.e. accessed + partiallyComplete + complete).
 */

export const progressCalculationMetric = {
  [FOUNDATION_TRAINING]: {
    [identifyingEntrepreneurshipOpportunity]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [establishingTheStartup]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [optimizeYourBrand]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [buildAGreatProduct]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [businessPlanning]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [deliverSmartTeamPlanning]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [keyMetricTracking]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [strategicThinkingToSuccess]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 7,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
    [sellingYourBusiness]: {
      accessed: 2,
      partiallyComplete: 4,
      complete: 8,
      [writtenContent]: 2,
      [successChecklist]: 2,
    },
  },
  [EBOOK]: {
    [bookOfSecrets]: 35,
    [raisingFunds]: 35,
    [expertsAdvice]: 30,
  },
}

export const videoProgressStatus = {
  ACCESSED: 'Accessed',
  PARTIALLY_COMPLETED: 'Partially Completed',
  COMPLETED: 'Completed',
}

// Represents the percentage of video content watched.
export const videoProgressIndex = {
  ACCESSED_VALUE: 0,
  PARTIALLY_COMPLETED_VALUE: 25,
  COMPLETED_VALUE: 75,
}

export const coreSessionVideoIDs = [1, 2, 3, 4, 5, 6, 7, 8, 9]
