import React, { useMemo } from 'react'
import { Field, Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'
import arrayMutators from 'final-form-arrays'
import { media } from 'styled-bootstrap-grid'
import Button from '../../../components/input/Button/Button'
import FormError from '../../../components/input/FormError/FormError'
import FormInlineInput from '../../../components/input/FormInline/FormInlineInput'
import Input from '../../../components/input/Input/Input'
import { P } from '../../../components/typography'
import { required, minOneRequired } from '../../../utils/forms/validation'

const FormColorAndPadding = styled.div`
  background: ${(props) => props.theme.colors.grayLight};
  padding: 10px;
  border-radius: 10px;
  width: 100%;
`

const ResponsiveTargetsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${media.md`
      flex-direction: row;
  `}
`

const ButtonWithMargin = styled(Button)`
  margin-bottom: 5px;
  margin-right: 10px;
`

const SaveDraftButton = styled(ButtonWithMargin)`
  background: ${(props) => props.theme.colors.info};
`

const CloseButton = styled(ButtonWithMargin)`
  background: ${(props) => props.theme.colors.gray};
`

const Greeting = styled(P)`
  text-align: left;
  font-weight: bold;
`

export const MessageForm = ({
  messageId,
  messageBody,
  saveMessage,
  onComplete,
  closeForm,
  published,
  greetingText,
  signatureText,
  disableNoProgram,
  disableMentoring,
  ...props
}) => {
  const initialTextInputValue = useMemo(
    () => ({ plainText: null, stringifiedState: messageBody }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <FormColorAndPadding>
      <Form
        {...props}
        onSubmit={({ targets, body, subject, mentoringStatusFilter }) => {
          let mentoringFilterStr

          if (mentoringStatusFilter) {
            const active = mentoringStatusFilter.includes('active')
            const inactive = mentoringStatusFilter.includes('inactive')
            if (active && inactive) mentoringFilterStr = 'all'
            else if (active) mentoringFilterStr = 'active'
            else if (inactive) mentoringFilterStr = 'inactive'
          }

          return saveMessage({
            id: messageId,
            subject,
            body: body.stringifiedState || body,
            targetsNoProgram: targets.includes('noProgram'),
            targetsStarter: targets.includes('starter'),
            targetsFoundation: targets.includes('foundation'),
            targetsAccelerator: targets.includes('accelerator'),
            targetsSubscription: targets.includes('subscription'),
            mentoringStatusFilter: mentoringFilterStr,
          }).then(onComplete)
        }}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <ResponsiveTargetsWrapper>
              <FieldArray
                label="Target Programs"
                name="targets"
                style={{ width: 250, paddingRight: 30 }}
                textStyle={{ width: 200 }}
                component={Input}
                componentClass={Input.CheckboxGroup}
                initial={props.initialValues.targets}
                options={{
                  ...(disableNoProgram
                    ? {}
                    : { noProgram: 'No Program (ignores mentoring filter)' }),
                  starter: 'Starter',
                  foundation: 'Foundation',
                  accelerator: 'Accelerator',
                  subscription: 'Success',
                }}
                validate={minOneRequired}
              />
              {props.initialValues.mentoringStatusFilter && !disableMentoring && (
                <FieldArray
                  label="Mentoring Status"
                  style={{ width: 250 }}
                  name="mentoringStatusFilter"
                  component={Input}
                  componentClass={Input.CheckboxGroup}
                  initial={props.initialValues.mentoringStatusFilter}
                  options={{
                    active: 'Active',
                    inactive: 'Inactive',
                  }}
                  validate={minOneRequired}
                />
              )}
            </ResponsiveTargetsWrapper>
            <Field
              name="subject"
              placeholder="Type subject"
              component={FormInlineInput}
              validate={required}
            />
            {greetingText && <Greeting>{greetingText} (automatically included)</Greeting>}
            <Field
              name="body"
              component={Input}
              componentClass={Input.FormattedInput}
              placeholder="Type message here"
              validate={required}
              initialValue={initialTextInputValue}
            />
            {signatureText && <Greeting>{signatureText} (automatically included)</Greeting>}
            <FormError submitting={submitting} error={submitError} />
            <SaveDraftButton
              solid
              type="submit"
              disabled={submitting}
              style={{ marginBottom: '5px' }}
            >
              {submitting ? 'Saving...' : published ? 'Save' : 'Save Draft'}
            </SaveDraftButton>
            {closeForm && (
              <CloseButton solid type="button" onClick={closeForm}>
                Close
              </CloseButton>
            )}
          </form>
        )}
      />
    </FormColorAndPadding>
  )
}
