import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import { colors, fonts, fontWeights, mediaMinWidth } from './index'

const NormalizeGlobalStyle = createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
  }

  body {
    background: #fff;
  
    font-size: 18px;
    font-family: ${fonts.primary};
    font-weight: ${fontWeights.normal};
    
    ${mediaMinWidth.sm`
      font-size: 24px;
    `}
    
    p {
      margin: 0;
    }
    
    a {
      color: ${colors.primary};
      text-decoration: none;
    }
    
    button:hover, a:hover {
      cursor: pointer;
    }
    
    a:focus, a:active,
    button:focus, button:active {
      outline: none;
    }
  }
`

export default NormalizeGlobalStyle
