import React, { useState } from 'react'
import styled from 'styled-components'
import EditIcon from '../../../components/icons/EditIcon'
import { Button } from '../../../components/input/Button/Button'
import { H3, P } from '../../../components/typography'
import { UpdateQuestionForm } from './UpdateQuestionForm'

const FeedbackItem = styled.div`
  padding: 15px;
  border-bottom: 2px solid ${(props) => props.theme.colors.gray};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const QuestionText = styled(H3)`
  font-size: 1.1em;
  font-weight: bold;
  text-align: left;
  margin-right: 10px;
`

const Published = styled(P)`
  color: ${(props) => (props.published ? props.theme.colors.green : props.theme.colors.error)};
  margin-bottom: 30px;
`

const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
`

const Answer = styled.li`
  text-align: left;
  margin-bottom: 10px;
`

const PublishButton = styled(Button)`
  background: ${(props) => (props.published ? props.theme.colors.error : props.theme.colors.green)};
`

const Row = styled.div`
  display: flex;
  width: 100%;
`

export const QuestionsList = ({
  questions,
  publishQuestion,
  unpublishQuestion,
  updateQuestion,
}) => {
  const [editingId, setEditingId] = useState(-1)

  return questions.map((q) => (
    <FeedbackItem key={q.id}>
      <Row>
        {editingId === q.id ? (
          <UpdateQuestionForm
            initialValue={q.body}
            onSubmit={async (vals) => {
              await updateQuestion(q.id, vals.questionBody)
              setEditingId(-1)
            }}
          />
        ) : (
          <>
            <QuestionText>{q.body}</QuestionText>
            <EditIcon title="edit" onClick={() => setEditingId(q.id)} />
          </>
        )}
      </Row>
      <Published published={q.published}>{q.published ? 'PUBLISHED' : 'NOT PUBLISHED'}</Published>
      <AnswersWrapper>
        {q.answers.map((a) => (
          <Answer key={a.id}>{a.body}</Answer>
        ))}
      </AnswersWrapper>
      {q.published ? (
        <PublishButton published onClick={() => unpublishQuestion(q.id)}>
          Unpublish
        </PublishButton>
      ) : (
        <PublishButton onClick={() => publishQuestion(q.id)}>Publish</PublishButton>
      )}
    </FeedbackItem>
  ))
}
