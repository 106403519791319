import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import H3 from '../../../../components/typography/H3/H3'
import P from '../../../../components/typography/P/P'
import Button from '../../../../components/input/Button/Button'
import { ModAnswerForm } from './ModAnswerForm'
import { ModalWithAction } from '../../../../components/input/ModalWithAction/ModalWithAction'
import { FullScreenWithClose } from '../../../../components/layout/FullScreenOverlay/FullScreenOverlay'
import { CopyButton } from '../components/CopyButton'
import { useCopyFromList } from '../useCopyFromList'

const StyledCopyButton = styled(CopyButton)`
  padding: 5px 10px;
  margin-right: 10px;
`

const SavedAnswers = styled.div`
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SavedAnswerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid gray;
  width: 100%;
  padding: 10px 0;
`

const SavedAnswerTitle = styled.div`
  padding: 0 15px 0 0;
`
const SavedAnswerButtons = styled.div`
  display: flex;
  align-items: center;
`

const ModAnswerButton = styled(Button)`
  padding: 5px 10px;
  margin-right: 10px;
`

const EditAnswerButton = styled(ModAnswerButton)`
  background: ${props => props.theme.colors.gray};
`

const ClickableText = styled.div`
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 10px;
`

export const ModeratorAnswers = ({
  closeModAnswers,
  savedAnswers = [],
  addAnswer,
  updateAnswer,
  removeAnswer,
}) => {
  const { idsWithCopiedText, toggleCopied } = useCopyFromList(
    savedAnswers.map(({ id, body }) => ({ id, text: body })),
  )

  return (
    <FullScreenWithClose close={closeModAnswers}>
      <H3>Moderator Answers</H3>

      <SavedAnswers>
        {!savedAnswers.length ? (
          <P>
            <b>No saved answers</b>
          </P>
        ) : (
          savedAnswers.map(({ title, id, body }, i) => (
            <SavedAnswer
              key={`${title}_${i}`}
              title={title}
              body={body}
              isCopied={idsWithCopiedText.includes(id)}
              copyAnswer={() => toggleCopied(id)}
              removeAnswer={() => removeAnswer(id)}
              updateAnswer={vals => updateAnswer(id, vals)}
            />
          ))
        )}
      </SavedAnswers>

      <H3 style={{ marginBottom: 20 }}>Add New Answer</H3>
      <ModAnswerForm onSubmit={titleAndBody => addAnswer(titleAndBody)} />
    </FullScreenWithClose>
  )
}

const SavedAnswer = ({ title, body, removeAnswer, copyAnswer, updateAnswer, isCopied }) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [editing, setEditing] = useState(false)

  return (
    <>
      <SavedAnswerRow>
        <SavedAnswerTitle>{title}</SavedAnswerTitle>
        <SavedAnswerButtons>
          <StyledCopyButton isCopied={isCopied} onClick={copyAnswer}>
            Copy
          </StyledCopyButton>
          <EditAnswerButton onClick={() => setEditing(true)}>Edit</EditAnswerButton>
          <ClickableText onClick={() => setConfirmModalVisible(true)}>X</ClickableText>
        </SavedAnswerButtons>
      </SavedAnswerRow>
      {confirmModalVisible && (
        <ModalWithAction
          closeModal={() => setConfirmModalVisible(false)}
          confirmButtonText="Delete"
          onPressConfirm={removeAnswer}
          confirmButtonColor="primary"
        />
      )}
      {editing && (
        <FullScreenWithClose close={() => setEditing(false)}>
          <H3>Update Answer</H3>
          <ModAnswerForm
            title={title}
            body={body}
            onSubmit={async titleAndBody => {
              await updateAnswer(titleAndBody)
              setEditing(false)
            }}
          />
        </FullScreenWithClose>
      )}
    </>
  )
}

ModeratorAnswers.propTypes = {
  addAnswer: PropTypes.func.isRequired,
  closeModAnswers: PropTypes.func.isRequired,
  removeAnswer: PropTypes.func.isRequired,
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  ),
}
