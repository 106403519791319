import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, themes, mediaMinWidth } from '../../../theme'

/**
 * A section heading which will render the `h2` HTML tag. This component can be used
 * multiple times per page where there are multiple sections with prominent headings.
 * Color styles controlled by the theme.
 */
const H2 = styled.h2`
  color: ${props =>
    props.color ? colors[props.color] : themes[props.colorScheme || 'light'].color};
  margin: 0 0 0.6em;
  line-height: 1;
  font-size: 38px;
  em {
    color: ${colors.primary};
  }

  ${mediaMinWidth.md`
    font-size: 48px;
  `};
`

H2.propTypes = {
  colorScheme: PropTypes.oneOf(['light', 'gray', 'dark', 'primary']),
  color: PropTypes.string,
}

H2.defaultProps = {
  colorScheme: 'light',
}

/** @component */
export default H2
