import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm, Field } from 'react-final-form'
import styled from 'styled-components'
import Button from '../../../../components/input/Button/Button'
import FormError from '../../../../components/input/FormError/FormError'
import FormInlineInput from '../../../../components/input/FormInline/FormInlineInput'

const RenameTagButton = styled(Button)`
  background: ${props => props.theme.colors.green};
  padding: 10px;
  margin: 0;
  font-size: 17px;
  height: fit-content;
`

const StyledInput = styled(FormInlineInput)`
  flex: 1;
  margin: 0;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
`

export const TagRenameForm = props => {
  return (
    <FinalForm
      {...props}
      render={({ handleSubmit, submitting, submitError }) => (
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', flex: 1, margin: '0px', padding: '0px' }}
        >
          <Field
            autoFocus
            name="tagName"
            component={StyledInput}
            placeholder="Type new tag name..."
            defaultValue={props.initialValue}
          />
          <FormError submitting={submitting} error={submitError} />
          <RenameTagButton solid type="submit" disabled={submitting}>
            {submitting ? 'Saving...' : 'Rename Tag'}
          </RenameTagButton>
        </form>
      )}
    />
  )
}

TagRenameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
