import { useState, useEffect } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const useNewsletterList = () => {
  const [newsletterList, setNewsletterList] = useState({ signUps: [], loading: true })

  useEffect(() => {
    esAdminAPI('get', '/newsletter/sign-ups')
      .then(signUps => setNewsletterList({ signUps, loading: false }))
      .catch(error => setNewsletterList(prev => ({ ...prev, error, loading: false })))
  }, [])

  return { newsletterList }
}
