import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm, Field } from 'react-final-form'
import styled from 'styled-components'

import Button from '../../../../components/input/Button/Button'
import FormError from '../../../../components/input/FormError/FormError'
import P from '../../../../components/typography/P/P'
import FormInlineInput from '../../../../components/input/FormInline/FormInlineInput'
import EditIcon from '../../../../components/icons/EditIcon'
import { InfoText } from './InfoText'

const UpdateTagsButton = styled(Button)`
  background: ${props => props.theme.colors.green};
  padding: 10px;
  margin: 0;
  font-size: 17px;
  height: fit-content;
`

const ReadOnlyView = styled(P)`
  margin: 0;
  margin-right: 20px;
  font-size: 19px;
`

const StyledInput = styled(FormInlineInput)`
  flex: 1;
  margin: 0;
  margin-right: 10px;
  border-radius: 5px;
  overflow: hidden;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.marginBottom || '4px'};
  /* min-height: 45px; */
`

const TagsLabel = styled(InfoText)`
  margin: 0;
  margin-right: 10px;
`

export const TagsFormAndPreview = ({ marginBottom, setTags, ...props }) => {
  const [editing, setEditing] = useState(false)

  const { initialTagsStr } = props

  return (
    <Row marginBottom={marginBottom}>
      {!editing ? (
        <>
          <TagsLabel small>
            <b>Tags:</b>
          </TagsLabel>
          <ReadOnlyView>{initialTagsStr || '--'}</ReadOnlyView>
        </>
      ) : (
        <FinalForm
          {...props}
          onSubmit={async vals => {
            const { tags: updatedTags = '' } = vals
            await setTags(updatedTags.split(',').map(t => t.trim()))
            setEditing(false)
          }}
          render={({ handleSubmit, submitting, submitError }) => (
            <form
              onSubmit={handleSubmit}
              style={{ display: 'flex', flex: 1, margin: '0px', padding: '0px' }}
            >
              <Field
                autoFocus
                name="tags"
                component={StyledInput}
                placeholder="e.g. marketing, customer aquisition, finance"
                defaultValue={initialTagsStr}
              />
              <FormError submitting={submitting} error={submitError} />
              <UpdateTagsButton solid type="submit" disabled={submitting}>
                {submitting ? 'Saving...' : 'Update Tags'}
              </UpdateTagsButton>
            </form>
          )}
        />
      )}
      {!editing && <EditIcon title="Edit Tags" onClick={() => setEditing(true)} />}
    </Row>
  )
}

TagsFormAndPreview.propTypes = {
  setTags: PropTypes.func.isRequired,
}
