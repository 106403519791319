import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../../components/input/Button/Button'
import { SectionHeading } from './components/SectionHeading'
import { MessageForm } from './MessageForm'

const Container = styled.div`
  margin-bottom: 30px;
`

const DisplayFormButton = styled(Button)`
  background: ${props => props.theme.colors.info};
`

export const WriteNewMessage = ({ typeMsg = 'Blog Post', ...props }) => {
  const [formVisible, setFormVisible] = useState(false)

  return (
    <Container>
      {!formVisible ? (
        <DisplayFormButton onClick={() => setFormVisible(true)}>
          Create New {typeMsg}
        </DisplayFormButton>
      ) : (
        <>
          <SectionHeading>Create New {typeMsg}</SectionHeading>
          <MessageForm
            {...props}
            saveMessage={props.saveMessage}
            onComplete={() => setFormVisible(false)}
          />
        </>
      )}
    </Container>
  )
}

WriteNewMessage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
