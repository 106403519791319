import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { colors, contentWidth, fonts } from '../../../theme'

const Page = styled.div`
  max-width: ${(props) => (props.fullWidth ? 'auto' : contentWidth)};
  font-family: ${fonts.secondary};
  padding: 1em ${(props) => (props.fullWidth ? '1.5em' : '0')};
  margin: 0 auto;
`

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: 12px;
`

const Heading = styled.h1`
  font-family: ${fonts.primary};
  color: ${colors.black};
  font-size: 36px;
  line-height: 1;
  margin: 0 0 0.6em;
`

const Actions = styled.div`
  text-align: right;
`

const Action = styled.a`
  color: ${colors.black};
  font-size: 24px;
  line-height: 1.5;
  margin: 0 0 0.6em;
`

const Head = ({ actions = [], children }) => (
  <Header>
    <Heading>{children}</Heading>
    <Actions style={{ textAlign: 'right' }}>
      {actions.map(({ title, action }) => (
        <Action key={title} onClick={action}>
          {title}
        </Action>
      ))}
    </Actions>
  </Header>
)

const AdminPage = ({ fullWidth, heading, actions = [], children, loading, error }) => (
  <Page fullWidth={fullWidth}>
    {Boolean(!!heading || !!actions.length) && <Head actions={actions}>{heading}</Head>}
    {loading ? <LoadingSection /> : error ? <ErrorSection error={error} /> : children}
  </Page>
)

AdminPage.Heading = Head

AdminPage.propTypes = {
  fullWidth: PropTypes.bool,
  heading: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ),
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
}

export default AdminPage
