import { useState, useCallback } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const useReferrals = () => {
  const [referrals, setReferrals] = useState({ referrals: [], loading: true, error: undefined })

  const setAllReferrals = referrals =>
    setReferrals(prev => ({ ...prev, referrals, loading: false }))

  const handleError = error => setReferrals(prev => ({ ...prev, error, loading: false }))

  const getReferralsByUser = useCallback(
    id =>
      esAdminAPI('get', `/referrals/user/${id}`)
        .then(setAllReferrals)
        .catch(handleError),
    [],
  )

  return { referrals, getReferralsByUser }
}
