import React, { useState } from 'react'
import styled from 'styled-components'
import TH from './TH'

const DropdownContainer = styled.div`
  position: relative;
  width: 220px;
`

const DropdownButton = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
`

const DropdownContent = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 1;
`

const DropdownOption = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`

const TruncateText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const acceptedOptions = [0, true, false]

export const DropDownFilter = ({ options = [], setFilter, value }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdownHandler = (filterValue) => {
    setFilter(filterValue)
    setDropdownOpen((prev) => !prev)
  }

  return (
    <DropdownContainer>
      <DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)}>
        <TruncateText>{value || 'Any'}</TruncateText>
      </DropdownButton>
      {dropdownOpen && (
        <DropdownContent>
          <DropdownOption onClick={() => dropdownHandler(null)}>Any</DropdownOption>
          {options
            .filter((o) => Boolean(o) || acceptedOptions.includes(o))
            .sort() // for text
            .sort((a, b) => a - b) // for numbers
            .map((option, index) => (
              <DropdownOption key={index} onClick={() => dropdownHandler(option)}>
                <TruncateText>{option}</TruncateText>
              </DropdownOption>
            ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  )
}

export const DropDownFilterCell = (props) => (
  <TH left>
    <DropDownFilter {...props} />
  </TH>
)
