import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { colors } from '../../../theme'
import P from '../../typography/P/P'

const DropzoneContainer = styled.div`
  display: flex;
  align-items: center;
`

const Placeholder = styled(P)`
  margin-top: 35%;
`

const FileDropzone = styled.div`
  border: 2px dashed ${props => (props.error ? colors.error : colors.grayLight)};
  font-size: 20px;
  padding: 0.6em;
  cursor: pointer;
  width: 200px;
  height: 200px;
  text-align: center;
`

const Preview = styled.div`
  margin: 10px;
  height: 200px;
`

const PreviewThumbnail = styled.img`
  height: 200px;
`

const Dropzone = ({ onChange, initialValue }) => {
  const [files, setFiles] = useState([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
      onChange(acceptedFiles)
    },
    accept: 'image/*',
    multiple: false,
  })

  return (
    <DropzoneContainer>
      <FileDropzone {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Placeholder>Drop file here ...</Placeholder>
        ) : (
          <Placeholder>Drop file here, or click to select</Placeholder>
        )}
      </FileDropzone>
      <Preview>
        {files.length > 0
          ? files.map(file => <PreviewThumbnail key={file.name} src={file.preview} alt="preview" />)
          : initialValue && (
              <PreviewThumbnail src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${initialValue}`} />
            )}
      </Preview>
    </DropzoneContainer>
  )
}

export default Dropzone
