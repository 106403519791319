import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const useSessionTracking = () => {
  const [coreSessionTracking, setCoreSessionTracking] = useState({ loading: true })
  const [bonusSessionTracking, setBonusSessionTracking] = useState({ loading: true })
  const [userCoreSessionTracking, setUserCoreSessionTracking] = useState({ loading: true })
  const [userBonusSessionTracking, setUserBonusSessionTracking] = useState({ loading: true })
  const [userTeachingSessionTracking, setUserTeachingSessionTracking] = useState({ loading: true })

  const coreTrackingByUserId = (userId) =>
    esAdminAPI('get', `/track/core-sessions/user/${userId}`)
      .then((trackingData) =>
        setUserCoreSessionTracking((prev) => ({ ...prev, trackingData, loading: false })),
      )
      .catch((error) => setUserCoreSessionTracking((prev) => ({ ...prev, error, loading: false })))

  const bonusTrackingByUserId = (userId) =>
    esAdminAPI('get', `/track/bonus-sessions/user/${userId}`)
      .then((trackingData) =>
        setUserBonusSessionTracking((prev) => ({ ...prev, trackingData, loading: false })),
      )
      .catch((error) => setUserBonusSessionTracking((prev) => ({ ...prev, error, loading: false })))

  const teachingTrackingByUserId = (userId) =>
    esAdminAPI('get', `/track/teaching-sessions/user/${userId}`)
      .then((trackingData) =>
        setUserTeachingSessionTracking((prev) => ({ ...prev, trackingData, loading: false })),
      )
      .catch((error) =>
        setUserTeachingSessionTracking((prev) => ({ ...prev, error, loading: false })),
      )

  const bySessionId = (sessionId) =>
    esAdminAPI('get', `/track/session/${sessionId}`)
      .then(({ sessionViewingData }) =>
        setCoreSessionTracking((prev) => ({ ...prev, sessionViewingData, loading: false })),
      )
      .catch((error) => setCoreSessionTracking((prev) => ({ ...prev, error, loading: false })))

  const allCoreAndBonus = () => {
    esAdminAPI('get', '/track/core-sessions/all')
      .then((coreSessionViewingData) =>
        setCoreSessionTracking((prev) => ({ ...prev, coreSessionViewingData, loading: false })),
      )
      .catch((error) => setCoreSessionTracking((prev) => ({ ...prev, error, loading: false })))
    esAdminAPI('get', '/track/bonus-sessions/all')
      .then((bonusSessionViewingData) =>
        setBonusSessionTracking((prev) => ({ ...prev, bonusSessionViewingData, loading: false })),
      )
      .catch((error) => setBonusSessionTracking((prev) => ({ ...prev, error, loading: false })))
  }

  const sessionTrackingHandlers = {
    allCoreAndBonus,
    bonusTrackingByUserId,
    teachingTrackingByUserId,
    bySessionId,
    coreTrackingByUserId,
  }

  return {
    bonusSessionTracking,
    coreSessionTracking,
    sessionTrackingHandlers,
    userBonusSessionTracking,
    userCoreSessionTracking,
    userTeachingSessionTracking,
  }
}

export const useAdviceTracking = ({ userId, sessionId } = { userId: 0, sessionId: 0 }) => {
  const [adviceTracking, setAdviceTracking] = useState({ loading: true })

  useEffect(() => {
    if (userId && sessionId) {
      esAdminAPI('get', `/track/advice/${sessionId}/${userId}`)
        .then((individualAdviceData) =>
          setAdviceTracking((prev) => ({ ...prev, individualAdviceData, loading: false })),
        )
        .catch((error) => setAdviceTracking((prev) => ({ ...prev, error, loading: false })))
    } else {
      esAdminAPI('get', '/track/advice/all')
        .then((adviceViewingData) => setAdviceTracking({ adviceViewingData, loading: false }))
        .catch((error) => setAdviceTracking((prev) => ({ ...prev, error, loading: false })))
    }
  }, [userId, sessionId])

  return adviceTracking
}

export const useContentTracking = (userId) => {
  const [trackingData, setTrackingData] = useState({ loading: true, contentTrackingData: [] })

  useEffect(() => {
    if (!userId) return

    esAdminAPI('get', `/track/content/${userId}`)
      .then((contentTrackingData) => {
        return setTrackingData((prev) => ({ ...prev, contentTrackingData, loading: false }))
      })
      .catch((error) => setTrackingData((prev) => ({ ...prev, error, loading: false })))
  }, [userId])

  return trackingData
}

export const usePdfTracking = (userId) => {
  const [pdfTracking, setPdfTracking] = useState({ pdfTrackingData: [], loading: true })

  useEffect(() => {
    esAdminAPI('get', `/track/pdf-first-accesses/${userId}`)
      .then((pdfTrackingData) => setPdfTracking({ pdfTrackingData, loading: false }))
      .catch((error) => setPdfTracking((prev) => ({ ...prev, error, loading: false })))
  }, [userId])

  return pdfTracking
}

class TrackingDataApi extends React.Component {
  state = {
    adviceViewingData: {},
    allUserWatchData: [],
    avgTimesWatched: 0,
    avgViewPercentage: 0,
    individualAdviceData: {},
    individualWatchData: {},
    sessionViewingData: {},
    timesWatched: 0,
    viewers: 0,
    viewPercentages: [],
    watches: undefined,
  }

  componentDidMount() {
    const { userId, sessionId, advice } = this.props
    if (advice) {
      if (userId) {
        esAdminAPI('get', `/track/advice/${sessionId}/${userId}`).then((individualAdviceData) =>
          this.setState({ individualAdviceData }),
        )
      } else {
        esAdminAPI('get', `/track/advice/all`).then((adviceViewingData) =>
          this.setState({ adviceViewingData }),
        )
      }
    } else if (userId) {
      if (sessionId) {
        esAdminAPI(
          'get',
          `/track/session/${sessionId}/${userId}`,
        ).then(({ watches, timesWatched, viewPercentages }) =>
          this.setState({ watches, timesWatched, viewPercentages }),
        )
      }
    } else {
      esAdminAPI('get', `/track/session/${sessionId}`).then(({ sessionViewingData }) =>
        this.setState({
          sessionViewingData,
        }),
      )
    }
  }

  render() {
    const { children } = this.props

    return children({ ...this.state })
  }
}

TrackingDataApi.propTypes = {
  sessionId: PropTypes.number,
  userId: PropTypes.number,
  children: PropTypes.func.isRequired,
}

export default TrackingDataApi
