import { useEffect, useState } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const usePublicMessages = (channel = 'blog') => {
  const [publicMessages, setPublicMessages] = useState({
    publicMessages: [],
    loading: true,
    error: null,
  })

  useEffect(() => {
    getAllMessages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateSingleMessage = updatedMessage =>
    setPublicMessages(prev => ({
      ...prev,
      loading: false,
      publicMessages: prev.publicMessages.map(message =>
        message.id === updatedMessage.id ? updatedMessage : message,
      ),
    }))

  const handleError = error => setPublicMessages(prev => ({ ...prev, error, loading: false }))

  const getAllMessages = () => {
    esAdminAPI('get', `/public-messages/${channel}`)
      .then(publicMessages =>
        setPublicMessages(prev => ({ ...prev, publicMessages, loading: false })),
      )
      .catch(handleError)
  }

  const saveMessage = vals =>
    esAdminAPI('post', `/public-messages`, { ...vals, channel })
      .then(getAllMessages)
      .catch(err => {
        alert(err.message)
        throw err
      })

  const publishMessage = id =>
    esAdminAPI('patch', `/public-messages/${id}/publish`)
      .then(updateSingleMessage)
      .catch(handleError)

  const unpublishMessage = id =>
    esAdminAPI('patch', `/public-messages/${id}/unpublish`)
      .then(updateSingleMessage)
      .catch(handleError)

  return { publicMessages, saveMessage, publishMessage, unpublishMessage }
}
