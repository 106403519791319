import { css } from 'styled-components'
import { breakPoints } from './breakPoints'

// Iterate through the break points and create a min-width media template
export const mediaMinWidth = Object.keys(breakPoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakPoints[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
