import React, { useState } from 'react'
import styled from 'styled-components'
import { orderBy } from 'lodash/fp'
import Button from '../../../components/input/Button/Button'
import { ModalWithAction } from '../../../components/input/ModalWithAction/ModalWithAction'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import { Table, TH } from '../../../components/lists/Table'
import P from '../../../components/typography/P/P'
import { useLiveSessionDates } from '../../../containers/AdminApi/LiveSessionDatesApi'
import LiveDateRow from './LiveDateRow/LiveDateRow'

const orderBySessionDate = orderBy(['date'], ['desc'])

const AddNewDateButton = styled(Button)`
  margin-right: 10px;
`

const NotifyUsersButton = styled(Button)`
  margin-bottom: 20px;
  background: ${(props) => props.theme.colors.info};
`

const ConfirmationText = styled(P)`
  color: ${(props) => props.theme.colors.green};
`

const LiveDatesPage = () => {
  const {
    liveSession: { sessions = [], loading, error },
    addSessionDates,
    updateSession,
    sendNextSessionUpdateEmail,
    removeSession,
  } = useLiveSessionDates()

  const [nextSessionEmailSent, setNextSessionEmailSent] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)

  return (
    <>
      <AdminPage loading={loading} error={error}>
        <AdminPage.Heading>Live Session Dates</AdminPage.Heading>
        <AddNewDateButton onClick={() => addSessionDates(1)}>Add new date</AddNewDateButton>
        {nextSessionEmailSent ? (
          <ConfirmationText>Users have been notified of the next session status</ConfirmationText>
        ) : (
          <NotifyUsersButton onClick={() => setConfirmModalVisible(true)}>
            Send Update Email
          </NotifyUsersButton>
        )}
        <Table
          headings={
            <tr>
              <TH>Date</TH>
              <TH>Time</TH>
              <TH>Title</TH>
              {/* <TH>Attendees</TH> */}
              <TH>Zoom URL</TH>
              <TH>Calendar Invite URL</TH>
              <TH />
              <TH />
            </tr>
          }
          items={orderBySessionDate(sessions)}
        >
          {(session) => (
            <LiveDateRow
              key={session.id}
              session={session}
              onSave={updateSession}
              removeSession={() => removeSession(session.id)}
            />
          )}
        </Table>
      </AdminPage>
      {confirmModalVisible && (
        <ModalWithAction
          confirmButtonText="Send Status Update Email"
          closeModal={() => setConfirmModalVisible(false)}
          onPressConfirm={() => {
            setNextSessionEmailSent(true)
            setConfirmModalVisible(false)
            sendNextSessionUpdateEmail()
          }}
        />
      )}
    </>
  )
}

export default LiveDatesPage
