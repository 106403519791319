import PropTypes from 'prop-types'
import styled from 'styled-components'

const TH = styled.th`
  text-align: ${props => (props.center ? 'center' : props.right ? 'right' : 'left')};
`

TH.propTypes = {
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
}

/** @component */
export default TH
