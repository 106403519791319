import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import bugsnag from '../../services/bugsnag/bugsnag'
import { authTokenStore } from '../../services/esAPI/authTokenStore'
import esAPI from '../../services/esAPI/esAPI'
import { formatFormServerError } from '../../utils/forms/errors'

export const fetchCurrentUser = (userToken) =>
  esAPI('get', `/auth/me?userToken=${userToken || ''}`).then(({ user }) => user)

/**
 * Used to load the current user details and provide the
 * register, sign in and sign out actions.
 */
export const UserContext = React.createContext({ user: null })

export const CurrentUser = UserContext.Consumer

export const useCurrentUser = () => useContext(UserContext)

export class UserProvider extends React.Component {
  setUser = (user) => {
    this.setState({ user, loading: false })
    bugsnag.setUser(user)
  }

  clearUser = () => {
    this.setState({ user: null, loading: false })
    bugsnag.setUser(null)
  }

  setPromoCode = ({ promoCode, isValid }) => {
    this.setState({ promoCode: isValid ? promoCode : null })
  }

  state = {
    promoCode: null,
    user: this.props.user,
    loading: !this.props.user,
    signIn: (credentials) => esAPI('post', '/auth/sign-in', credentials).then(this.setUser),
    signOut: () => {
      this.clearUser()
      authTokenStore.clear()
    },
    requestPwdReset: (values) =>
      esAPI('post', '/auth/request-password-reset', values).catch(formatFormServerError),
    resetPassword: (values) =>
      esAPI('post', '/auth/reset-password', values).then(this.setUser).catch(formatFormServerError),

    questionnaireEmail: null,
    setQuestionnaireEmail: (email) => this.setState({ questionnaireEmail: email }),

    modalOpen: false,
    setModalStatus: (modalOpen) =>
      this.setState((state) => (state.modalOpen === modalOpen ? null : { modalOpen })),
  }

  componentDidMount() {
    if (!this.state.user) {
      fetchCurrentUser().then(this.setUser).catch(this.clearUser)
    }
  }

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>
  }
}

UserProvider.propTypes = {
  /** Optionally pass in a user, useful for SSR */
  user: PropTypes.object,
  /** Children can access the user using the UserContext.Consumer */
  children: PropTypes.node.isRequired,
}
