import React from 'react'
import { useNewsletterList } from '../../../containers/AdminApi/NewsletterListApi'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import SignUpsTable from './SignUpsTable/SignUpsTable'

const NewsletterSignUpsPage = () => {
  const {
    newsletterList: { signUps, loading, error },
  } = useNewsletterList()
  return (
    <AdminPage fullWidth heading="Newsletter Sign-Ups" loading={loading} error={error}>
      <SignUpsTable signUps={signUps} emptyMsg="There are no sign-ups yet" />
    </AdminPage>
  )
}

export default NewsletterSignUpsPage
