import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FORM_ERROR } from 'final-form'
import { Form, Field } from 'react-final-form'

import P from '../../typography/P/P'
import Input from '../../input/Input/Input'
import Button from '../../input/Button/Button'
import FormError from '../../input/FormError/FormError'

import { requiredEmail, minLength } from '../../../utils/forms/validation'

const minLength6 = minLength(6)

class SignInForm extends React.Component {
  pwdInputRef = React.createRef()

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleSubmit = values =>
    this.props.onSubmit(values).catch(error => {
      this.resetPwdInput()
      return { [FORM_ERROR]: error.message }
    })

  resetPwdInput = result => {
    if (this.mounted && this.pwdInput) {
      this.pwdInput.onChange('')
      this.pwdInputRef.current.focus()
    }
    return result
  }

  render() {
    const { submitText } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              type="email"
              component={Input}
              placeholder="Email Address"
              validate={requiredEmail}
            />
            <Field
              name="password"
              validate={minLength6}
              type="password"
              render={({ input, meta }) => {
                this.pwdInput = input
                return (
                  <Input meta={meta} input={input} ref={this.pwdInputRef} placeholder="Password" />
                )
              }}
            />
            <P>
              Request a <Link to="/request-password-reset">Password Reset</Link>
            </P>
            <FormError submitting={submitting} error={submitError} />
            <Button type="submit" block disabled={submitting}>
              {submitting ? 'Submitting...' : submitText || 'Sign In'}
            </Button>
          </form>
        )}
      />
    )
  }
}

SignInForm.propTypes = {
  /** Submit button text */
  submitText: PropTypes.string,
}

SignInForm.defaultProps = {
  submitText: 'Sign In',
}

export default SignInForm
