import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { colors } from '../../../theme'

const sizes = {
  sm: 0.4,
  md: 0.6,
  lg: 0.9,
}

const spinning = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: scale(0.5);
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid ${colors.primary};
    border-radius: 50%;
    animation: ${spinning} 1.4s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.primary} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`

class Spinner extends React.PureComponent {
  // To prevent a flashing spinner we wait a bit to see if the
  // content loads quickly.
  state = { wait: true }

  componentDidMount() {
    this.timeout = setTimeout(() => this.setState({ wait: false }), 200)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    const { size, ...props } = this.props
    return this.state.wait ? null : (
      <Container {...props} style={{ transform: `scale(${sizes[size]})` }}>
        <div />
        <div />
        <div />
        <div />
      </Container>
    )
  }
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

Spinner.defaultProps = {
  size: 'sm',
}

export default Spinner
