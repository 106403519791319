import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 1rem;
  background-color: #0000;
  border-collapse: collapse;
  font-size: 1.1rem;

  td,
  th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }

  tbody tr:last-child td {
    vertical-align: bottom;
    border-bottom: 1px solid #dee2e6;
  }

  tbody tr:nth-of-type(odd) {
    background-color: ${props => (props.banded ? '#0000000d' : 'inherit')};
  }

  tbody tr:hover {
    background-color: ${props => (props.hover ? '#00000010' : 'inherit')};
  }
`

const EmptyTR = styled.tr`
  background-color: #0000 !important;
  td {
    text-align: center;
    padding: 2em;
    font-size: 18px;
  }
`

const Table = ({ headings, filters, rows, items, emptyMsg, children, ...props }) => (
  <StyledTable {...props}>
    {headings && (
      <thead>
        {!Array.isArray(headings) ? (
          headings
        ) : (
          <tr>
            {headings.map((heading, i) => (
              <th key={i}>{heading}</th>
            ))}
          </tr>
        )}
      </thead>
    )}

    <tbody>
      {filters && filters}
      {(rows || []).filter(Boolean)}
      {items.map(children)}
      {!rows && !items.length && (
        <EmptyTR>
          <td colSpan={100}>{emptyMsg}</td>
        </EmptyTR>
      )}
    </tbody>
  </StyledTable>
)

Table.propTypes = {
  headings: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  rows: PropTypes.array,
  items: PropTypes.array.isRequired,
  emptyMsg: PropTypes.string,
  banded: PropTypes.bool,
  children: PropTypes.func.isRequired,
}

Table.defaultProps = {
  emptyMsg: 'There are no items in this list',
  banded: true,
}

export default Table
