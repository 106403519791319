import React from 'react'
import TD from '../../../../../components/lists/Table/TD'
import ViewTracking from '../../../../../components/layout/ViewTracking/ViewTracking'

const AdviceTrackingRow = ({ articleTitle, viewPercentages, watchData }) => (
  <tr>
    <TD>{articleTitle}</TD>
    <TD>
      <ViewTracking viewPercentages={viewPercentages} watchData={watchData} />
    </TD>
  </tr>
)

export default AdviceTrackingRow
