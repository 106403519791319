import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../../components/input/Button/Button'
import {
  FormattedInput,
  stringifiedStateToPlainText,
} from '../../../components/input/FormattedInput'
import { ModalWithAction } from '../../../components/input/ModalWithAction'
import { P } from '../../../components/typography'
import { mySqlToLongDateTime } from '../../../utils/date'
import MentoringAnswerForm from './MentoringAnswerForm'
import { ModeratorAnswers } from './ModeratorAnswers/ModeratorAnswers'
import { CopyButton } from './components/CopyButton'
import { InfoText } from './components/InfoText'
import { MentoringSection } from './components/Section'
import { TagsFormAndPreview } from './components/TagsFormAndPreview'
import { DRAFT, NEW, PUBLISHED } from './constants'

const QABlockContainer = styled.div`
  padding-top: 30px;
  margin-bottom: 40px;
`

const EditButton = styled(Button)`
  background: ${(props) =>
    props.answerExists ? props.theme.colors.gray : props.theme.colors.info};
  color: ${(props) => props.theme.colors.white};
  margin-right: 10px;
  margin-bottom: 10px;
`

const PublishButton = styled(Button)`
  background: ${(props) => (props.published ? props.theme.colors.error : props.theme.colors.green)};
  color: ${(props) => props.theme.colors.white};
  margin-right: 10px;
  margin-bottom: 10px;
`

const ModPublishButton = styled(PublishButton)`
  background: ${(props) => props.theme.colors.gold};
`

const AnswerSection = styled.div`
  margin-bottom: 30px;
`

const AnswerStatus = styled.span`
  color: ${({ status, theme }) =>
    status === PUBLISHED ? theme.colors.green : status === DRAFT ? 'darkorange' : 'red'};
`

const LongText = styled(P)`
  font-size: 20px;
  white-space: pre-wrap;
`

export const QuestionAnswerBlock = ({
  answerFormatted,
  answerPlainText,
  answerDate,
  clearCopiedIds,
  handlers,
  isCopied,
  published,
  publishingEnabled,
  question,
  questionDate,
  questionId,
  subject,
  toggleCopied,
  tags = [],
  setTags,
  modAnswers,
}) => {
  const [editingAnswer, setEditingAnswer] = useState(false)
  const [martinPublishModalVisible, setMartinPublishModalVisible] = useState(false)
  const [modPublishModalVisible, setModPublishModalVisible] = useState(false)
  const [modAnswersVisible, setModAnswersVisible] = useState(false)

  const answerStatus = published ? PUBLISHED : !!answerPlainText ? DRAFT : NEW

  const handleSubmit = async ({ answerFormatted }) => {
    try {
      clearCopiedIds()

      let stringifiedState

      // currently if the user doesn't type anything then answerFormatted is the object
      // containing both plainText and stringifiedState (i.e. the default value passed in)
      if (Object.keys(answerFormatted).includes('stringifiedState')) {
        stringifiedState = answerFormatted.stringifiedState
      } else {
        stringifiedState = answerFormatted
      }

      if (!stringifiedState) return setEditingAnswer(false)

      const answerPlainText = stringifiedStateToPlainText(stringifiedState)

      await handlers.postAnswer(questionId, {
        answerPlainText,
        answerFormatted: stringifiedState,
      })
      await handlers.getQuestionById(questionId)
      setEditingAnswer(false)
    } catch (err) {
      alert(err)
    }
  }

  const togglePublish = async (answerAuthor) => {
    if (!publishingEnabled) {
      alert('Only Martin can publish or unpublish answers')
      return
    }

    if (published) await handlers.unpublishAnswer(questionId)
    else await handlers.publishAnswer(questionId, answerAuthor)

    await handlers.getQuestionById(questionId)
  }

  const answerExists = !!answerPlainText || !!answerFormatted

  return (
    <QABlockContainer>
      <MentoringSection>
        <InfoText>
          <b>Subject Line:</b> {subject}
        </InfoText>
        <TagsFormAndPreview
          marginBottom="10px"
          initialTagsStr={tags.map((t) => t.name).join(', ')}
          setTags={setTags}
        />
        <InfoText small>
          <b>Status:</b> <AnswerStatus status={answerStatus}>{answerStatus}</AnswerStatus>
        </InfoText>
        <InfoText small>
          <b>Question Date:</b> {mySqlToLongDateTime(questionDate)}
        </InfoText>
        <InfoText style={{ paddingTop: '20px' }} small>
          <b>Question:</b>
        </InfoText>

        {question && <LongText>{question}</LongText>}
        {answerPlainText && (
          <AnswerSection>
            {published && (
              <InfoText small>
                <b>Answer Publish Date:</b> {mySqlToLongDateTime(answerDate)}
              </InfoText>
            )}
            <InfoText small>
              <b>Answer:</b>
            </InfoText>
            {!editingAnswer && (
              <FormattedInput
                editing={false}
                plainText={answerPlainText}
                stringifiedState={answerFormatted}
              />
            )}
          </AnswerSection>
        )}

        {editingAnswer && (
          <>
            <MentoringAnswerForm
              answerPlainText={answerPlainText}
              answerFormatted={answerFormatted}
              onSubmit={handleSubmit}
              closeAnswerForm={() => setEditingAnswer(false)}
              showModAnswers={() => setModAnswersVisible(true)}
            />
            {modAnswersVisible && (
              <ModeratorAnswers
                savedAnswers={modAnswers}
                addAnswer={handlers.addModAnswer}
                updateAnswer={handlers.updateModAnswer}
                removeAnswer={handlers.deleteModAnswer}
                closeModAnswers={() => setModAnswersVisible(false)}
              />
            )}
          </>
        )}

        {!published && !editingAnswer && (
          <EditButton link answerExists={answerExists} onClick={() => setEditingAnswer(true)}>
            {!!answerExists ? 'Edit Answer' : 'Add Answer'}
          </EditButton>
        )}

        {!editingAnswer && answerExists && (
          <>
            <PublishButton
              published={published}
              onClick={() => setMartinPublishModalVisible(true)}
              link
            >
              {published ? 'Unpublish' : 'Publish as Martin'}
            </PublishButton>
            {!published && (
              <ModPublishButton link onClick={() => setModPublishModalVisible(true)}>
                Publish as Mod
              </ModPublishButton>
            )}
            <CopyButton onClick={() => toggleCopied(questionId)} isCopied={isCopied}>
              {isCopied ? 'Copied' : 'Copy'}
            </CopyButton>
          </>
        )}
      </MentoringSection>
      <ModalWithAction
        visible={martinPublishModalVisible}
        confirmButtonText={published ? 'Unpublish' : 'Publish as Martin'}
        onPressConfirm={() => togglePublish('Martin')}
        closeModal={() => setMartinPublishModalVisible(false)}
      />
      <ModalWithAction
        visible={modPublishModalVisible}
        confirmButtonText={published ? 'Unpublish' : 'Publish as Mod'}
        onPressConfirm={() => togglePublish('Moderator')}
        closeModal={() => setModPublishModalVisible(false)}
        confirmButtonColor="gold"
      />
    </QABlockContainer>
  )
}
