import bugsnag from 'bugsnag-js'
import pick from 'lodash/pick'

const config = {
  apiKey: process.env.REACT_APP_BUGSNAG_JS_API_KEY,
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
  autoCaptureSessions: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE === 'production',
  beforeSend(report) {
    // Neither service workers nor jQuery is used by the app;
    // it is not clear which 3rd party service tries to use this
    if (
      report.errorMessage.indexOf('jQuery') > -1 ||
      report.errorMessage.indexOf('ServiceWorkerRegistration') > -1
    ) {
      return false
    }
  },
}

const bugsnagClient = bugsnag(config)

bugsnagClient.setContext = currentUrl => {
  bugsnagClient.context = currentUrl
}

bugsnagClient.setUser = user => {
  bugsnagClient.user = user
    ? pick(user, ['id', 'email', 'firstName', 'lastName', 'seminarId'])
    : null
}

export default bugsnagClient
