import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import LockIcon from '@mui/icons-material/Lock'
import { green, pink } from '@mui/material/colors'
import styled from 'styled-components'
import { format } from 'date-fns'
import { media } from 'styled-bootstrap-grid'
import { View } from '../../../../../components/layout'
import { colors } from '../../../../../theme'
import { mySqlToLongDateTime } from '../../../../../utils/date'

const TrackedContentStatus = styled.div`
  width: 35px;
  height: 35px;
  border: 1px solid black;
  padding: 0 10px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Wrapper = styled.div`
  width: 100%;

  ${TrackedContentStatus} {
    margin-top: 8px;
    margin-bottom: -6px;

    div {
      margin-top: 5px;
    }
  }

  ${media.md`
    display: flex;
    flex-direction: column;
  `}
`

const SubItem = styled(View)`
  padding: 16px 0;
  cursor: pointer;
  border-bottom: 1px solid #bbb;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  ${media.md`
    align-items: center;
  `}
`

const TrackedContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const TrackedContentTitle = styled(View)`
  width: 100%;
  padding-bottom: 5px;
  text-align: start;

  ${media.md`
    align-items: center;
    flex-direction: row;
  `}
`

const Numbering = styled.div`
  margin-right: 5px;
`

const Title = styled.div``

const VideoProgressStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 50px;
  margin-top: 20px;
  font-size: 20px;
  border-top: 1px solid lightgray;
  text-align: center;
`

const VideoStatusTitle = styled.div`
  display: flex;
  min-width: 50%;
`

const SubNumbering = styled.div`
  margin-right: 20px;
`

const Status = styled.div``

const AccessDate = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
`

const WrittenAndSuccess = styled.div`
  border-top: 1px solid lightgray;
  margin-top: 20px;
  padding-left: 30px;
  vertical-align: center;
`

const WrittenAndSuccessTitle = styled(TrackedContentTitle)`
  margin-top: 12px;
`

export const FoundationTrackedItem = ({
  title,
  href = '',
  complete,
  subContents,
  progress,
  index,
  accessDate,
  resourceAccessDate,
}) => {
  const mainNumbering = index + 1
  return (
    <SubItem>
      <Wrapper>
        <TrackedContent>
          <TrackedContentTitle>
            <Numbering>{'#' + mainNumbering + '.'}</Numbering>
            <Title>{title}</Title>
          </TrackedContentTitle>
          {complete && resourceAccessDate && (
            <AccessDate>{mySqlToLongDateTime(resourceAccessDate)}</AccessDate>
          )}
          {accessDate && <AccessDate>{mySqlToLongDateTime(accessDate)}</AccessDate>}
          <TrackedContentStatus>
            {complete ? (
              <DoneRoundedIcon fontSize="large" sx={{ color: green[500] }} />
            ) : (
              <CloseRoundedIcon fontSize="large" sx={{ color: pink[500] }} />
            )}
          </TrackedContentStatus>
        </TrackedContent>
        {progress && (
          <>
            {progress.map((step, index) => {
              const subNumbering = index + 1
              return (
                <VideoProgressStatus key={index}>
                  <VideoStatusTitle>
                    <SubNumbering>{`${mainNumbering}.${subNumbering}`}</SubNumbering>
                    <Status>{step.status}</Status>
                  </VideoStatusTitle>
                  <TrackedContentStatus>
                    <div>
                      {step.complete ? (
                        <DoneRoundedIcon fontSize="large" sx={{ color: green[500] }} />
                      ) : (
                        <CloseRoundedIcon fontSize="large" sx={{ color: colors.errorRed }} />
                      )}
                    </div>
                  </TrackedContentStatus>
                </VideoProgressStatus>
              )
            })}
          </>
        )}
        {subContents &&
          subContents.map(
            ({ title, complete, href, writtenContentAccessDate, successChecklistAccessDate }) => (
              <WrittenAndSuccess key={href}>
                <TrackedContent>
                  <WrittenAndSuccessTitle>
                    <Title key={title}>{title}</Title>
                  </WrittenAndSuccessTitle>
                  {complete ? (
                    <AccessDate>
                      {title === 'Read Written Content'
                        ? mySqlToLongDateTime(writtenContentAccessDate)
                        : mySqlToLongDateTime(successChecklistAccessDate)}
                    </AccessDate>
                  ) : null}
                  <TrackedContentStatus>
                    <div>
                      {complete ? (
                        <DoneRoundedIcon fontSize="large" sx={{ color: green[500] }} />
                      ) : (
                        <CloseRoundedIcon fontSize="large" sx={{ color: colors.errorRed }} />
                      )}
                    </div>
                  </TrackedContentStatus>
                </TrackedContent>
              </WrittenAndSuccess>
            ),
          )}
      </Wrapper>
    </SubItem>
  )
}
