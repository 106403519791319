import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'

import FormError from '../../../../components/input/FormError/FormError'
import FormInlineButton from '../../../../components/input/FormInline/FormInlineButton'
import FormInlineInput from '../../../../components/input/FormInline/FormInlineInput'
import FormInlineRow from '../../../../components/input/FormInline/FormInlineRow'
import { required } from '../../../../utils/forms/validation'
import { timeZones } from '@es/common/src/config/timeZones'

const LiveDateForm = props => (
  <Form
    {...props}
    render={({ handleSubmit, submitting, submitError, values }) => (
      <form onSubmit={handleSubmit} style={{ marginBottom: '0.5em' }}>
        <FormInlineRow>
          <Field
            name="date"
            placeholder="Session Date"
            component={FormInlineInput}
            componentClass={FormInlineInput.DateTimePicker}
            format={date => utcToZonedTime(date, values.timeZone)}
            parse={date => zonedTimeToUtc(date, values.timeZone)}
            help={format(values.date, 'zzzz', values)}
            validate={required}
          />
          <Field
            name="timeZone"
            placeholder="Time Zone"
            component={FormInlineInput}
            componentClass={FormInlineInput.Select}
            validate={required}
          >
            {timeZones.map(timeZone => (
              <option key={timeZone}>{timeZone}</option>
            ))}
          </Field>
          <Field name="title" placeholder="Session Title" component={FormInlineInput} />
          <Field name="url" placeholder="Zoom URL" component={FormInlineInput} />
          <Field
            name="calendarInviteUrl"
            placeholder="Calendar Invite URL"
            component={FormInlineInput}
          />
          <div>
            <FormInlineButton type="submit" disabled={submitting}>
              {submitting ? 'Saving...' : 'Save'}
            </FormInlineButton>
          </div>
        </FormInlineRow>
        <FormError submitting={submitting} error={submitError} />
      </form>
    )}
  />
)

LiveDateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LiveDateForm
