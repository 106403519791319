import React from 'react'
import styled from 'styled-components'
import { DropDownFilter } from './DropdownFilterCell'

const TH = styled.th`
  font-weight: normal;
  text-align: left;
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const FixedWidth = styled.div`
  width: 50px;
`

export const TimeFilterCell = ({ from, to, setFromTime, setToTime }) => (
  <TH>
    <FlexColumn>
      <FlexRow>
        <FixedWidth>From:</FixedWidth>
        <DropDownFilter
          value={from}
          setFilter={setFromTime}
          options={Array(24)
            .fill(null)
            .map((_, i) => i)}
        />
      </FlexRow>
      <FlexRow>
        <FixedWidth>To:</FixedWidth>
        <DropDownFilter
          value={to}
          setFilter={setToTime}
          options={Array(24)
            .fill(null)
            .map((_, i) => i)}
        />
      </FlexRow>
    </FlexColumn>
  </TH>
)
