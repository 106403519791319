import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import Input from '../../../../components/input/Input/Input'
import FormError from '../../../../components/input/FormError/FormError'
import { maxLength100Required, required } from '../../../../utils/forms/validation'
import Button from '../../../../components/input/Button/Button'

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background: ${props => props.theme.colors.green};
`

const MinHeightTextArea = styled(Input.TextArea)`
  min-height: 200px;
`

const FullWidthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ModAnswerForm = ({ title, body, ...props }) => (
  <Form
    {...props}
    render={({ handleSubmit, submitting, submitError, form, valid }) => (
      <FullWidthForm
        onSubmit={async vals => {
          await handleSubmit(vals)
          form.reset()
        }}
      >
        <Field
          name="title"
          component={Input}
          placeholder="Type short name for answer..."
          validate={maxLength100Required}
          initialValue={title}
        />
        <Field
          name="body"
          component={Input}
          componentClass={MinHeightTextArea}
          placeholder="Type answer..."
          validate={required}
          initialValue={body}
        />
        <FormError submitting={submitting} error={submitError} />
        <SubmitButton solid type="submit" disabled={submitting || !valid}>
          {submitting ? 'Saving...' : 'Save Answer'}
        </SubmitButton>
      </FullWidthForm>
    )}
  />
)

ModAnswerForm.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
