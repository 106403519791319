import React from 'react'
import TH from './TH'

export const TextFilterCell = ({ value, placeholder = 'Filter...', setFilter }) => (
  <TH left>
    <input
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) => setFilter(value)}
    />
  </TH>
)
