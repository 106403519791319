import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Icon = styled.span`
  display: inline-block;
  float: ${(props) => (props.noFloat ? 'none' : 'right')};
  cursor: pointer;
`

const EditIcon = ({ title, onClick, ...props }) => (
  <Icon title={title} onClick={onClick} {...props}>
    <span role="img" aria-label="Edit">
      📝
    </span>
  </Icon>
)

EditIcon.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default EditIcon
