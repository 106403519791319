import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import ScrollToTop from './containers/ScrollToTop/ScrollToTop'
import { UserProvider } from './containers/UserContext/UserContext'
import Admin from './pages/Admin/Admin'
import * as theme from './theme'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
})

const App = ({ user }) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <UserProvider user={user}>
          <ScrollToTop />
          <Admin />
        </UserProvider>
      </BrowserRouter>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>
)

export default App
