import React from 'react'
import { usePayments } from '../../../containers/AdminApi/PaymentsListApi'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import PaymentsTable from './PaymentsTable/PaymentsTable'

const PaymentsPage = () => {
  const {
    payment: { payments, loading, error },
  } = usePayments()
  return (
    <AdminPage fullWidth heading="Payments" loading={loading} error={error}>
      <PaymentsTable payments={payments} emptyMsg="There are no payments yet" />
    </AdminPage>
  )
}

export default PaymentsPage
