import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styled from 'styled-components'

const TH = styled.th`
  font-weight: normal;
  text-align: left;
`
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const FlexRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`

const FixedWidth = styled.div`
  width: 50px;
`

export const DateFilterCell = ({ from = new Date(), to = new Date(), setFromDate, setToDate }) => (
  <TH>
    <FlexColumn>
      <FlexRow>
        <FixedWidth>From:</FixedWidth>
        <DatePicker selected={from} onChange={setFromDate} />
      </FlexRow>
      <FlexRow>
        <FixedWidth>To:</FixedWidth>
        <DatePicker selected={to} onChange={setToDate} />
      </FlexRow>
    </FlexColumn>
  </TH>
)
