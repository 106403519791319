import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import UserForm from '../../../../../components/auth/UserForm/UserForm'
import EditIcon from '../../../../../components/icons/EditIcon'
import { TD } from '../../../../../components/lists/Table'
import { formatDate } from '../../../../../utils/date'
import { PhoneNumberView } from '../../../Leads/LeadsTable/LeadRow/PhoneNumberView'

const RemoveButton = styled.span`
  cursor: pointer;
`
const CheckBox = styled.input`
  margin-right: 10px;
  height: 15px;
  width: 20px;
`

class PaidUserRow extends React.Component {
  state = { edit: false }

  toggleEdit = (ev) => {
    ev && ev.stopPropagation()
    this.setState((state) => ({ edit: !state.edit }))
  }

  handleUpdate = (values) => {
    const { user } = this.props
    return this.props
      .onSave(user.id, {
        ...user,
        ...values,
      })
      .then((formError) => formError || this.toggleEdit())
  }

  render() {
    const {
      adviceTracking,
      coreSessionTracking,
      onSave,
      onClickRemove,
      user,
      selectedUsers,
      toggleCheckBox,
      programStatus,
    } = this.props
    const {
      accessedSessions,
      accessedWrittenContent,
      availableContent: { liveSession, mentoringExpiryDate, referrerEmail },
      city,
      country,
      email,
      firstName,
      hasPassword,
      id,
      lastName,
      phone,
      program,
      programExpiryDate,
      promoCode,
      source,
      telesalesSource,
      timeZone,
      utmSource = '-',
      utmMedium = '-',
      utmCampaign = '-',
      utmTerm = '-',
      utmContent = '-',
    } = user

    if (onSave && this.state.edit) {
      return (
        <tr>
          <td colSpan={30}>
            <UserForm
              closeForm={this.toggleEdit}
              submitText="Save"
              onSubmit={this.handleUpdate}
              initialValues={{
                ...user,
                mentoringExpiryDate: user.availableContent.mentoringExpiryDate || new Date().toISOString(),
              }}
            />
          </td>
        </tr>
      )
    }

    return (
      <>
        <tr key={id}>
          <TD>
            <CheckBox type="checkbox" checked={selectedUsers.includes(id)} onChange={(e) => toggleCheckBox(e, id)} />
            <Link
              to={{
                pathname: `/tracking/${firstName}/${lastName}/${id}`,
                state: {
                  coreSessionTracking,
                  adviceTracking,
                },
              }}
            >
              {firstName}
            </Link>
          </TD>
          <TD>{email}</TD>
          <TD>
            <PhoneNumberView phoneNumber={phone} />
          </TD>
          <TD>{source}</TD>
          <TD>{utmSource}</TD>
          <TD>{utmMedium}</TD>
          <TD>{utmCampaign}</TD>
          <TD>{utmTerm}</TD>
          <TD>{utmContent}</TD>
          <TD>{program}</TD>
          <TD center>{programStatus ? <>&#10004;</> : <>&times;</>}</TD>
          <TD>{programExpiryDate && formatDate(programExpiryDate)}</TD>
          <TD>{mentoringExpiryDate && formatDate(mentoringExpiryDate)}</TD>
          <TD>{trueFalseMap[hasPassword]}</TD>
          <TD>{liveSession && formatDate(liveSession.date)}</TD>
          <TD>{formatDate(user.paymentDate)}</TD>
          <TD center>{accessedSessions ? <>&#10004;</> : <>&times;</>}</TD>
          <TD center>{accessedWrittenContent ? <>&#10004;</> : <>&times;</>}</TD>
          <TD>{telesalesSource}</TD>
          <TD>{promoCode}</TD>
          <TD>{country}</TD>
          <TD>{city}</TD>
          <TD>{timeZone}</TD>
          <TD>{referrerEmail}</TD>
          <TD>{onSave && <EditIcon title="Edit User" onClick={this.toggleEdit} />}</TD>
          <TD>
            <RemoveButton onClick={onClickRemove}>Remove</RemoveButton>
          </TD>
        </tr>
      </>
    )
  }
}

const trueFalseMap = {
  true: 'Yes',
  false: 'No',
}

PaidUserRow.propTypes = {
  user: PropTypes.object.isRequired,
  onSave: PropTypes.func,
}

export default PaidUserRow
