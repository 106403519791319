import React, { useState, useCallback } from 'react'
import { orderBy } from 'lodash/fp'
import styled from 'styled-components'
import { ModalWithAction } from '../../../components/input/ModalWithAction'
import { H3, P } from '../../../components/typography'
import { TagRenameForm } from './components/TagRenameForm'

const orderByCount = orderBy(['count'], ['desc'])

const Container = styled.div`
  margin-bottom: 40px;
`

const Summary = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`

const ClickableText = styled.div`
  font-weight: bold;
  text-decoration-line: underline;
  user-select: none;
  cursor: pointer;
`

const Data = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const DataRow = styled.div`
  background: ${props => (props.index % 2 === 0 ? props.theme.colors.grayLight : 'transparent')};
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px 5px;
`
const DataCell = styled(P)`
  margin: 0;
  padding: 5px 10px;
`

const TagButton = styled(DataCell)`
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
`

const TagName = styled(DataCell)`
  width: 200px;
  word-break: break-word;
  text-align: left;
`

export const TagsManager = ({ tagCounts = [], deleteTagGlobally, renameTagGlobally }) => {
  const [showAll, setShowAll] = useState(false)

  const sortedByCount = orderByCount(tagCounts)
  const countAfter5 = sortedByCount.length - 5

  const toTagRow = useCallback(
    (idxOffset = 0) => ({ tag, count }, i) => (
      <TagRow
        key={tag}
        tagName={tag}
        tagCount={count}
        i={i + idxOffset}
        deleteTagGlobally={deleteTagGlobally}
        renameTagGlobally={renameTagGlobally}
      />
    ),
    [deleteTagGlobally, renameTagGlobally],
  )

  return (
    <Container>
      <H3>Tags Summary</H3>
      {sortedByCount.length === 0 ? (
        <P>
          <b>No tags have been added yet</b>
        </P>
      ) : (
        <Summary>
          <Data>
            {sortedByCount.slice(0, 5).map(toTagRow(0))}
            {showAll && sortedByCount.slice(5).map(toTagRow(1))}
          </Data>
          {countAfter5 > 0 && (
            <ClickableText onClick={() => setShowAll(prev => !prev)}>
              Show {showAll ? 'less' : `${countAfter5} more`}
            </ClickableText>
          )}
        </Summary>
      )}
    </Container>
  )
}

const TagRow = ({ deleteTagGlobally, i, renameTagGlobally, tagCount, tagName }) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [confirmModalAction, setConfirmModalAction] = useState('')
  const [renameFormVisible, setRenameFormVisible] = useState(false)
  const [newTagName, setNewTagName] = useState('')

  if (renameFormVisible) {
    return (
      <TagRenameForm
        editing={renameFormVisible}
        initialValue={tagName}
        onSubmit={vals => {
          setNewTagName(vals.tagName)
          setConfirmModalAction('Rename')
          setRenameFormVisible(false)
          setConfirmModalVisible(true)
        }}
      />
    )
  }

  return (
    <>
      <DataRow index={i}>
        <TagName>{tagName}</TagName>
        <DataCell>{tagCount}</DataCell>
        <Row>
          <TagButton
            onClick={() => {
              setConfirmModalAction('Rename')
              setRenameFormVisible(true)
            }}
          >
            Rename
          </TagButton>
          <TagButton
            onClick={() => {
              setConfirmModalAction('Remove')
              setConfirmModalVisible(true)
            }}
          >
            x
          </TagButton>
        </Row>
      </DataRow>
      {confirmModalVisible && (
        <ModalWithAction
          body={`Are you sure you want to ${confirmModalAction.toUpperCase()} ${tagName} (${tagCount} associated question${
            tagCount === 1 ? '' : 's'
          }) `}
          confirmButtonText={confirmModalAction}
          onPressConfirm={
            confirmModalAction === 'Remove'
              ? () => deleteTagGlobally(tagName)
              : () => renameTagGlobally(tagName, newTagName)
          }
          closeModal={() => setConfirmModalVisible(false)}
        />
      )}
    </>
  )
}
