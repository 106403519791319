import React, { useState } from 'react'
import styled from 'styled-components'

const Toggle = styled.span`
  cursor: pointer;
  font-size: 20px;
`

const ExpandableSection = ({ children }) => {
  const [expand, setExpand] = useState(false)
  return (
    <>
      {expand && children}
      <Toggle onClick={() => setExpand(!expand)}>{expand ? 'Show Less' : 'Show More'}</Toggle>
    </>
  )
}

export default ExpandableSection
