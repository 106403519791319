import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import Input from '../../../components/input/Input/Input'
import FormError from '../../../components/input/FormError/FormError'
import { required } from '../../../utils/forms/validation'
import Button from '../../../components/input/Button/Button'

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background: ${props => props.theme.colors.green};
`

const MinHeightTextArea = styled(Input.TextArea)`
  min-height: 70px;
`

const FullWidthForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`

export const AddQuestionForm = ({ addQuestion, ...props }) => (
  <Form
    {...props}
    onSubmit={addQuestion}
    render={({ handleSubmit, submitting, submitError, form }) => {
      return (
        <FullWidthForm
          onSubmit={async vals => {
            await handleSubmit(vals)
            form.reset()
          }}
        >
          <Field
            name="questionBody"
            component={Input}
            componentClass={MinHeightTextArea}
            placeholder="Add a new question..."
            validate={required}
          />
          <FormError submitting={submitting} error={submitError} />
          <SubmitButton solid type="submit" disabled={submitting}>
            {submitting ? 'Saving...' : 'Save Question'}
          </SubmitButton>
        </FullWidthForm>
      )
    }}
  />
)

AddQuestionForm.propTypes = {
  addQuestion: PropTypes.func.isRequired,
}
