import React from 'react'
import styled from 'styled-components'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { H2 } from '../../../components/typography'
import { usePublicMessages } from '../../../containers/AdminApi/PublicMessagesApi'
import { MessageList } from './MessageList'
import { WriteNewMessage } from './WriteNewMessage'
import { oderByPublishDateDesc } from './helpers'

const Page = styled.div`
  max-width: 'auto';
`

const greetingText = 'Hi...'
const signatureText = 'Best, Martin'

const EmailsPage = () => {
  const {
    publicMessages: { error, loading, publicMessages },
    saveMessage,
    publishMessage,
  } = usePublicMessages('email')

  return (
    <Page>
      {loading ? (
        <LoadingSection />
      ) : (
        <ErrorSection error={error}>
          <H2>Emails from Martin</H2>
          <WriteNewMessage
            initialValues={{
              targets: ['starter', 'foundation', 'accelerator', 'subscription'],
              mentoringStatusFilter: ['active', 'inactive'],
            }}
            saveMessage={saveMessage}
            typeMsg="Email"
            greetingText={greetingText}
            signatureText={signatureText}
          />
          <MessageList
            greetingText={greetingText}
            signatureText={signatureText}
            saveMessage={saveMessage}
            publishMessage={publishMessage}
            title="Unsent Emails"
            messages={publicMessages.filter((m) => !m.published)}
            publishButtonText="Send"
          />
          <MessageList
            greetingText={greetingText}
            signatureText={signatureText}
            saveMessage={saveMessage}
            publishMessage={publishMessage}
            title="Sent Emails"
            messages={oderByPublishDateDesc(publicMessages.filter((m) => m.published))}
          />
        </ErrorSection>
      )}
    </Page>
  )
}

export default EmailsPage
