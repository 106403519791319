import { useQuery } from 'react-query'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

const initialData = {
  acceleratorProgram: null,
  bonusSessions: [],
  coreSessions: [],
  foundationProgram: null,
  hasSeenWelcomeVideo: false,
  instalmentPlan: null,
  mentoringActive: false,
  mentoringExpiryDate: null,
  monthlyCoreSession: [],
  paymentDate: null,
  program: null,
  programText: 'No ',
  starterProgram: null,
  subscription: null,
  teachingSessions: [],
  unlockedContent: {},
}

export const useContentAvailableQuery = (userId, options = {}) => {
  const query = useQuery(
    `content-available-${userId}`,
    () => esAdminAPI('get', `/course/content-available/${userId}`),
    {
      initialData,
      ...options,
    },
  )

  return { ...query, contentAvailable: query.data }
}
