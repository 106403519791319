import React from 'react'
import marked from 'marked'

class ReactMarkdown extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.children !== this.props.children || nextProps.className !== this.props.className
    )
  }

  render() {
    const { children, className, style } = this.props
    return (
      <div
        style={style}
        className={className}
        dangerouslySetInnerHTML={{ __html: marked(children || '') }}
      />
    )
  }
}

export default ReactMarkdown
