import React, { useState } from 'react'
import styled from 'styled-components'
import EditIcon from '../../../components/icons/EditIcon'
import { TD } from '../../../components/lists/Table'
import { ConfigRowForm } from './ConfigRowForm'

const CheckMark = () => <>&#10004;</>

export const Cell = styled(TD)`
  text-align: center;
  max-width: 100px;
`

const SlugCell = styled(Cell)`
  text-align: left;
`

const ActionsCell = styled(Cell)`
  max-width: 60px;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const RemoveButton = styled.div`
  cursor: pointer;
`

export const ConfigRow = ({ updateConfig, deleteConfig, ...props }) => {
  const [editing, setEditing] = useState(false)

  const formProps = {
    submitText: 'Save',
    onCancel: () => setEditing(false),
    onSubmit: (vals) => {
      updateConfig(vals)
      setEditing(false)
    },
  }

  const { slug, mailingListId, GB, US } = props ?? {}
  return editing ? (
    <ConfigRowForm {...props} {...formProps} />
  ) : (
    <tr>
      <SlugCell>{slug}</SlugCell>
      <Cell>{mailingListId}</Cell>
      <Cell>{GB && <CheckMark />}</Cell>
      <Cell>{US && <CheckMark />}</Cell>
      <ActionsCell>
        <Actions>
          <EditIcon onClick={() => setEditing(true)} />
          <RemoveButton onClick={deleteConfig}>Remove</RemoveButton>
        </Actions>
      </ActionsCell>
    </tr>
  )
}
