import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { formatAmount } from '@es/common/src/utils/currency'
import { Table, TD, TH } from '../../../../components/lists/Table'

const PaymentsTable = ({ payments, updateUser, emptyMsg }) => (
  <Table
    headings={
      <tr>
        <TH>Name</TH>
        <TH>Email</TH>
        <TH>Date</TH>
        <TH>Amount</TH>
        <TH>Promo Code</TH>
        <TH>Payment Provider / id</TH>
      </tr>
    }
    items={payments}
    emptyMsg={emptyMsg}
  >
    {({
      id,
      fullName,
      email,
      date,
      stripeChargeId,
      paypalOrderId,
      currency,
      amount,
      promoCode,
    }) => (
      <tr key={id}>
        <TD>{fullName}</TD>
        <TD>{email}</TD>
        <TD>{format(date, 'PPP p')}</TD>
        <TD>{formatAmount(currency, amount)}</TD>
        <TD>
          <Link to={`/promo-codes/${promoCode}`}>{promoCode}</Link>
        </TD>
        <TD>{stripeChargeId ? `Stripe / ${stripeChargeId}` : `PayPal / ${paypalOrderId}`}</TD>
      </tr>
    )}
  </Table>
)

PaymentsTable.propTypes = {
  payments: PropTypes.array.isRequired,
  emptyMsg: PropTypes.string.isRequired,
}

export default PaymentsTable
