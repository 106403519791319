import React from 'react'
import { Table, TH } from '../../../../components/lists/Table'
import AdviceTrackingRow from './AdviceTrackingRow/AdviceTrackingRow'

const AdviceTrackingTable = ({ viewingData }) => (
  <Table
    headings={
      <tr>
        <TH>Video/Article</TH>
        <TH>No. Users Watched</TH>
        <TH>Avg. Views per User</TH>
        <TH>Avg. Watch Time</TH>
        <TH>Social Shares</TH>
      </tr>
    }
    items={Object.entries(viewingData)}
    emptyMsg={''}
  >
    {article => {
      return <AdviceTrackingRow key={`${article[0]}`} article={article} />
    }}
  </Table>
)

export default AdviceTrackingTable
