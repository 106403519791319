import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isAfter from 'date-fns/fp/isAfter'

import { formatZonedTime } from '@es/common/src/utils/formatZonedTime'
import LiveDateForm from '../LiveDateForm/LiveDateForm'
import EditIcon from '../../../../components/icons/EditIcon'
import TD from '../../../../components/lists/Table/TD'
import P from '../../../../components/typography/P/P'
import { ModalWithAction } from '../../../../components/input/ModalWithAction/ModalWithAction'

const LiveDateRow = ({ session, onSave, removeSession }) => {
  const { id, date, timeZone, title, url, calendarInviteUrl } = session
  const [editing, setEditing] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const toggleEdit = () => setEditing(prev => !prev)

  const editable = isAfter(new Date(), date)

  if (editing) {
    return (
      <tr>
        <td colSpan={10}>
          <LiveDateForm
            initialValues={session}
            onSubmit={values =>
              onSave(id, {
                ...values,
                url: values.url || null,
                calendarInviteUrl: values.calendarInviteUrl || null,
              }).then(toggleEdit)
            }
          />
        </td>
      </tr>
    )
  }

  return (
    <>
      <tr style={{ cursor: 'pointer' }}>
        <TD>{formatZonedTime('EEE PPP')(date, timeZone)}</TD>
        <TD>{formatZonedTime('p zz')(date, timeZone)}</TD>
        <TD>{title}</TD>
        {/* <TD>{liveSignUps}</TD> */}
        <UrlCell url={url} />
        <UrlCell url={calendarInviteUrl} />
        <TD>{editable && <EditIcon title="Edit" onClick={toggleEdit} />}</TD>
        <TD>{editable && <P onClick={() => setConfirmModalVisible(true)}>Remove</P>}</TD>
      </tr>
      <ModalWithAction
        visible={confirmModalVisible}
        confirmButtonColor="primary"
        confirmButtonText="Remove Session"
        onPressConfirm={removeSession}
        closeModal={() => setConfirmModalVisible(false)}
      />
    </>
  )
}

const Url = styled.div`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    text-decoration: underline;
  }
`

const UrlCell = ({ url }) => (
  <TD>
    <Url>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    </Url>
  </TD>
)

LiveDateRow.propTypes = {
  session: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default LiveDateRow
