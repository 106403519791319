import PropTypes from 'prop-types'
import styled from 'styled-components'

const FormInlineRow = styled.div`
  display: flex;
  width: ${props => props.width || 'auto'};
`

FormInlineRow.propTypes = {
  width: PropTypes.string,
}

/** @component */
export default FormInlineRow
