import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
`

/**
 * The wrapper for a navigation menu, takes `NavItem` as children which in turn takes `Link`.
 */
const Nav = ({ className, style, vertical, children }) => (
  <nav className={className} style={style}>
    <NavList style={{ flexDirection: vertical ? 'column' : 'row' }}>{children}</NavList>
  </nav>
)

Nav.propTypes = {
  /** Switch from a horizontal nav menu to a vertical list */
  vertical: PropTypes.bool,
}

Nav.defaultProps = {
  vertical: false,
}

export default Nav
