import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import dateFnsLocalizer, { defaultFormats } from 'react-widgets-date-fns'
import { Provider } from 'unstated'
import LoadingSection from '../../components/loading/Section/LoadingSection'
import { CurrentUser } from '../../containers/UserContext/UserContext'
import AdminUsersPage from './AdminUsers/AdminUsersPage'
import AdvicePage from './AdvicePage/AdvicePage'
import AdviceTracking from './AdviceTracking/AdviceTracking'
import FeedbackPage from './FeedbackPage/FeedbackPage'
import AdminHeader from './Header/AdminHeader'
import LeadsPage from './Leads/LeadsPage'
import LiveDatesPage from './LiveDatesPage/LiveDatesPage'
import { MailingListConfigsPage } from './MailingListConfigs/MailingListConfigsPage'
import MentoringPage from './MentoringPage/MentoringPage'
import UsersQuestions from './MentoringPage/UsersQuestions'
import NewsletterSignUpsPage from './NewsletterSignUps/NewsletterSignUpsPage'
import PaidUsersPage from './PaidUsers/PaidUsersPage'
import PasswordResetPage from './PasswordReset/PasswordResetPage'
import PaymentsPage from './Payments/PaymentsPage'
import BlogPage from './PublicMessages/BlogPage'
import EmailsPage from './PublicMessages/EmailsPage'
import SignInPage from './SignIn/SignInPage'
import TestominalsPage from './TestimonialsPage/TestimonialsPage'
import TrackingPage from './Tracking/TrackingPage'

dateFnsLocalizer({
  formats: {
    ...defaultFormats,
    dayOfMonth: 'd',
    date: 'yyyy-MM-dd',
    time: 'HH:mm',
    default: 'yyyy-MM-dd HH:mm',
  },
})

const userIsAdmin = (user) => user && user.role === 'admin'

const Admin = () => (
  <Provider>
    <AdminHeader />

    <CurrentUser>
      {({ loading, user }) =>
        loading ? (
          <LoadingSection />
        ) : userIsAdmin(user) ? (
          <Switch>
            <Route path="/leads" exact component={LeadsPage} />
            <Route path="/paid-users" component={PaidUsersPage} />
            <Route path="/newsletter-sign-ups" component={NewsletterSignUpsPage} />
            <Route path="/payments" component={PaymentsPage} />
            <Route path="/live-sessions" component={LiveDatesPage} />
            {/* <Route path="/landing-pages" component={LandingPagesPage} /> */}
            {/* <Route path="/entrepreneur-assessments" component={AssessmentsPage} /> */}
            <Route path="/admins" component={AdminUsersPage} />
            <Route path="/advice" component={AdvicePage} />
            <Route path="/advice-tracking" component={AdviceTracking} />
            <Route path="/mentoring/questions/:userId/:questionId" component={UsersQuestions} />
            <Route path="/mentoring" component={MentoringPage} />
            <Route path="/es-blog" component={BlogPage} />
            <Route path="/es-emails" component={EmailsPage} />
            <Route path="/tracking/:firstName/:lastName/:userId" component={TrackingPage} />
            <Route path="/testimonials" component={TestominalsPage} />
            <Route path="/feedback" component={FeedbackPage} />
            <Route path="/mailing-list-configs" component={MailingListConfigsPage} />
            <Redirect
              to={
                ['martin1warner@gmail.com', 'martin@entrepreneurseminar.com'].includes(user.email)
                  ? '/mentoring'
                  : '/paid-users'
              }
            />
          </Switch>
        ) : (
          <Switch>
            <Route path="/request-password-reset" component={PasswordResetPage} />
            <Route component={SignInPage} />
          </Switch>
        )
      }
    </CurrentUser>
  </Provider>
)

export default Admin
