import { useState, useEffect } from 'react'
import { formatFormServerError } from '../../utils/forms/errors'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const useAdminUsers = () => {
  const [admin, setAdmin] = useState({ users: [], loading: true })

  useEffect(() => {
    esAdminAPI('get', '/admins')
      .then(users => setAdmin(prev => ({ ...prev, users, loading: false })))
      .catch(error => setAdmin(prev => ({ ...prev, error, loading: false })))
  }, [])

  const addAdmin = values =>
    esAdminAPI('post', '/admins', values)
      .then(adminUser => setAdmin(prev => ({ ...prev, users: [adminUser, ...prev.users] })))
      .catch(formatFormServerError)

  const removeAdmin = userId => {
    const user = admin.users.find(user => user.id === userId)
    if (
      window.confirm(
        `Are you sure you want to remove the admin role from ${user.firstName} ${user.lastName}`,
      )
    ) {
      esAdminAPI('delete', `/admins/${userId}`).then(() =>
        setAdmin(prev => ({ ...prev, users: prev.users.filter(user => user.id !== userId) })),
      )
      // todo Catch errors and push to some global handler like a snack bar
    }
  }

  return { admin, addAdmin, removeAdmin }
}
