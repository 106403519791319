import styled from 'styled-components'

export const MentoringSection = styled.div`
  background-color: ${props => props.theme.colors.grayLight};
  border: 1px solid ${props => props.theme.colors.grayLight};
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  text-align: left;
`
