import { useEffect, useState, useCallback } from 'react'

/**
 * Takes an array of objects with shape:
 *
 * {
 *   id: number,
 *   text: string
 * }
 *
 */

export const useCopyFromList = items => {
  const [idsWithCopiedText, setIdsWithCopiedText] = useState([])

  useEffect(() => {
    setIdsWithCopiedText([])
  }, [items.length])

  const copyQuestionBodies = useCallback(
    (idArr = []) => {
      let text = ''

      idArr.forEach(id => {
        const item = items.find(q => q.id === id)

        text += item.text
        text += '\n\n'
      })
      navigator.clipboard.writeText(text)
    },
    [items],
  )

  const addToCopiedText = useCallback(
    id => {
      const newIds = [...idsWithCopiedText, id].sort()
      copyQuestionBodies(newIds)
      setIdsWithCopiedText(newIds)
    },
    [copyQuestionBodies, idsWithCopiedText],
  )

  const removeFromCopiedText = useCallback(
    id => {
      const newIds = idsWithCopiedText.filter(qId => qId !== id)
      copyQuestionBodies(newIds)
      setIdsWithCopiedText(newIds)
    },
    [copyQuestionBodies, idsWithCopiedText],
  )

  const toggleCopied = useCallback(
    id => (idsWithCopiedText.includes(id) ? removeFromCopiedText(id) : addToCopiedText(id)),
    [addToCopiedText, idsWithCopiedText, removeFromCopiedText],
  )

  const clearCopiedIds = useCallback(() => setIdsWithCopiedText([]), [])

  return {
    idsWithCopiedText,
    clearCopiedIds,
    toggleCopied,
  }
}
