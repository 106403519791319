import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import styled from 'styled-components'
import Input from '../../../components/input/Input/Input'
import Button from '../../../components/input/Button/Button'
import FormError from '../../../components/input/FormError/FormError'
import { required } from '../../../utils/forms/validation'

const ButtonWithMargin = styled(Button)`
  margin-bottom: 5px;
  margin-right: 10px;
`

const SaveDraftButton = styled(ButtonWithMargin)`
  background: ${props => props.theme.colors.info};
`

const ModAnswersButton = styled(ButtonWithMargin)`
  background: ${props => props.theme.colors.gold};
`

const CloseButton = styled(ButtonWithMargin)`
  background: ${props => props.theme.colors.gray};
`

const MentoringAnswerForm = ({
  answerPlainText,
  answerFormatted,
  showModAnswers,
  closeAnswerForm,
  ...props
}) => {
  const initialAnswers = useMemo(
    () => ({
      plainText: answerPlainText,
      stringifiedState: answerFormatted,
    }),
    [answerPlainText, answerFormatted],
  )

  return (
    <Form
      {...props}
      render={({ handleSubmit, submitting, submitError }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="answerFormatted"
            component={Input}
            componentClass={Input.FormattedInput}
            placeholder="Type answer here"
            validate={required}
            initialValue={initialAnswers}
          />
          <FormError submitting={submitting} error={submitError} />
          <SaveDraftButton
            solid
            type="submit"
            disabled={submitting}
            style={{ marginBottom: '5px' }}
          >
            {submitting ? 'Saving...' : 'Save Draft'}
          </SaveDraftButton>
          <ModAnswersButton solid type="button" onClick={showModAnswers}>
            Mod Answers
          </ModAnswersButton>
          <CloseButton solid type="button" onClick={closeAnswerForm}>
            Close
          </CloseButton>
        </form>
      )}
    />
  )
}

MentoringAnswerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default MentoringAnswerForm
