import React, { useEffect, useRef, useState } from 'react'

import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
  SelectionState,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import './formattedInput.css'
import styled from 'styled-components'

const Background = styled.div`
  cursor: ${props => (props.editing ? 'text' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: ${props => (!props.editing ? 'transparent' : props.theme.colors.white)};
  border-radius: 5px;
  padding: ${props => (props.editing ? '10px' : '0')};
  border: ${props =>
    !props.editing
      ? 'none'
      : `1px solid ${props => props.theme.colors[props.editing ? 'blue' : 'gray']}`};
  width: 100%;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 10px;
  min-height: ${props => (props.editing ? '200px' : 'auto')};

  ::placeholder {
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    font-size: 20px;
  }
`

const StyledButton = styled.button`
  border-radius: 0px;
  border: none;
  background: transparent;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  user-select: none;
  pointer-events: all;
`

const ClickBlockingDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: ${props => (props.editing ? 'text' : 'auto')};
`

const FormatButton = props => <StyledButton {...props} type="button" />

const ButtonRow = styled.div`
  background: white;
  margin: 20px 0px 10px 0px;
  box-shadow: 2px 2px 10px gray;
`

export const FormattedInput = ({ editing = true, plainText, stringifiedState, ...props }) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
  const inputRef = useRef('input')

  // convert plaintext or stringified state in props to editor state
  useEffect(() => {
    if (!plainText && !stringifiedState) return

    const rawState = JSON.parse(stringifiedState)

    const contentState = rawState
      ? convertFromRaw(rawState)
      : ContentState.createFromText(plainText)

    const newEditorState = EditorState.createWithContent(contentState)

    onChange(newEditorState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plainText, stringifiedState])

  const handleKeyCommand = (command, oldState) => {
    const newState = RichUtils.handleKeyCommand(oldState, command)

    if (newState) {
      onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const onChange = newState => {
    setEditorState(newState)
    props.onChange && props.onChange(JSON.stringify(convertToRaw(newState.getCurrentContent())))
  }

  const onClickFormatButton = style => e => {
    e.stopPropagation()
    toggleStyle(style)
  }

  const toggleStyle = style => onChange(RichUtils.toggleInlineStyle(editorState, style))

  const focusInput = () => {
    inputRef.current.focus()
    moveSelectionToEnd()
  }

  const moveSelectionToEnd = () => {
    const content = editorState.getCurrentContent()
    const blockMap = content.getBlockMap()
    const key = blockMap.last().getKey()
    const length = blockMap.last().getLength()
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    })

    setEditorState(EditorState.forceSelection(editorState, selection))
  }

  return (
    <>
      <Background onClick={focusInput} editing={editing}>
        <ClickBlockingDiv onClick={e => e.stopPropagation()}>
          <Editor
            {...props}
            ref={inputRef}
            readOnly={!editing}
            spellCheck={editing}
            editorState={editorState}
            onChange={onChange}
            handleKeyCommand={handleKeyCommand}
          />
        </ClickBlockingDiv>
        {editing && <FullButtonRow onClickFormatButton={onClickFormatButton} />}
      </Background>
    </>
  )
}

const FullButtonRow = ({ onClickFormatButton }) => (
  <ButtonRow onClick={e => e.stopPropagation()}>
    <FormatButton onClick={onClickFormatButton('BOLD')}>
      <b>B</b>
    </FormatButton>
    <FormatButton onClick={onClickFormatButton('ITALIC')}>
      <i>I</i>
    </FormatButton>
    <FormatButton onClick={onClickFormatButton('UNDERLINE')}>
      <u>U</u>
    </FormatButton>
  </ButtonRow>
)

export const stringifiedStateToPlainText = stringifiedState =>
  convertFromRaw(JSON.parse(stringifiedState)).getPlainText() || ''
