import React from 'react'
import PropTypes from 'prop-types'
import H3 from '../../../../components/typography/H3/H3'
import { Table, TH } from '../../../../components/lists/Table'
import { ReferralsTrackingRow } from './ReferralsTrackingRow'

export const ReferralsTrackingTable = ({ referrals, emptyMsg, heading }) => (
  <>
    <H3>{heading}</H3>
    <Table
      headings={
        <tr>
          <TH>Referee Name</TH>
          <TH>Referee Email</TH>
          <TH>Converted</TH>
        </tr>
      }
      items={Object.entries(referrals)}
      emptyMsg={emptyMsg}
    >
      {refereeData => {
        const { firstName, email, converted, id: userId } = refereeData[1]
        return (
          <ReferralsTrackingRow
            key={userId}
            userId={userId}
            email={email}
            firstName={firstName}
            converted={converted}
          />
        )
      }}
    </Table>
  </>
)

ReferralsTrackingTable.propTypes = {
  referrals: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      converted: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  heading: PropTypes.string.isRequired,
  emptyMsg: PropTypes.string.isRequired,
}
