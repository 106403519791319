import React from 'react'
import format from 'date-fns/format'
import PropTypes from 'prop-types'

import { Table, TD, TH } from '../../../../components/lists/Table'

const SignUpsTable = ({ signUps, emptyMsg }) => (
  <Table
    headings={
      <tr>
        <TH>First Name</TH>
        <TH>Last Name</TH>
        <TH>Email</TH>
        <TH>Phone</TH>
        <TH>Country</TH>
        <TH>City</TH>
        <TH>Date</TH>
        <TH>Website URL</TH>
      </tr>
    }
    items={signUps}
    emptyMsg={emptyMsg}
  >
    {({ id, firstName, lastName, email, phone, country, city, date }) => (
      <tr key={id}>
        <TD>{firstName}</TD>
        <TD>{lastName}</TD>
        <TD>{email}</TD>
        <TD>{phone}</TD>
        <TD>{country}</TD>
        <TD>{city}</TD>
        <TD>{format(date, 'PPP p')}</TD>
        <TD>{'-'}</TD>
      </tr>
    )}
  </Table>
)

SignUpsTable.propTypes = {
  signUps: PropTypes.array.isRequired,
  emptyMsg: PropTypes.string.isRequired,
}

export default SignUpsTable
