// const exampleHeadings = [{ text: 'HeadingA', key: 'keyA' }, { text: 'HeadingB', key: 'keyB' }]
// const exampleRows = [
//   { keyA: 'row1ValueA', keyB: 'row1ValueB' },
//   { keyA: 'row2ValueA', keyB: 'row2ValueB' },
// ]

export const createCsvData = (headings = [], rows = []) => {
  const data = [headings.map((h) => h.text)]

  rows.forEach((r) => data.push(headings.map((h) => r[h.key])))

  return data
}

export const CSV_HEADINGS = [
  { text: 'First Name', key: 'firstName' },
  { text: 'Email', key: 'email' },
  { text: 'Phone', key: 'phone' },
  { text: 'URL', key: 'source' },
  { text: 'UTM Source', key: 'utmSource' },
  { text: 'UTM Medium', key: 'utmMedium' },
  { text: 'UTM Name', key: 'utmCampaign' },
  { text: 'UTM Term', key: 'utmTerm' },
  { text: 'UTM Content', key: 'utmContent' },
  { text: 'Program', key: 'program' },
  { text: 'Program Status', key: 'programStatus' },
  { text: 'Program Expiry', key: 'programExpiryDate' },
  { text: 'Program Expiry Day', key: 'programExpiryDay' },
  { text: 'Program Expiry Month', key: 'programExpiryMonth' },
  { text: 'Program Expiry Year', key: 'programExpiryYear' },
  { text: 'Mentoring Expiry', key: 'mentoringExpiryDate' },
  { text: 'Mentoring Expiry Day', key: 'mentoringExpiryDay' },
  { text: 'Mentoring Expiry Month', key: 'mentoringExpiryMonth' },
  { text: 'Mentoring Expiry Year', key: 'mentoringExpiryYear' },
  { text: 'Has Password', key: 'hasPassword' },
  { text: 'Live Session', key: 'liveSession' },
  { text: 'Purchase Date', key: 'paymentDate' },
  { text: 'Accessed Sessions', key: 'accessedSessions' },
  { text: 'Accessed PDFs', key: 'accessedWrittenContent' },
  { text: 'Telesales Assignee', key: 'telesalesSource' },
  { text: 'Promo Code', key: 'promoCode' },
  { text: 'Country', key: 'country' },
  { text: 'City', key: 'city' },
  { text: 'Time Zone', key: 'timeZone' },
]
