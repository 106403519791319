/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Nav from '../../../components/navigation/Nav/Nav'
import NavItem from '../../../components/navigation/NavItem/NavItem'
import { useCurrentUser } from '../../../containers/UserContext/UserContext'
import { colors, zIndex } from '../../../theme'

const HEADER_HEIGHT = 70

const FixedHeader = styled.header`
  border-bottom: 1px solid ${colors.grayLight};
  background: ${colors.white};
  z-index: ${zIndex.header};
  position: fixed;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
`

const InlineSpacer = styled.div`
  height: ${HEADER_HEIGHT}px;
`

const DesktopMenu = styled.div`
  align-items: center;
  display: flex;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`

const Logo = styled.img`
  height: ${HEADER_HEIGHT - 4}px;
  width: auto;
`

const navs = [
  { label: 'Mentoring', to: '/mentoring' },
  { label: 'ES Blog', to: '/es-blog' },
  { label: 'ES Emails', to: '/es-emails' },
  { label: 'Leads', to: '/leads' },
  { label: 'Paid Users', to: '/paid-users' },
  { label: 'Newsletter', to: '/newsletter-sign-ups' },
  { label: 'Payments', to: '/payments' },
  { label: 'Live Sessions', to: '/live-sessions' },
  { label: 'Mailing Lists', to: '/mailing-list-configs' },
  { label: 'Feedback', to: '/feedback' },
  { label: 'Testimonials', to: '/testimonials' },
  { label: 'Admin Users', to: '/admins' },
  { label: 'Advice CMS', to: '/advice' },
  { label: 'Advice Tracking', to: '/advice-tracking' },
]

const AdminHeader = () => {
  const { user, signOut } = useCurrentUser()

  return (
    <>
      <FixedHeader>
        <DesktopMenu>
          <Link to="/">
            <Logo src="/img/brand/es-logo.png" alt="Entrepreneur Seminar" />
          </Link>
          {user && (
            <Nav style={{ marginLeft: 'auto' }}>
              {navs.map(({ label, to }) => (
                <NavItem key={label}>
                  <Link to={to}>{label}</Link>
                </NavItem>
              ))}
              <NavItem>
                <a onClick={signOut}>Sign out</a>
              </NavItem>
            </Nav>
          )}
        </DesktopMenu>
      </FixedHeader>
      <InlineSpacer />
    </>
  )
}
AdminHeader.height = HEADER_HEIGHT

export default AdminHeader
