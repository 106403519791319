import React from 'react'
import PropTypes from 'prop-types'
import { Table, TH } from '../../../../components/lists/Table'
import AdviceTrackingRow from './AdviceTrackingRow/AdviceTrackingRow'
import H3 from '../../../../components/typography/H3/H3'

const AdviceTrackingTable = ({ userId, adviceViewingData }) => (
  <>
    <H3>Advice Tracking</H3>
    <Table
      headings={
        <tr>
          <TH>Title</TH>
          <TH>% Viewed</TH>
        </tr>
      }
      items={Object.entries(adviceViewingData)}
      emptyMsg={''}
    >
      {article => {
        const title = article[0]
        const { allViewPercentagesByUser, allUsersDataByWatchNo } = article[1]
        return (
          <AdviceTrackingRow
            key={title}
            articleTitle={title}
            viewPercentages={allViewPercentagesByUser && allViewPercentagesByUser[userId]}
            watchData={allUsersDataByWatchNo && allUsersDataByWatchNo[userId]}
          />
        )
      }}
    </Table>
  </>
)

AdviceTrackingTable.propTypes = {
  emptyMsg: PropTypes.string.isRequired,
}

export default AdviceTrackingTable
