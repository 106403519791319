import React from 'react'

import { CurrentUser } from '../../../containers/UserContext/UserContext'

import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import ModalContent from '../../../components/layout/ModalContent/ModalContent'
import FormBorder from '../../../components/input/FormBorder/FormBorder'
import SignInForm from '../../../components/auth/SignInForm/SignInForm'

const SignInPage = () => (
  <CurrentUser>
    {({ signIn }) => (
      <AdminPage>
        <ModalContent.Heading noMargin>Entrepreneur Seminar</ModalContent.Heading>
        <ModalContent.SubHeading noMargin>Sign in for admin access</ModalContent.SubHeading>
        <FormBorder maxWidth="440px">
          <SignInForm onSubmit={signIn} />
        </FormBorder>
      </AdminPage>
    )}
  </CurrentUser>
)

export default SignInPage
