import React from 'react'
import styled from 'styled-components'
import Spinner from '../Spinner/Spinner'
import Section from '../../layout/Section/Section'
import P from '../../typography/P/P'

const PaddedSection = styled(Section)`
  padding-top: 3em;
  min-height: 520px;
`

const LoadingSection = ({ loadingMessage, ...props }) => (
  <PaddedSection center {...props}>
    <Spinner size="lg" />
    {loadingMessage && <P style={{ marginTop: '20px' }}>{loadingMessage}</P>}
  </PaddedSection>
)

export default LoadingSection
