import { useState } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

const ROUTE = '/feedback/questions'

export const useFeedback = () => {
  const [state, setState] = useState({ questions: [], loading: true, error: null })

  const updateQuestionFromResponse = (newQuestion) => {
    setState((prev) => ({
      ...prev,
      questions: prev.questions.map((oldQuestion) => (oldQuestion.id === newQuestion.id ? newQuestion : oldQuestion)),
    }))
  }

  const handleError = (error) => setState((prev) => ({ ...prev, error, loading: false }))

  const getAllQuestions = () =>
    esAdminAPI('get', ROUTE)
      .then((questions) => setState((prev) => ({ ...prev, questions, loading: false })))
      .catch(handleError)

  const addQuestion = (body) =>
    esAdminAPI('post', ROUTE, body)
      .then((newQuestion) =>
        setState((prev) => ({
          ...prev,
          questions: [...prev.questions, newQuestion],
          loading: false,
          error: null,
        })),
      )
      .catch(handleError)

  const updateQuestion = (id, questionBody) =>
    esAdminAPI('put', `${ROUTE}/${id}`, { body: questionBody }).then(updateQuestionFromResponse).catch(handleError)

  const publishQuestion = (id) =>
    esAdminAPI('put', `${ROUTE}/publish/${id}`).then(updateQuestionFromResponse).catch(handleError)

  const unpublishQuestion = (id) =>
    esAdminAPI('put', `${ROUTE}/unpublish/${id}`).then(updateQuestionFromResponse).catch(handleError)

  return {
    ...state,
    addQuestion,
    getAllQuestions,
    updateQuestion,
    publishQuestion,
    unpublishQuestion,
  }
}
