export const colors = {
  primary: '#46AECF',
  secondary: '#0785AB',
  black: '#000',
  grayDarker: '#333',
  grayDark: '#555',
  gray: '#999',
  grayLight: '#e5e5e5',
  offWhite: '#f2f2f2',
  white: '#fff',
  error: '#b9161f',
  info: '#22abab',
  green: '#00A86B',
  orangeDark: '#FF8C00',
  gold: '#c5a511',
  amber: '#FFBF00',
  errorRed: '#FF0000',
  orangeYellow: '#FF8300',
  brightGreen: '#18991E',
}

export const fonts = {
  primary: "'Source Sans Pro', sans-serif",
  secondary: "'Lato', sans-serif",
  tertiary: 'Industry, sans-serif',
}

// If weights are added here the font type kits (Adobe, Google etc.) must
// be updated to include the new weights.
export const fontWeights = {
  thin: 200,
  normal: 400, // italic variant included
  demiBold: 600, // Industry font-family only
  bold: 700,
}

export const themes = {
  light: { background: colors.white, color: colors.black },
  dark: { background: colors.black, color: colors.white },
  gray: { background: colors.grayLight, color: colors.grayDark },
  primary: { background: colors.secondary, color: colors.white },
}

export const contentWidth = '1200px'

export const zIndex = {
  floatingButtons: 5,
  cmsPagesMenu: 10,
  mobileMenu: 10,
  mobileNavBar: 15,
  header: 20,
  modalBackdrop: 50,
  modal: 51,
}

export { mediaMinWidth } from './MediaQuery/mediaCss'
