/**
 * The final-form submit `handleSubmit` function returns a Promise when the
 * submission is started, but `undefined` when there are validation errors
 * blocking the submission. This makes for verbose promise checking/chaining.
 *
 * Instead, pass the `handleSubmit` and `onSuccess` callback to this function to
 * hide the return value checks.
 *
 *     <Form
 *       onSubmit={this.props.onSubmit}
 *       render={({ form, handleSubmit }) => (
 *         <form
 *           onSubmit={withSuccessHandler(handleSubmit, () => {
 *             form.reset()
 *             // etc.
 *           })}
 *         >
 */
export const withSuccessHandler = (handleSubmit, onSuccess) => ev => {
  const submission = handleSubmit(ev)
  submission && submission.then(submitErrors => !submitErrors && onSuccess())
}
