import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import H3 from '../../../components/typography/H3/H3'
import { useMentoring } from '../../../containers/AdminApi/MentoringApi'
import { useUserList } from '../../../containers/AdminApi/UsersListApi'
import { useCurrentUser } from '../../../containers/UserContext/UserContext'
import { QuestionAnswerBlock } from './QuestionAnswerBlock'
import { InfoText } from './components/InfoText'
import { MentoringSection } from './components/Section'
import { useCopyFromList } from './useCopyFromList'

const SectionTitle = styled(H3)`
  padding-top: 30px;
  border-top: 1px solid black;
  margin-bottom: 0px;
  font-size: 25px;
  padding-left: 10px;
`

const DashboardLink = styled(Link)`
  padding: 10px;
  display: block;
  width: 300px;
  margin-bottom: 10px;
`

const PageHeading = styled(H3)`
  color: black;
  padding-left: 10px;
`

const DashboardButton = () => <DashboardLink to="/mentoring">Back to Dashboard</DashboardLink>

const UsersQuestions = ({
  match: {
    params: { userId, questionId },
  },
}) => {
  const {
    mentoring: { questions, loading: mentoringLoading, modAnswers },
    ...handlers
  } = useMentoring()

  const usersQuestions = questions.filter((q) => q.userId === parseInt(userId))
  const { idsWithCopiedText, toggleCopied, clearCopiedIds } = useCopyFromList(
    usersQuestions.map(({ id, answerPlainText }) => ({ id, text: answerPlainText })),
  )

  const { user: currentUser } = useCurrentUser()
  const {
    user: { user, loading: userLoading },
  } = useUserList({ userId })

  const publishingEnabled =
    process.env.REACT_APP_MENTORING_PUBLISHING_ENABLED_FOR_ALL === 'true' ||
    [
      'martin1warner@gmail.com',
      'martin@entrepreneurseminar.com',
      'anthony@entrepreneurseminar.com',
    ].includes(currentUser.email)

  const toQABlock = useCallback(
    (question) => (
      <QuestionAnswerBlock
        key={question.id}
        {...question}
        questionId={question.id}
        publishingEnabled={publishingEnabled}
        handlers={handlers}
        clearCopiedIds={clearCopiedIds}
        toggleCopied={toggleCopied}
        isCopied={idsWithCopiedText.includes(question.id)}
        setTags={(tags) => handlers.setTags(question.id, tags)}
        answerAuthor={question.answerAuthor}
        modAnswers={modAnswers}
      />
    ),
    [clearCopiedIds, handlers, idsWithCopiedText, modAnswers, publishingEnabled, toggleCopied],
  )

  if (mentoringLoading || userLoading) {
    return (
      <>
        <DashboardButton />
        <LoadingSection />
      </>
    )
  }

  const currentQuestion = usersQuestions.find((q) => q.id === parseInt(questionId))
  const excludingCurrent = usersQuestions.filter((q) => q.id !== currentQuestion.id)

  const draftAnsweredQs = excludingCurrent.filter((q) => !q.published && !!q.answer)
  const newQs = excludingCurrent.filter((q) => !q.published && !q.answer)
  const answeredQs = excludingCurrent.filter((q) => q.published)

  const { userFirstName } = currentQuestion

  const { availableContent: { starterProgram, acceleratorProgram, foundationProgram } = {} } = user

  return (
    <AdminPage>
      <DashboardButton />
      <PageHeading>Questions from {userFirstName}</PageHeading>
      <SectionTitle>User Details</SectionTitle>
      <MentoringSection>
        <InfoText>
          <b>Name:</b> {userFirstName && userFirstName}
        </InfoText>
        <InfoText>
          <b>Program:</b>{' '}
          {acceleratorProgram
            ? 'Accelerator'
            : foundationProgram
            ? 'Foundation'
            : starterProgram
            ? 'Starter'
            : 'None (Please report to dev team if you see this)'}
        </InfoText>
      </MentoringSection>

      <SectionTitle>Current question</SectionTitle>
      {[currentQuestion].map(toQABlock)}
      {!!draftAnsweredQs.length && (
        <>
          <SectionTitle>Questions with draft answers</SectionTitle>
          {draftAnsweredQs.map(toQABlock)}
        </>
      )}
      {!!newQs.length && (
        <>
          <SectionTitle>New questions</SectionTitle>
          {newQs.map(toQABlock)}
        </>
      )}
      {!!answeredQs.length && (
        <>
          <SectionTitle>Published answers</SectionTitle>
          {answeredQs.map(toQABlock)}
        </>
      )}
    </AdminPage>
  )
}

export default UsersQuestions
