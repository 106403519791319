import React from 'react'
import styled from 'styled-components'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { H2 } from '../../../components/typography'
import { usePublicMessages } from '../../../containers/AdminApi/PublicMessagesApi'
import { MessageList } from './MessageList'
import { WriteNewMessage } from './WriteNewMessage'
import { oderByPublishDateDesc } from './helpers'

const Page = styled.div`
  max-width: 'auto';
`

const BlogLinkWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const BlogLink = styled.a`
  margin: 0 auto;
`

const BlogPage = () => {
  const {
    publicMessages: { error, loading, publicMessages },
    saveMessage,
    publishMessage,
    unpublishMessage,
  } = usePublicMessages('blog')

  return (
    <Page>
      {loading ? (
        <LoadingSection />
      ) : (
        <ErrorSection error={error}>
          <H2>Martin's Blog</H2>
          <WriteNewMessage
            disableNoProgram
            disableMentoring
            initialValues={{ targets: ['starter', 'foundation', 'accelerator', 'subscription'] }}
            saveMessage={saveMessage}
            typeMsg="Blog Post"
          />
          <BlogLinkWrapper>
            <BlogLink href={`${process.env.REACT_APP_APP_HOST}/course`}>
              View in customer portal
            </BlogLink>
          </BlogLinkWrapper>
          <MessageList
            disableNoProgram
            disableMentoring
            saveMessage={saveMessage}
            publishMessage={publishMessage}
            unpublishMessage={unpublishMessage}
            title="Unpublished Messages"
            messages={publicMessages.filter((m) => !m.published)}
          />
          <MessageList
            disableNoProgram
            disableMentoring
            saveMessage={saveMessage}
            publishMessage={publishMessage}
            unpublishMessage={unpublishMessage}
            title="Published Messages"
            messages={oderByPublishDateDesc(publicMessages.filter((m) => m.published))}
          />
        </ErrorSection>
      )}
    </Page>
  )
}

export default BlogPage
