import { useMutation, useQueryClient } from 'react-query'
import esAdminAPI from '../../services/esAPI/esAdminAPI'
import { MAILING_LIST_ROUTE } from './useMailingListConfigsQuery'

export const useMailingListConfigsUpdateMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    MAILING_LIST_ROUTE,
    ({ id, ...vals }) => esAdminAPI('put', `${MAILING_LIST_ROUTE}/${id}`, { vals }),
    {
      onSuccess: (updatedConfig) => {
        const allConfigs = queryClient.getQueryData(MAILING_LIST_ROUTE)
        const updatedIndex = allConfigs.findIndex((config) => config.id === updatedConfig.id)
        allConfigs[updatedIndex] = updatedConfig

        queryClient.setQueryData(MAILING_LIST_ROUTE, allConfigs)
      },
    },
  )
}
