import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TD from '../../../../components/lists/Table/TD'

export const ReferralsTrackingRow = ({ firstName, email, converted, userId }) => (
  <tr>
    <TD>
      <Link
        to={{
          pathname: `/tracking/${firstName}/_/${userId}`,
        }}
      >
        {firstName}
      </Link>
    </TD>
    <TD>{email}</TD>
    <TD>{converted ? 'Yes' : 'No'}</TD>
  </tr>
)

ReferralsTrackingRow.propTypes = {
  userId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  converted: PropTypes.bool.isRequired,
}
