import React from 'react'
import PropTypes from 'prop-types'
import { Table } from '../../../components/lists/Table'
import { ConfigRow } from './ConfigRow'

export const MailingListConfigsTable = ({ addConfig, configs, deleteConfig, updateConfig }) => {
  return !configs ? null : (
    <Table headings={['Route', 'Mailing List', 'GB', 'US', '']} items={configs} emptyMsg="No Mailing Lists available">
      {(config) => (
        <ConfigRow
          addConfig={addConfig}
          updateConfig={(newVals) => updateConfig({ id: config.id, ...newVals })}
          deleteConfig={() => {
            const confirmed = window.confirm(`Really delete ${config.slug}?`)
            confirmed && deleteConfig(config.id)
          }}
          key={config.id}
          {...config}
        />
      )}
    </Table>
  )
}

MailingListConfigsTable.propTypes = {
  addConfig: PropTypes.func.isRequired,
  configs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
      mailingListId: PropTypes.number.isRequired,
      GB: PropTypes.bool,
      US: PropTypes.bool,
    }),
  ),
  deleteConfig: PropTypes.func.isRequired,
  updateConfig: PropTypes.func.isRequired,
}
