import React, { useState } from 'react'
import UserForm from '../../../components/auth/UserForm/UserForm'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import TrackingDataApi from '../../../containers/AdminApi/TrackingDataApi'
import { useUserList } from '../../../containers/AdminApi/UsersListApi'
import LeadsTable from './LeadsTable/LeadsTable'

const LEAD_USER = 'lead'
const INTRO_SESSION_ID = process.env.NODE_ENV === 'development' ? 1 : 48

const LeadsPage = () => {
  const [add, setAdd] = useState(false)
  const {
    userList: { users, loading, error },
    addUser,
    updateUser,
    archiveUser,
    loadUnverifiedPhones,
    getAllUsers,
  } = useUserList({ userType: LEAD_USER })
  return (
    <AdminPage
      fullWidth
      heading="Leads"
      actions={[{ title: `${add ? 'Close' : 'Add a Lead'}`, action: () => setAdd(!add) }]}
      loading={loading}
      error={error}
    >
      {add && <UserForm newUser onSubmit={addUser} />}
      <TrackingDataApi sessionId={INTRO_SESSION_ID}>
        {({ sessionViewingData }) => (
          <LeadsTable
            users={users.filter((user) => !user.archived)}
            updateUser={updateUser}
            archiveUser={archiveUser}
            loadUnverifiedPhones={loadUnverifiedPhones}
            getAllUsers={getAllUsers}
            allUserWatchData={sessionViewingData}
            emptyMsg="There are no leads yet"
          />
        )}
      </TrackingDataApi>
    </AdminPage>
  )
}

export default LeadsPage
