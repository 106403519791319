import React, { useEffect } from 'react'

import ErrorSection from '../../../components/errors/Section/ErrorSection'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { useFeedback } from '../../../containers/AdminApi/FeedbackApi'
import { AddQuestionForm } from './AddQuestionForm'
import { QuestionsList } from './QuestionsList'

const FeedbacksPage = () => {
  const {
    questions,
    addQuestion,
    getAllQuestions,
    publishQuestion,
    unpublishQuestion,
    updateQuestion,
    loading,
    error,
  } = useFeedback()

  useEffect(() => {
    getAllQuestions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdminPage heading="Feedback">
      {loading ? (
        <LoadingSection />
      ) : (
        <ErrorSection error={error}>
          <AddQuestionForm addQuestion={addQuestion} />

          <QuestionsList
            questions={questions}
            publishQuestion={publishQuestion}
            unpublishQuestion={unpublishQuestion}
            updateQuestion={updateQuestion}
          />
        </ErrorSection>
      )}
    </AdminPage>
  )
}

export default FeedbacksPage
