import React from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'

import FormError from '../../../../components/input/FormError/FormError'
import FormInlineButton from '../../../../components/input/FormInline/FormInlineButton'
import FormInlineInput from '../../../../components/input/FormInline/FormInlineInput'
import FormInlineRow from '../../../../components/input/FormInline/FormInlineRow'

import { minLength2Required, password, requiredEmail } from '../../../../utils/forms/validation'
import { withSuccessHandler } from '../../../../utils/forms/withHandlers'

const AdminUsersForm = props => {
  const firstInput = React.createRef()
  return (
    <Form
      {...props}
      render={({ form, handleSubmit, submitting, submitError }) => (
        <form
          style={{ marginBottom: '0.5em' }}
          onSubmit={withSuccessHandler(handleSubmit, () => {
            // firstInput.current.focus()
            form.reset()
          })}
        >
          <FormInlineRow>
            <Field
              name="firstName"
              placeholder="First Name"
              component={FormInlineInput}
              validate={minLength2Required}
              errorOnlyIfDirty
              inputRef={firstInput}
            />
            <Field
              name="lastName"
              placeholder="Last Name"
              component={FormInlineInput}
              validate={minLength2Required}
            />
            <Field
              name="email"
              placeholder="Email"
              component={FormInlineInput}
              validate={requiredEmail}
            />
            <Field
              name="password"
              placeholder="Password"
              component={FormInlineInput}
              validate={password}
            />
            <div>
              <FormInlineButton type="submit" disabled={submitting}>
                {submitting ? 'Saving...' : 'Add an Admin'}
              </FormInlineButton>
            </div>
          </FormInlineRow>
          <FormError submitting={submitting} error={submitError} />
        </form>
      )}
    />
  )
}

AdminUsersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default AdminUsersForm
