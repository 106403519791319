import React from 'react'
import compose from 'lodash/fp/compose'
import groupBy from 'lodash/fp/groupBy'
import orderBy from 'lodash/fp/orderBy'
import {
  useMailingListConfigsAddMutation,
  useMailingListConfigsDeleteMutation,
  useMailingListConfigsQuery,
  useMailingListConfigsUpdateMutation,
} from '../../../api/mailingListConfigs'
import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import { AddMailingListForm } from './AddMailingListForm'
import { MailingListConfigsTable } from './MailingListConfigsTable'

const orderByScheduledDateAsc = orderBy([(list) => list.slug.toLowerCase()], ['asc'])
const groupConfigs = compose(groupBy('type'), orderByScheduledDateAsc)

export const MailingListConfigsPage = () => {
  const { data: mailingListConfigs = [], isLoading, error } = useMailingListConfigsQuery()
  const { mutate: addConfig } = useMailingListConfigsAddMutation()
  const { mutate: updateConfig } = useMailingListConfigsUpdateMutation()
  const { mutate: deleteConfig } = useMailingListConfigsDeleteMutation()

  const configsByType = groupConfigs(mailingListConfigs)

  return (
    <AdminPage loading={isLoading} error={error}>
      <h2>Leads Mailing Lists</h2>
      <AddMailingListForm onSubmit={addConfig} />
      <MailingListConfigsTable
        configs={configsByType.lead}
        addConfig={(values) => addConfig({ ...values, type: 'lead' })}
        updateConfig={updateConfig}
        deleteConfig={deleteConfig}
      />

      <h2>Paid Mailing Lists</h2>
      <MailingListConfigsTable
        configs={configsByType.paid}
        addConfig={(values) => addConfig({ ...values, type: 'paid' })}
        updateConfig={updateConfig}
        deleteConfig={deleteConfig}
      />
    </AdminPage>
  )
}
