import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import Button from '../../../../components/input/Button/Button'
import { Table, TH } from '../../../../components/lists/Table'
import { DateFilterCell } from '../../../../components/lists/Table/DateFilterCell'
import { DropDownFilterCell } from '../../../../components/lists/Table/DropdownFilterCell'
import { TextFilterCell } from '../../../../components/lists/Table/TextFilterCell'
import { TimeFilterCell } from '../../../../components/lists/Table/TimeFilterCell'
import { useAdviceTracking, useSessionTracking } from '../../../../containers/AdminApi/TrackingDataApi'
import { useFilterUsers } from '../../../../hooks/useFilterUsers'
import { onlyUnique } from '../../../../utils/arrays'
import { createCsvData } from '../../../../utils/csv'
import LeadRow from './LeadRow/LeadRow'

const HEADINGS = [
  { text: 'Name', key: 'firstName' },
  { text: 'Email', key: 'email' },
  { text: 'Phone', key: 'phone' },
  { text: 'Verified Phone', key: 'verifiedPhone' },
  { text: 'Masterclass Date', key: 'masterclass' },
  { text: 'URL', key: 'source' },
  { text: 'UTM Source', key: 'utmSource' },
  { text: 'UTM Medium', key: 'utmMedium' },
  { text: 'UTM Name', key: 'utmCampaign' },
  { text: 'UTM Term', key: 'utmTerm' },
  { text: 'UTM Content', key: 'utmContent' },
  { text: 'Telesales Source', key: 'telesalesSource' },
  { text: 'Country', key: 'country' },
  { text: 'City', key: 'city' },
  { text: 'Time Zone', key: 'timeZone' },
  { text: 'Initial Sign-up Date', key: 'initDate' },
  { text: 'Initial Sign-up Time', key: 'initTime' },
  { text: 'Updated Sign-up Date', key: 'updDate' },
  { text: 'Updated Sign-up Time', key: 'updTime' },
]

// Exclude headings array
const unverifiedTableHeading = ['masterclass', 'verifiedPhone', 'telesalesSource', 'initTime', 'updDate', 'updTime']

const CSVButton = styled(CSVLink)`
  margin: 0 10px;
  color: black;
  text-decoration: underline;
`

const UnverifiedUsers = styled.a`
  margin: 0;
  color: black;
  font-size: 18px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`

const LeadsTable = ({
  loadUnverifiedPhones,
  getAllUsers,
  archiveUser,
  emptyMsg,
  updateUser,
  users,
  allUserWatchData: { allUsersDataByWatchNo, allViewPercentagesByUser },
}) => {
  const [unverifiedPhonesTable, setUnverifiedPhonesTable] = useState(false)
  const adviceTracking = useAdviceTracking()
  const { coreSessionTracking, sessionTrackingHandlers } = useSessionTracking()
  const { filters, clearFilters, setSingleFilter, filtersApplied, filteredUsers } = useFilterUsers(users)

  const handleUnverifiedPhonesData = () => {
    if (!unverifiedPhonesTable) {
      loadUnverifiedPhones()
      setUnverifiedPhonesTable(true)
    } else {
      getAllUsers('lead')
      setUnverifiedPhonesTable(false)
    }
  }

  useEffect(() => {
    sessionTrackingHandlers.allCoreAndBonus()
  }, [])

  return (
    <>
      <Button style={{ marginBottom: 20, background: filtersApplied ? 'red' : 'gray' }} onClick={clearFilters}>
        Reset Filters
      </Button>
      <CSVButton
        filename={`leads${filters.country ? '-' + filters.country : ''}.csv`}
        data={createCsvData(
          HEADINGS.filter((h) => h.key),
          filteredUsers.map((user) => ({ ...user, date: format(user.date, 'PPP p') })),
        )}
      >
        Download CSV
      </CSVButton>

      <br />
      <UnverifiedUsers onClick={handleUnverifiedPhonesData}>
        {!unverifiedPhonesTable ? 'Show Pre Leads' : 'Show Leads'}
      </UnverifiedUsers>

      <Table
        headings={
          <tr>
            {HEADINGS.map((h) =>
              unverifiedTableHeading.includes(h.key) && unverifiedPhonesTable ? null : <TH key={h.text}>{h.text}</TH>,
            )}

            {!unverifiedPhonesTable && (
              <>
                <TH />
                <TH />
              </>
            )}
          </tr>
        }
        filters={
          <tr style={{ alignItems: 'center' }}>
            <TextFilterCell value={filters.name} setFilter={(val) => setSingleFilter('name', val)} />
            <TextFilterCell value={filters.email} setFilter={(val) => setSingleFilter('email', val)} />
            <td />
            {!unverifiedPhonesTable && <td />}
            {!unverifiedPhonesTable && <td />}
            <DropDownFilterCell
              value={filters.source}
              options={users.map((u) => u.source).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('source', val)}
            />
            <DropDownFilterCell
              value={filters.utmSource}
              options={users.map((u) => u.utmSource).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmSource', val)}
            />
            <DropDownFilterCell
              value={filters.utmMedium}
              options={users.map((u) => u.utmMedium).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmMedium', val)}
            />
            <DropDownFilterCell
              value={filters.utmCampaign}
              options={users.map((u) => u.utmCampaign).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmCampaign', val)}
            />
            <DropDownFilterCell
              value={filters.utmTerm}
              options={users.map((u) => u.utmTerm).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmTerm', val)}
            />
            <DropDownFilterCell
              value={filters.utmContent}
              options={users.map((u) => u.utmContent).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('utmContent', val)}
            />
            {!unverifiedPhonesTable && (
              <DropDownFilterCell
                value={filters.telesalesSource}
                options={users.map((u) => u.telesalesSource).filter(onlyUnique)}
                setFilter={(val) => setSingleFilter('telesalesSource', val)}
              />
            )}
            <DropDownFilterCell
              value={filters.country}
              options={users.map((u) => u.country).filter(onlyUnique)}
              setFilter={(val) => setSingleFilter('country', val)}
            />
            <td />
            <td />
            <DateFilterCell
              from={filters.fromDate}
              to={filters.toDate}
              setFromDate={(date) => setSingleFilter('fromDate', date)}
              setToDate={(date) => setSingleFilter('toDate', date)}
            />
            {!unverifiedPhonesTable && (
              <>
                <TimeFilterCell
                  from={filters.fromTime}
                  to={filters.toTime}
                  setFromTime={(time) => setSingleFilter('fromTime', time)}
                  setToTime={(time) => setSingleFilter('toTime', time)}
                />

                <DateFilterCell
                  from={filters.fromUpdatedDate}
                  to={filters.toUpdatedDate}
                  setFromDate={(updatedDate) => setSingleFilter('fromUpdatedDate', updatedDate)}
                  setToDate={(updatedDate) => setSingleFilter('toUpdatedDate', updatedDate)}
                />
                <TimeFilterCell
                  from={filters.fromUpdatedTime}
                  to={filters.toUpdatedTime}
                  setFromTime={(updatedTime) => setSingleFilter('fromUpdatedTime', updatedTime)}
                  setToTime={(updatedTime) => setSingleFilter('toUpdatedTime', updatedTime)}
                />
                <td />
                <td />
              </>
            )}
          </tr>
        }
        items={filteredUsers}
        emptyMsg={emptyMsg}
      >
        {(user) => {
          const individualWatchData = allUsersDataByWatchNo && allUsersDataByWatchNo[user.id]
          const individualViewPercentages = allViewPercentagesByUser && allViewPercentagesByUser[user.id]
          return (
            <LeadRow
              key={user.id}
              user={user}
              onSave={updateUser}
              remove={archiveUser}
              watchData={individualWatchData}
              viewPercentages={individualViewPercentages}
              adviceTracking={adviceTracking}
              coreSessionTracking={coreSessionTracking}
              isUnverifiedPhonesData={unverifiedPhonesTable}
            />
          )
        }}
      </Table>
    </>
  )
}

LeadsTable.propTypes = {
  users: PropTypes.array.isRequired,
  emptyMsg: PropTypes.string.isRequired,
}

export default LeadsTable
