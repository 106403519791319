import styled from 'styled-components'
import PropTypes from 'prop-types'

/**
 * A section heading which will render the `h3` HTML tag.
 */
const H3 = styled.h3`
  margin: 0 0 ${props => (props.noMargin ? '0' : '0.4em')};
  font-size: 30px;
  font-weight: ${props => props.theme.fontWeights.bold};
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.grayDark)};
`

H3.propTypes = {
  noMargin: PropTypes.bool,
  color: PropTypes.string,
}

/** @component */
export default H3
