import styled from 'styled-components'
import Input from '../Input/Input'

const FormInlineInput = styled(Input)`
  flex-shrink: ${props => props.flexShrink || 1};
  margin-right: 0.6em;
  &:last-child {
    margin-right: 0;
  }
`

FormInlineInput.DateTimePicker = styled(Input.DateTimePicker)`
  padding: ${props => (props.value ? '9px 10px' : '5px 7px 6px')};
  flex-shrink: ${props => props.flexShrink || 1};
`

FormInlineInput.Select = styled(Input.Select)`
  padding: ${props => (props.value ? '9px 10px' : '5px 7px 6px')};
  flex-shrink: ${props => props.flexShrink || 1};
`

/** @component */
export default FormInlineInput
