import { format, utcToZonedTime } from 'date-fns-tz'
import enUS from 'date-fns/locale/en-US'

enUS.code = 'en-US'
const enGB = { ...enUS, code: 'en-GB' }

export const formatZonedTime = pattern => (date, timeZone) =>
  format(utcToZonedTime(date, timeZone), pattern, {
    timeZone,
    locale: timeZone.indexOf('Europe') > -1 ? enGB : enUS,
  })
