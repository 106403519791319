import React, { useState } from 'react'
import styled from 'styled-components'

import AdviceArticleForm from './AdviceArticleForm/AdviceArticleForm'
import { colors, fonts } from '../../../theme'
import FormBorder from '../../../components/input/FormBorder/FormBorder'
import H3Secondary from '../../../components/typography/H4Secondary/H3Secondary'
import ArticleSection from './ArticleSection'
import ExpandableSection from './ExpandableSection'
import { minuteSecondFormat } from '../../../utils/time'

const ArticlesP = styled.p`
  margin: 0 0 0.8em;
  line-height: 1.3;
  font-family: ${fonts.secondary};
  font-size: 18px;
  padding: 0.6em 0;
`

const ArticleTitle = styled(ArticlesP)`
  display: inline-block;
  margin-right: 5px;
`

const Categories = styled(ArticlesP)`
  text-transform: capitalize;
`

const ImageContainer = styled.div`
  margin: 10px;
  height: 200px;
`

const Image = styled.img`
  height: 200px;
`

const CloseEdit = styled.span`
  cursor: pointer;
  position: absolute;
  margin-left: 258px;
  font-size: 30px;
  user-select: none;
`

const Buttons = styled.div`
  position: absolute;
  margin: 8px 0 0 260px;
  user-select: none;
`

const ArticleForm = styled(AdviceArticleForm)`
  position: relative;
`

const ArticleButton = styled.span`
  cursor: pointer;
  margin-right: 10px;
  font-size: 20px;
  padding: 5px;
  color: ${props => (props.red ? colors.primary : colors.grayDarker)};
  &:hover {
    background-color: ${props => (props.red ? colors.primary : colors.grayLight)};
    color: ${props => props.red && colors.white};
  }
`

const ArticleDetails = ({ article, update, archive, setPublished, saving }) => {
  const [editArticle, setEdit] = useState(false)

  const {
    id,
    title,
    slug,
    videoUrl,
    durationInSeconds,
    description,
    categories,
    published,
    thumbnail,
    ogImage,
  } = article
  const articleUrl = `${process.env.REACT_APP_APP_HOST}/advice/${slug}`

  const handleUpdate = values => {
    const { thumbnail, ogImage, categories } = values

    const article = {
      ...values,
      categories: JSON.stringify(categories),
      thumbnail: Array.isArray(thumbnail) ? thumbnail[0] : thumbnail,
      ogImage: Array.isArray(ogImage) ? ogImage[0] : ogImage,
    }
    update(id, article).then(() => setEdit(false))
  }

  return (
    <>
      {editArticle ? (
        <>
          <CloseEdit onClick={() => setEdit(false)} title="Close">
            &times;
          </CloseEdit>
          <ArticleForm
            onSubmit={handleUpdate}
            initialValues={article}
            submitText="Save Article"
            saving={saving}
          />
        </>
      ) : (
        <>
          <Buttons>
            <ArticleButton title="Edit Article" onClick={() => setEdit(true)}>
              Edit
            </ArticleButton>
            <ArticleButton
              red={published}
              title={`${published ? 'Un-Publish' : 'Publish'} Article`}
              onClick={() => {
                window.confirm(
                  `Are you sure you want to ${
                    published ? 'un-' : ''
                  }publish this article?\n ${title}`,
                ) && setPublished(id, !published)
              }}
            >
              {published ? 'Un-Publish' : 'Publish'}
            </ArticleButton>
            <ArticleButton
              red
              title="Delete Article"
              onClick={() =>
                window.confirm(`Are you sure you want to delete this article?\n ${title}`) &&
                archive(id)
              }
            >
              Delete
            </ArticleButton>
          </Buttons>

          <FormBorder maxWidth="700px">
            <div>
              <ArticleSection first>
                <H3Secondary>Title</H3Secondary>
                <ArticleTitle>{title}</ArticleTitle>
              </ArticleSection>

              <ArticleSection>
                <H3Secondary>Description</H3Secondary>
                <ArticlesP>{description}</ArticlesP>
              </ArticleSection>

              <ArticleSection>
                <H3Secondary>Url</H3Secondary>
                <ArticlesP>
                  {published ? (
                    <a href={articleUrl} target="_blank" rel="noopener noreferrer">
                      {articleUrl}
                    </a>
                  ) : (
                    <span>{articleUrl}</span>
                  )}
                </ArticlesP>
              </ArticleSection>

              <ExpandableSection>
                <ArticleSection>
                  <H3Secondary>Video Embed Url</H3Secondary>
                  <ArticlesP>
                    <a href={videoUrl} target="_blank" rel="noopener noreferrer">
                      {videoUrl}
                    </a>
                  </ArticlesP>
                </ArticleSection>

                <ArticleSection>
                  <H3Secondary>Video Duration</H3Secondary>
                  <ArticlesP>{minuteSecondFormat(durationInSeconds)}</ArticlesP>
                </ArticleSection>

                <ArticleSection>
                  <H3Secondary>Categories</H3Secondary>
                  <Categories>
                    {categories.map(
                      (category, index, arr) =>
                        `${category}${arr.length === index + 1 ? '' : ', '}`,
                    )}
                  </Categories>
                </ArticleSection>

                <ArticleSection>
                  <H3Secondary>Thumbnail</H3Secondary>
                  <ImageContainer>
                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${thumbnail}`} />
                  </ImageContainer>
                </ArticleSection>

                <ArticleSection noUnderLine>
                  <H3Secondary>Social Sharing Image</H3Secondary>
                  <ImageContainer>
                    <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/${ogImage}`} />
                  </ImageContainer>
                </ArticleSection>
              </ExpandableSection>
            </div>
          </FormBorder>
        </>
      )}
    </>
  )
}

export default ArticleDetails
