import styled from 'styled-components'
import { colors, fonts } from '../../../theme'

const ClosePage = styled.div`
  text-align: center;
  cursor: pointer;
  span {
    margin: 0.5em auto;
    font-family: ${fonts.secondary};
    background: ${colors.grayDark};
    color: ${colors.white};
    border-radius: 50%;
    display: inline-block;
    line-height: 27px;
    height: 32px;
    width: 32px;
    font-size: 20px;
  }
`

/** @component */
export default ClosePage
