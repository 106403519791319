import React, { useState } from 'react'
import styled from 'styled-components'
import { kebabCase } from 'lodash/fp'
import Input from '../../../components/input/Input/Input'

import AdminPage from '../../../components/layout/AdminPage/AdminPage'
import { useAdviceArticles } from '../../../containers/AdminApi/AdviceArticlesApi'
import AdviceArticleForm from './AdviceArticleForm/AdviceArticleForm'
import ArticleDetails from './ArticleDetails'
import ErrorSection from '../../../components/errors/Section/ErrorSection'

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

const AdvicePage = () => {
  const [add, setAdd] = useState(false)
  const [search, setSearch] = useState('')
  const {
    advice: { articles, saving, loading, error },
    createArticle,
    updateArticle,
    archiveArticle,
    setPublished,
  } = useAdviceArticles()

  const isInSearch = item =>
    search.length ? item.toLowerCase().includes(search.toLowerCase()) : true

  const handleSubmit = async values => {
    const { title, categories, thumbnail, ogImage } = values
    const article = {
      ...values,
      slug: kebabCase(title),
      categories: JSON.stringify(categories),
      thumbnail: thumbnail[0],
      ogImage: ogImage[0],
    }
    await createArticle(article).then(() => setAdd(false))
  }

  return (
    <AdminPage
      heading="Advice Page CMS"
      actions={[{ title: add ? 'Close' : 'Add Article', action: () => setAdd(!add) }]}
      loading={loading}
      error={error}
    >
      {add && <AdviceArticleForm onSubmit={handleSubmit} saving={saving} />}
      <SearchBar>
        <Input
          placeholder="Search by title..."
          width="700px"
          onChange={event => setSearch(event.target.value)}
        />
      </SearchBar>
      {articles.length ? (
        articles.map(
          article =>
            !article.archived &&
            isInSearch(article.title) && (
              <ArticleDetails
                key={article.slug}
                update={updateArticle}
                archive={archiveArticle}
                setPublished={setPublished}
                article={article}
                saving={saving}
              />
            ),
        )
      ) : (
        <ErrorSection>No Articles Found.</ErrorSection>
      )}
      {error && <ErrorSection>There was an error loading articles.</ErrorSection>}
    </AdminPage>
  )
}

export default AdvicePage
