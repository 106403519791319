import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, mediaMinWidth } from '../../../theme'

/**
 * Render a form as a child of this component to add a border to it.
 */
const FormBorder = styled.div`
  max-width: ${props => props.maxWidth || 'auto'};
  margin: 0 auto 1em;

  ${mediaMinWidth.sm`
    border: 2px solid ${colors.grayLight};
    border-radius: 5px;
    padding: 0.6em;
  `};
`

FormBorder.propTypes = {
  /** Forms fill their parent's width, set a max width to limit that */
  maxWidth: PropTypes.string,
}

/** @component */
export default FormBorder
