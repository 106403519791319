import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FormError from '../../../components/input/FormError/FormError'
import FormInlineButton from '../../../components/input/FormInline/FormInlineButton'
import FormInlineInput from '../../../components/input/FormInline/FormInlineInput'
import FormInlineRow from '../../../components/input/FormInline/FormInlineRow'
import { required } from '../../../utils/forms/validation'

const CheckBoxView = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 18px;
`

const FieldLabel = styled.label`
  font-size: 18px;
`

export const AddMailingListForm = ({ onSubmit }) => {
  const onFormSubmit = (values, form) => {
    const submitValues = {
      ...values,
      type: values.type ? values.type.toLowerCase() : values.type,
    }
    onSubmit(submitValues)
    form.restart()
  }

  return (
    <Form
      onSubmit={onFormSubmit}
      render={({ form, handleSubmit, submitting, submitError }) => (
        <form onSubmit={(values) => handleSubmit(values, form)}>
          <FormInlineRow>
            <Field name="slug" placeholder="Route…" component={FormInlineInput} validate={required} />

            <Field
              name="mailingListId"
              placeholder="Mailing List Id…"
              component={FormInlineInput}
              validate={required}
            />

            <Field name="type" placeholder="Lead or Paid…" component={FormInlineInput} />

            <CheckBoxView>
              <FieldLabel>GB</FieldLabel>
              <Field name="GB" component="input" type="checkbox" defaultValue={false} validate={required} />
            </CheckBoxView>

            <CheckBoxView>
              <FieldLabel>US</FieldLabel>
              <Field name="US" component="input" type="checkbox" defaultValue={false} validate={required} />
            </CheckBoxView>

            <div>
              <FormInlineButton type="submit" disabled={submitting}>
                {submitting ? 'Saving…' : 'Add'}
              </FormInlineButton>
            </div>
          </FormInlineRow>
          <FormError submitting={submitting} error={submitError} />
        </form>
      )}
    />
  )
}

AddMailingListForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
