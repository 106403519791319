import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import P from '../../typography/P/P'
import Button from '../Button/Button'

const ScreenSizeWrapper = styled.div`
  background: rgb(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

const Overlay = styled.div`
  background: white;
  padding: 15px;
  margin: 20px;
  border-radius: 10px;
`

const OverlayTitle = styled(P)`
  margin-bottom: 30px;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
`

const CloseButton = styled(Button)`
  background: gray;
`

const ConfirmButton = styled(Button)`
  background: ${props => props.theme.colors[props.color || 'green']};
`

export const ModalWithAction = ({
  body,
  closeModal,
  onPressConfirm,
  confirmButtonText,
  visible = true,
  confirmButtonColor,
}) =>
  visible && (
    <ScreenSizeWrapper onClick={closeModal}>
      <Overlay>
        <OverlayTitle>{body || `Are you sure you want to ${confirmButtonText}?`}</OverlayTitle>
        <ButtonRow>
          <CloseButton onClick={closeModal}>Close</CloseButton>
          <ConfirmButton
            color={confirmButtonColor}
            onClick={() => {
              onPressConfirm()
              closeModal()
            }}
          >
            {confirmButtonText}
          </ConfirmButton>
        </ButtonRow>
      </Overlay>
    </ScreenSizeWrapper>
  )

ModalWithAction.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onPressConfirm: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  body: PropTypes.string,
  confirmButtonColor: PropTypes.string,
}
