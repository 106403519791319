import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fontWeights, themes } from '../../../theme'

/**
 * The main heading of a page which renders the `h1` HTML tag. As such it should
 * only be used once per page. Color styles controlled by the theme.
 */
const H1 = styled.h1`
  margin: 0 0 0.3em;
  font-size: 70px;
  line-height: 1;
  font-weight: ${fontWeights.bold};
  color: ${props => themes[props.colorScheme || 'light'].color};
`

H1.propTypes = {
  colorScheme: PropTypes.oneOf(['light', 'gray', 'dark', 'primary']),
}

H1.defaultProps = {
  colorScheme: 'light',
}

/** @component */
export default H1
