import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import ClosePage from '../ClosePage/ClosePage'
import ReactMarkdown from '../ReactMarkdown/ReactMarkdown'

import { colors, fonts, fontWeights, mediaMinWidth } from '../../../theme'

const twoColumns = props => React.Children.toArray(props.children).filter(Boolean).length === 2

const HeadingH1 = styled.h1`
  font-family: ${fonts.primary};
  color: ${colors.black};
  text-align: center;
  font-size: 32px;
  line-height: 1;
  margin: 0 0 ${props => (props.noMargin ? '0.1em' : '1em')};
`

const HeadingMD = styled(ReactMarkdown)`
  h1 {
    font-family: ${fonts.primary};
    color: ${colors.black};
    text-align: center;
    font-size: 32px;
    line-height: 1;
    margin: 0 0 ${props => (props.noMargin ? '0.1em' : '1em')};
    em {
      color: ${colors.primary};
      font-style: normal;
    }
  }
`

const Heading = ({ children, ...props }) =>
  typeof children === 'string' ? (
    <HeadingMD {...props}>{`# ${children}`}</HeadingMD>
  ) : (
    <HeadingH1>{children}</HeadingH1>
  )

const SubHeading = styled.h2`
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.thin};
  font-style: italic;
  text-align: center;
  color: ${colors.grayDark};
  font-size: 30px;
  margin: 0 0 1em;
`

const Grid = styled.section`
  display: flex;
  justify-content: space-between;
  max-width: ${props => (twoColumns(props) ? '1150px' : '600px')};
  flex-wrap: ${props => (props.colReverse ? 'wrap-reverse' : 'wrap')};
  margin: ${props => props.marginTop || '0'} auto 0;

  > div {
    margin-bottom: 0.5em;
    width: 100%;
  }
  ${mediaMinWidth.md`
    > div {
      width: ${props => (twoColumns(props) ? '48%' : '100%')};
    }
  `};

  color: ${colors.black};
  h4 {
    font-family: ${fonts.secondary};
    color: ${colors.black};
    font-weight: ${fontWeights.bold};
    font-size: 18px;
    line-height: 1.2;
    margin: 0.8em 0 0.6em;

    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    margin: 0;
    padding-left: 30px;
  }
  p,
  li {
    font-family: ${fonts.secondary};
    font-size: 16px;
    margin: 0 0 0.8em;
    line-height: 1.3;
  }
  li {
    margin-bottom: 0.8em;
  }
  em {
    font-style: normal;
    color: ${colors.primary};
  }
`

const Footer = styled.div`
  text-align: center;
  margin: 0.6em 0 1em;
`

const ModalContent = ({
  heading,
  subHeading,
  footer,
  colReverse,
  children,
  history,
  noClose,
  marginTop,
}) => (
  <>
    {heading && <Heading noMargin={!!subHeading}>{heading}</Heading>}
    {subHeading && <SubHeading>{subHeading}</SubHeading>}
    <Grid colReverse={colReverse} marginTop={marginTop}>
      {children}
    </Grid>
    {footer && <Footer>{footer}</Footer>}
    {!noClose && (
      <ClosePage onClick={history.goBack}>
        <span>x</span>
      </ClosePage>
    )}
  </>
)

ModalContent.Heading = Heading
ModalContent.SubHeading = SubHeading
ModalContent.Column = ({ className, children }) => <div className={className}>{children}</div>

ModalContent.propTypes = {
  heading: PropTypes.node,
  subHeading: PropTypes.node,
  colReverse: PropTypes.bool,
}

export default withRouter(ModalContent)
