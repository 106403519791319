import React from 'react'
import styled from 'styled-components'
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
// import Tooltip from 'react-bootstrap/Tooltip'
// import SeekTimes from './SeekTimes'

// const ViewingTooltip = styled(Tooltip)`
//   background: #ccc;
//   color: #fff;
//   padding: 5px;
//   border-radius: 5px;
// `

const Span = styled.span`
  cursor: default;
  &:hover {
    background: #ccc;
    color: #fff;
  }
`

const ViewTracking = ({ viewPercentages = [], watchData = {} }) => {
  return (
    viewPercentages
      // .filter(
      //   ({ watchNo }) =>
      //     watchData[watchNo].find(event => event.eventTrigger === 'play') &&
      //     watchData[watchNo].find(event => event.eventTrigger === 'end'),
      // )
      .map(({ avgViewed }, i, arr) => (
        // <OverlayTrigger
        //   key={i}
        //   placement="right"
        //   overlay={
        //     <ViewingTooltip>
        //       <SeekTimes watchSession={watchData[watchNo]} />
        //     </ViewingTooltip>
        //   }
        // >
        <Span key={i}>
          {Math.round(avgViewed)}% {i === arr.length - 1 ? null : '| '}
        </Span>
        // </OverlayTrigger>
      ))
  )
}

export default ViewTracking
