import { useMutation, useQueryClient } from 'react-query'
import esAdminAPI from '../../services/esAPI/esAdminAPI'
import { MAILING_LIST_ROUTE } from './useMailingListConfigsQuery'

export const useMailingListConfigsDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    MAILING_LIST_ROUTE,
    (id) => esAdminAPI('delete', `${MAILING_LIST_ROUTE}/${id}`),
    {
      onSuccess: (_, id) => {
        const existing = queryClient.getQueryData(MAILING_LIST_ROUTE)
        queryClient.setQueryData(
          MAILING_LIST_ROUTE,
          existing.filter((config) => config.id !== id),
        )
      },
    },
  )
}
