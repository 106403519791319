import React, { useState } from 'react'
import styled from 'styled-components'
import { orderBy } from 'lodash/fp'
import ErrorSection from '../../../components/errors/Section/ErrorSection'
import Input from '../../../components/input/Input/Input'
import LoadingSection from '../../../components/loading/Section/LoadingSection'
import { H3, P } from '../../../components/typography'
import { useMentoring } from '../../../containers/AdminApi/MentoringApi'
import { QuestionPreviewRow } from './QuestionPreviewRow'
import { TagsManager } from './TagsManager'
import { useCopyFromList } from './useCopyFromList'

const orderByDate = orderBy(['questionDate'], ['asc'])

const StatusDropdown = styled(Input.Select)`
  margin-bottom: 10px;
`
const TextFilter = styled(Input.Text)`
  margin-bottom: 10px;
`
// const DateTimePicker = styled(Input.DateTimePicker)`
//   margin-bottom: 10px;
// `

const Page = styled.div`
  max-width: 'auto';
`

const QuestionsHeading = styled(H3)`
  padding-top: 20px;
`

const FilterByStatus = styled(P)`
  text-align: left;
`

const BlogLinkWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const BlogLink = styled.a`
  margin: 0 auto;
`

const MentoringPage = () => {
  const {
    tagCounts,
    renameTagGlobally,
    deleteTagGlobally,
    mentoring: { questions, loading, error },
    setTags,
  } = useMentoring()

  const [statusFilter, setStatusFilter] = useState('new-plus-drafted')
  const [nameFilter, setNameFilter] = useState('')
  const [subjectAndAnswerFilter, setSubjectAndAnswerFilter] = useState('')
  const [tagsFilter, setTagsFilter] = useState('')

  const questionCount = questions.length
  const newQuestionCount = questions.filter(byStatus('new')).length
  const draftedCount = questions.filter(byStatus('drafted')).length
  const newPlusDrafted = newQuestionCount + draftedCount
  const publishedCount = questions.filter(byStatus('published')).length

  const statusFilteredQuestions = questions.filter(byStatus(statusFilter))

  const filteredQuestions = orderByDate(
    statusFilteredQuestions
      .filter(bySubjectAndAnswer(subjectAndAnswerFilter.toLowerCase()))
      .filter(byName(nameFilter.toLowerCase()))
      .filter(byTags(tagsFilter.toLowerCase())),
  )

  const { idsWithCopiedText, toggleCopied } = useCopyFromList(
    filteredQuestions.map(({ id, answerPlainText }) => ({ id, text: answerPlainText })),
  )

  return (
    <Page>
      {loading ? (
        <LoadingSection />
      ) : (
        <ErrorSection error={error}>
          <h2>Mentoring Q&A Dashboard</h2>
          <BlogLinkWrapper>
            <BlogLink href="/es-blog">Martin's Blog</BlogLink>
          </BlogLinkWrapper>

          <TagsManager
            tagCounts={tagCounts}
            renameTagGlobally={renameTagGlobally}
            deleteTagGlobally={deleteTagGlobally}
          />

          <FilterByStatus>Filter by Status:</FilterByStatus>
          <StatusDropdown
            default={statusFilter}
            onChange={({ target: { value } }) => setStatusFilter(value)}
          >
            <option value="all">All ({questionCount})</option>
            <option selected value="new-plus-drafted">
              New & Drafted ({newPlusDrafted})
            </option>
            <option value="new">New Questions ({newQuestionCount})</option>
            <option value="drafted">Drafted ({draftedCount})</option>
            <option value="published">Published ({publishedCount})</option>
          </StatusDropdown>
          <TextFilter
            onChange={({ target: { value } }) => setNameFilter(value)}
            placeholder="Filter by name..."
          />
          <TextFilter
            onChange={({ target: { value } }) => setTagsFilter(value)}
            placeholder="Filter by tags..."
          />
          <TextFilter
            onChange={({ target: { value } }) => setSubjectAndAnswerFilter(value)}
            placeholder="Filter by subject line or answer..."
          />
          <QuestionsHeading>
            {statusFilter === 'all'
              ? `Total Questions`
              : statusFilter === 'new'
              ? `New Questions`
              : statusFilter === 'new-plus-drafted'
              ? `New & Drafted`
              : statusFilter === 'drafted'
              ? `Drafted Answers`
              : statusFilter === 'published'
              ? `Published Answers`
              : null}
            : {filteredQuestions.length}
            {filteredQuestions.length === statusFilteredQuestions.length ? '' : ' (Filtered)'}
          </QuestionsHeading>
          {filteredQuestions.map((qAndA) => (
            <QuestionPreviewRow
              {...qAndA}
              key={qAndA.id}
              answerAuthor={qAndA.answerAuthor}
              answer={qAndA.answerPlainText}
              isCopied={idsWithCopiedText.includes(qAndA.id)}
              toggleCopied={() => toggleCopied(qAndA.id)}
              date={qAndA.questionDate}
              name={qAndA.userFirstName}
              questionHref={`/mentoring/questions/${qAndA.userId}/${qAndA.id}`}
              tagsStr={qAndA.tags.map((t) => t.name).join(', ')}
              setTags={(tags) => setTags(qAndA.id, tags)}
            />
          ))}
        </ErrorSection>
      )}
    </Page>
  )
}

// Array filter functions

const byStatus = (status) => (qAndA) => {
  switch (status) {
    case 'new':
      return !qAndA.published && !qAndA.answerPlainText
    case 'drafted':
      return !qAndA.published && !!qAndA.answerPlainText
    case 'published':
      return qAndA.published
    case 'new-plus-drafted':
      return !qAndA.published
    default:
      return true
  }
}

const byName = (name) => (qAndA) => isAInB(name, qAndA.userFirstName)

const bySubjectAndAnswer = (query) => (qAndA) => {
  const { answerPlainText, subject } = qAndA
  return matchesQuery(query, [answerPlainText || '', subject])
}

const byTags = (query) => (qAndA) =>
  matchesAllQueries(
    query.split(',').map((q) => q.trim()),
    qAndA.tags.map((t) => t.name),
  )

const matchesAllQueries = (queries = [], stringsToSearch = []) =>
  queries.length === 0 ||
  queries.filter((q) => matchesQuery(q, stringsToSearch)).length === queries.length

const matchesQuery = (query, stringsToSearch = []) =>
  !query || Boolean(stringsToSearch.find((item) => isAInB(query, item)))

const isAInB = (a = '', b = '') => b.toLowerCase().includes(a.toLowerCase())

export default MentoringPage
