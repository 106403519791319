import PropTypes from 'prop-types'
import styled from 'styled-components'

const TD = styled.td`
  text-align: ${props => (props.center ? 'center' : props.right ? 'right' : 'left')};
`

TD.propTypes = {
  left: PropTypes.bool,
  center: PropTypes.bool,
  right: PropTypes.bool,
}

/** @component */
export default TD
