const tokenKey = 'entrSem-auth'

class TokenStore {
  token = ''

  constructor() {
    this.token = localStorage.getItem(tokenKey) ?? ''
  }

  set(token) {
    this.token = token
    localStorage.setItem(tokenKey, token)
  }

  get() {
    return this.token
  }

  clear() {
    this.set('')
  }
}

export const authTokenStore = new TokenStore()
