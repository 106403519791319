import { useState, useEffect, useCallback } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'
import { formatFormServerError } from '../../utils/forms/errors'

const USER_INITIAL_STATE = { user: null, loading: true }

export const useUserList = ({ userType, userId }) => {
  const [userList, setUserList] = useState({ users: [], loading: true })
  const [user, setUser] = useState(USER_INITIAL_STATE)
  const [selectedUsers, setSelectedUsers] = useState([])

  const setAllUserList = useCallback((users) => setUserList({ users, loading: false, error: undefined }), [])

  const handleAllUserListError = useCallback((error) => setUserList((prev) => ({ ...prev, error, loading: false })), [])

  useEffect(() => {
    if (userType) getAllUsers(userType)

    if (userId) {
      setUser(USER_INITIAL_STATE)
      getUser(userId)
    }
  }, [userId, userType])

  const getAllUsers = (type) => esAdminAPI('get', `/users/${type}`).then(setAllUserList).catch(handleAllUserListError)

  const addUser = (values) =>
    esAdminAPI('post', '/users', values)
      .then((user) => setUserList((prev) => ({ users: [user, ...prev.users] })))
      .catch(formatFormServerError)

  const getUser = (id) =>
    esAdminAPI('get', `/user/${id}`)
      .then((user) => setUser({ user, loading: false }))
      .catch((error) => setUser((prev) => ({ ...prev, error, loading: false })))

  const updateUser = (id, values) =>
    esAdminAPI('put', `/users/${id}`, values)
      .then(() => getAllUsers(userType))
      .catch(formatFormServerError)

  const archiveUser = (id) =>
    esAdminAPI('delete', `/users/${id}`)
      .then((updatedUser) =>
        setUserList((prev) => ({
          users: prev.users.map((user) => (user.id !== updatedUser.id ? user : updatedUser)),
        })),
      )
      .catch(formatFormServerError)

  const archiveMultipleUsers = (usersToArchive) =>
    esAdminAPI('post', `/users/archive-users`, { usersToArchive })
      .then((updatedUser) => {
        setUserList((prev) => ({
          users: prev.users.filter((user) => !updatedUser.map((u) => u.id).includes(user.id)),
        }))
        setSelectedUsers([])
      })
      .catch(formatFormServerError)

  const loadUnverifiedPhones = () =>
    esAdminAPI('get', '/otp/unverified-phones').then(setAllUserList).catch(handleAllUserListError)

  return {
    userList,
    user,
    addUser,
    updateUser,
    archiveUser,
    getUser,
    archiveMultipleUsers,
    selectedUsers,
    setSelectedUsers,
    getAllUsers,
    loadUnverifiedPhones,
  }
}
