import { useState, useEffect } from 'react'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const usePayments = () => {
  const [payment, setPayment] = useState({ payments: [], loading: true })

  useEffect(() => {
    esAdminAPI('get', '/reports/payments')
      .then(payments => setPayment({ payments, loading: false }))
      .catch(error => setPayment(prev => ({ ...prev, error, loading: false })))
  }, [])

  return { payment }
}
