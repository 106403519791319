import { useState, useCallback, useMemo } from 'react'
import { addDays, isAfter } from 'date-fns'
import { isBefore } from 'date-fns/esm'
import { getHours } from 'date-fns/fp'

const initialFilters = {
  accessedSessions: '',
  accessedWrittenContent: '',
  country: '',
  email: '',
  fromDate: '',
  fromUpdatedDate: '',
  fromLiveSessionDate: '',
  fromPaymentDate: '',
  fromProgramExpiryDate: '',
  fromMentoringExpiryDate: '',
  fromTime: '',
  fromUpdatedTime: '',
  hasPassword: '',
  name: '',
  program: '',
  programStatus: '',
  promoCode: '',
  signUpdDate: '',
  signUpTime: '',
  source: '',
  telesalesSource: '',
  toDate: '',
  toUpdatedDate: '',
  toLiveSessionDate: '',
  toPaymentDate: '',
  toProgramExpiryDate: '',
  toMentoringExpiryDate: '',
  toTime: '',
  toUpdatedTime: '',
  utmCampaign: '',
  utmContent: '',
  utmMedium: '',
  utmSource: '',
  utmTerm: '',
}

export function useFilterUsers(users) {
  const [filters, setFilters] = useState(initialFilters)

  const clearFilters = useCallback(() => setFilters(initialFilters), [])
  const setSingleFilter = useCallback(
    (name, value) => setFilters((prev) => ({ ...prev, [name]: value })),
    [],
  )

  const filtersApplied = useMemo(
    () => JSON.stringify(initialFilters).length !== JSON.stringify(filters).length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  )
  const filteredUsers = useMemo(() => filterUsers(users, filters), [users, filters])

  return useMemo(
    () => ({
      clearFilters,
      filteredUsers,
      filters,
      filtersApplied,
      setSingleFilter,
    }),
    [clearFilters, filteredUsers, filters, filtersApplied, setSingleFilter],
  )
}

const filterUsers = (users = [], filters = initialFilters) => {
  return users.filter(matchesFilters(filters))
}

const lowerCase = (str) => (str || '').toLowerCase()

const matchesFilters = (filters) => (user) => {
  const firstName = lowerCase(user.firstName)
  const lastName = lowerCase(user.lastName)
  const email = lowerCase(user.email)
  const source = lowerCase(user.source)
  const telesalesSource = lowerCase(user.telesalesSource)
  const utmSource = lowerCase(user.utmSource)
  const utmCampaign = lowerCase(user.utmCampaign)
  const utmMedium = lowerCase(user.utmMedium)
  const utmTerm = lowerCase(user.utmTerm)
  const utmContent = lowerCase(user.utmContent)
  const country = lowerCase(user.country)
  const program = lowerCase(user.program)
  const promoCode = lowerCase(user.promoCode)

  const {
    date,
    updatedDate,
    accessedWrittenContent,
    accessedSessions,
    paymentDate,
    programExpiryDate,
    availableContent,
    hasPassword,
  } = user

  const { liveSession } = availableContent || {}

  if (date) {
    if (filters.fromDate && isBefore(date, filters.fromDate)) return false
    if (filters.toDate && isAfter(date, addDays(filters.toDate, 1))) return false
    if (filters.fromTime && getHours(date) < filters.fromTime) return false
    if (filters.toTime && getHours(date) >= filters.toTime) return false
  } else if (filters.fromDate || filters.toDate || filters.fromTime || filters.toTime) return false

  if (updatedDate) {
    if (filters.fromUpdatedDate && isBefore(date, filters.fromUpdatedDate)) return false
    if (filters.toUpdatedDate && isAfter(date, addDays(filters.toUpdatedDate, 1))) return false
    if (filters.fromUpdatedTime && getHours(date) < filters.fromUpdatedTime) return false
    if (filters.toUpdatedTime && getHours(date) >= filters.toUpdatedTime) return false
  } else if (
    filters.fromUpdatedDate ||
    filters.toUpdatedDate ||
    filters.fromUpdatedTime ||
    filters.toUpdatedTime
  )
    return false

  if (
    filters.programStatus === 'Active' &&
    !isBefore(new Date(), availableContent?.mentoringExpiryDate)
  )
    return false
  if (
    filters.programStatus === 'Inactive' &&
    isBefore(new Date(), availableContent?.mentoringExpiryDate)
  )
    return false

  if (programExpiryDate) {
    if (filters.fromProgramExpiryDate && isBefore(programExpiryDate, filters.fromProgramExpiryDate))
      return false
    if (
      filters.toProgramExpiryDate &&
      isAfter(programExpiryDate, addDays(filters.toProgramExpiryDate, 1))
    )
      return false
  } else if (filters.fromProgramExpiryDate || filters.toProgramExpiryDate) return false

  if (availableContent?.mentoringExpiryDate) {
    if (
      filters.fromMentoringExpiryDate &&
      isBefore(availableContent?.mentoringExpiryDate, filters.fromMentoringExpiryDate)
    )
      return false
    if (
      filters.toMentoringExpiryDate &&
      isAfter(availableContent?.mentoringExpiryDate, addDays(filters.toMentoringExpiryDate, 1))
    )
      return false
  } else if (filters.fromMentoringExpiryDate || filters.toMentoringExpiryDate) return false

  if (paymentDate) {
    if (filters.fromPaymentDate && isBefore(paymentDate, filters.fromPaymentDate)) return false
    if (filters.toPaymentDate && isAfter(paymentDate, addDays(filters.toPaymentDate, 1)))
      return false
  } else if (filters.fromPaymentDate || filters.toPaymentDate) return false

  if (filters.fromLiveSessionDate || filters.toLiveSessionDate) {
    if (!liveSession || !liveSession.date) return false

    if (filters.fromLiveSessionDate && isBefore(liveSession.date, filters.fromLiveSessionDate)) {
      return false
    }
    if (filters.toLiveSessionDate && isAfter(liveSession.date, filters.toLiveSessionDate)) {
      return false
    }
  }

  if (!firstName.includes(lowerCase(filters.name)) && !lastName.includes(lowerCase(filters.name))) {
    return false
  }

  if (!email.includes(lowerCase(filters.email))) return false
  if (!source.includes(lowerCase(filters.source))) return false
  if (!utmSource.includes(lowerCase(filters.utmSource))) return false
  if (!utmCampaign.includes(lowerCase(filters.utmCampaign))) return false
  if (!utmMedium.includes(lowerCase(filters.utmMedium))) return false
  if (!utmTerm.includes(lowerCase(filters.utmTerm))) return false
  if (!utmContent.includes(lowerCase(filters.utmContent))) return false
  if (!country.includes(lowerCase(filters.country))) return false
  if (!promoCode.includes(lowerCase(filters.promoCode))) return false
  if (!program.includes(lowerCase(filters.program))) return false
  if (!telesalesSource.includes(lowerCase(filters.telesalesSource))) return false

  if (filters.accessedSessions === 'Yes' && !accessedSessions) return false
  if (filters.accessedSessions === 'No' && accessedSessions) return false

  if (filters.accessedWrittenContent === 'Yes' && !accessedWrittenContent) return false
  if (filters.accessedWrittenContent === 'No' && accessedWrittenContent) return false

  if (filters.hasPassword === 'Yes' && !hasPassword) return false
  if (filters.hasPassword === 'No' && hasPassword) return false

  return true
}
