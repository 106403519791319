import React from 'react'
import styled from 'styled-components'
import { differenceInHours } from 'date-fns'
import { mySqlToLongDateTime } from '../../../utils/date'
import { TagsFormAndPreview } from './components/TagsFormAndPreview'
import { DRAFT, NEW, PUBLISHED } from './constants'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: solid gray 1px;
  padding: 20px 0px;
  overflow: hidden;
`

const Detail = styled.div`
  margin-bottom: 4px;
  flex: 0.2;
  text-align: left;
`

const Subject = styled.div`
  margin-bottom: 4px;
  flex: 0.6;
  text-align: left;
`

const DateField = styled.div`
  margin-bottom: 6px;
  flex: 0.2;
  text-align: left;
`
const TimeElapsed = styled.div`
  color: ${(props) => props.theme.colors[props.color || 'black']};
  text-align: left;
`

const Anchor = styled.a`
  color: blue;
  font-weight: bold;
  text-decoration: underline;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
`

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const CopyButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  padding: 6px;
  border-radius: 5px;
  border: ${(props) => `2px solid ${props.isCopied ? 'transparent' : 'black'}`};
  background: ${(props) => (!props.isCopied ? '' : props.theme.colors.info)};
  color: ${(props) => (!props.isCopied ? '' : props.theme.colors.white)};
  user-select: none;
`

const AnswerStatus = styled.span`
  color: ${({ status, theme }) =>
    theme.colors[status === PUBLISHED ? 'green' : status === DRAFT ? 'amber' : 'primary']};
  text-align: left;
`

export const QuestionPreviewRow = ({
  answer,
  date,
  isCopied,
  name,
  published,
  questionHref,
  setTags,
  subject,
  toggleCopied,
  tagsStr,
  answerAuthor,
}) => {
  const hoursElapsed = differenceInHours(new Date(), date)
  const answerStatus = published ? PUBLISHED : !!answer ? DRAFT : NEW

  return (
    <Container>
      <Details>
        <Detail>
          <b>Name: </b> {name}
        </Detail>
        <Subject>
          <b>Subject Line: </b>
          {subject}
        </Subject>
        <TagsFormAndPreview setTags={setTags} initialTagsStr={tagsStr} />
        <DateField>
          <b>Date: </b>
          {mySqlToLongDateTime(date)}
        </DateField>
        <Detail>
          <b>Status:</b> <AnswerStatus status={answerStatus}>{answerStatus}</AnswerStatus>
        </Detail>

        {answerAuthor !== 'Martin' && (
          <Detail style={{ color: 'red' }}>
            <u>
              <b>Answer Author: {answerAuthor}</b>
            </u>
          </Detail>
        )}

        {!published && (
          <TimeElapsed color={getTimeElapsedColor(hoursElapsed)}>
            <b>Time passed:</b> {hoursElapsed} {hoursElapsed === 1 ? 'hour' : 'hours'}
          </TimeElapsed>
        )}
      </Details>
      <Bottom>
        <Anchor href={questionHref}>View Question</Anchor>
        {answer && (
          <CopyButton onClick={toggleCopied} isCopied={isCopied}>
            {isCopied ? 'COPIED' : 'COPY'}
          </CopyButton>
        )}
      </Bottom>
    </Container>
  )
}

const getTimeElapsedColor = (hoursElapsed) => {
  if (hoursElapsed > 48) return 'error'
  if (hoursElapsed > 36) return 'amber'
  return 'green'
}
