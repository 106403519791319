import { useMutation, useQueryClient } from 'react-query'
import esAdminAPI from '../../services/esAPI/esAdminAPI'
import { MAILING_LIST_ROUTE } from './useMailingListConfigsQuery'

export const useMailingListConfigsAddMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(MAILING_LIST_ROUTE, (vals) => esAdminAPI('post', MAILING_LIST_ROUTE, { vals }), {
    onSuccess: (newConfig) => {
      const existing = queryClient.getQueryData(MAILING_LIST_ROUTE)
      queryClient.setQueryData(MAILING_LIST_ROUTE, [...existing, newConfig])
    },
  })
}
