import { useQuery } from 'react-query'
import esAdminAPI from '../../services/esAPI/esAdminAPI'

export const useStarterContentTrackingQuery = (userId, options) => {
  const query = useQuery(
    `user-progress-data-${userId}`,
    () => esAdminAPI('get', `/track/all-starter-content/${userId}`),
    options,
  )

  return { ...query, starterUserProgressData: query.data }
}

export const useFoundationContentTrackingQuery = (userId, options) => {
  const query = useQuery(
    `foundation-user-progress-data-${userId}`,
    () => esAdminAPI('get', `/track/all-foundation-content/${userId}`),
    options,
  )

  return { ...query, foundationUserProgressData: query.data }
}
export const useAcceleratorContentTrackingQuery = (userId, options) => {
  const query = useQuery(
    `accelerator-user-progress-data-${userId}`,
    () => esAdminAPI('get', `/track/all-accelerator-content/${userId}`),
    options,
  )

  return { ...query, acceleratorUserProgressData: query.data }
}
