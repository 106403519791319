import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { minLength2Required, password, required, requiredEmail } from '../../../utils/forms/validation'
import { withSuccessHandler } from '../../../utils/forms/withHandlers'
import FormError from '../../input/FormError/FormError'
import FormInlineButton from '../../input/FormInline/FormInlineButton'
import FormInlineInput from '../../input/FormInline/FormInlineInput'
import FormInlineRow from '../../input/FormInline/FormInlineRow'

const defaultInitialValues = {
  country: 'US',
  role: 'user',
}

const CloseButton = styled(FormInlineButton)`
  margin-left: 10px;
  background: ${(props) => props.theme.colors.gray};
`

const UserForm = ({ newUser, submitText, initialValues, closeForm, ...props }) => (
  <Form
    {...props}
    initialValues={{ ...defaultInitialValues, ...initialValues }}
    render={({ form, handleSubmit, submitting, submitError }) => (
      <form style={{ marginBottom: '0.5em' }} onSubmit={withSuccessHandler(handleSubmit, form.reset)}>
        <FormInlineRow>
          <Field name="firstName" placeholder="First Name" component={FormInlineInput} validate={minLength2Required} />
          <Field name="lastName" placeholder="Last Name" component={FormInlineInput} validate={minLength2Required} />
          <Field name="email" placeholder="Email" type="email" component={FormInlineInput} validate={requiredEmail} />
          <Field name="phone" placeholder="Phone" component={FormInlineInput} />
          <Field name="source" placeholder="URL" component={FormInlineInput} />
          <Field name="telesalesSource" placeholder="Telesales source" component={FormInlineInput} />
          <Field name="utmSource" placeholder="UTM source" component={FormInlineInput} />
          <Field name="utmMedium" placeholder="UTM medium" component={FormInlineInput} />
          <Field name="utmCampaign" placeholder="UTM campaign" component={FormInlineInput} />
          <Field name="utmTerm" placeholder="UTM term" component={FormInlineInput} />
          <Field name="utmContent" placeholder="UTM content" component={FormInlineInput} />
          {newUser && (
            <Field
              name="password"
              placeholder="Password"
              type="password"
              component={FormInlineInput}
              validate={password}
            />
          )}
          <Field
            name="country"
            component={FormInlineInput}
            componentClass={FormInlineInput.Select}
            placeholder="Country"
            initialValue={initialValues ? initialValues.country : ''}
            validate={required}
          >
            <option>US</option>
            <option>GB</option>
          </Field>
          <Field name="city" placeholder="City" component={FormInlineInput} />
          <Field
            name="mentoringExpiryDate"
            placeholder="Mentoring Expiry Date"
            component={FormInlineInput}
            componentClass={FormInlineInput.DateTimePicker}
            help="Mentoring Expiry"
          />
          <div>
            <FormInlineButton type="submit" disabled={submitting}>
              {submitting ? 'Saving...' : submitText}
            </FormInlineButton>
          </div>
          {closeForm && (
            <div>
              <CloseButton onClick={closeForm} type="button">
                Close
              </CloseButton>
            </div>
          )}
        </FormInlineRow>
        <FormError submitting={submitting} error={submitError} />
      </form>
    )}
  />
)

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  newUser: PropTypes.bool,
}

UserForm.defaultProps = {
  submitText: 'Add a User',
}

export default UserForm
