import React from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FormError from '../../../components/input/FormError/FormError'
import FormInlineButton from '../../../components/input/FormInline/FormInlineButton'
import FormInlineInput from '../../../components/input/FormInline/FormInlineInput'
import { Cell } from './ConfigRow'
import './ConfigRowForm'

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`

const CellField = (props) => (
  <Cell>
    <Centered>
      <Field {...props} />
    </Centered>
  </Cell>
)

const Actions = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

const CancelButton = styled.div`
  cursor: pointer;
`

export const ConfigRowForm = ({ GB, mailingListId, onCancel, onSubmit, slug, submitText, US }) => (
  <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, submitting, submitError }) => (
      <tr>
        <CellField name="slug" placeholder="Route…" component={FormInlineInput} initialValue={slug} />
        <CellField
          name="mailingListId"
          placeholder="Mailing List Id…"
          component={FormInlineInput}
          initialValue={mailingListId}
        />

        <CellField name="GB" component="input" type="checkbox" defaultValue={GB} />
        <CellField name="US" component="input" type="checkbox" defaultValue={US} />

        <Cell>
          <Actions>
            <FormInlineButton type="submit" disabled={submitting} onClick={handleSubmit}>
              {submitting ? 'Saving…' : submitText}
            </FormInlineButton>
            {onCancel && <CancelButton onClick={onCancel}>Cancel</CancelButton>}
          </Actions>
        </Cell>

        <FormError submitting={submitting} error={submitError} />
      </tr>
    )}
  />
)

ConfigRowForm.propTypes = {
  GB: PropTypes.bool,
  mailingListId: PropTypes.number,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  slug: PropTypes.string,
  US: PropTypes.bool,
}
