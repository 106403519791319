import 'date-time-format-timezone'
import React from 'react'
import ReactDOM from 'react-dom'
import createPlugin from 'bugsnag-react'
import smoothScroll from 'smoothscroll-polyfill'
import bugsnag from './services/bugsnag/bugsnag'
import NormalizeGlobalStyle from './theme/NormalizeGlobalStyle'
import App from './App'

smoothScroll.polyfill()

const ErrorBoundary = bugsnag.use(createPlugin(React))

let user

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEFAULT_USER) {
  user = JSON.parse(process.env.REACT_APP_DEFAULT_USER)
}

ReactDOM.render(
  <ErrorBoundary>
    <NormalizeGlobalStyle />
    <App user={user} />
  </ErrorBoundary>,
  document.getElementById('root'),
)
