import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors, fonts, mediaMinWidth, themes } from '../../../theme'

/**
 * The `NavItem` wraps an individual react-router `Link`. Color styles are
 * controlled by the theme. Each `NavItem` spans the full width of its parent,
 * to control the width in a horizontal list use `flex` on the container.
 */
const NavItem = styled.li`
  font-family: ${fonts.primary};
  font-size: 19px;
  font-weight: 500;
  display: block;
  a {
    ${props => themes[props.colorScheme]};
    display: flex;
    white-space: nowrap;
    padding: ${props => (props.vertical ? '0.5em' : '9px 0.3em')};
    &:hover {
      cursor: pointer;
      background: ${props => (props.colorScheme === 'dark' ? colors.grayDark : colors.grayLight)};
    }
  }

  ${mediaMinWidth.md`
    font-size: 18px;
    a {
      padding-left: 0.27em;
      padding-right: 0.27em;
    }
  `};

  ${mediaMinWidth.lg`
    font-size: 21px;
  `};
`

NavItem.propTypes = {
  colorScheme: PropTypes.oneOf(['dark', 'light']),
  /** Set the `display` of the NavItem to `block` instead of `inline-block`, useful for vertical lists */
  block: PropTypes.bool,
}

NavItem.defaultProps = {
  colorScheme: 'light',
  block: false,
}

/** @component */
export default NavItem
